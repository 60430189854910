import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IUsageGroupState } from "../../../../redux/types/IUsageGroupState";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { USAGE_MATCHING_WORKS_SECTION_KEY, USAGE_ROWS_SECTION_KEY, MATCHINGWORKS_KEY, DEFINITE_MATCH, USAGE_MATCHING_PRODUCTS_SECTION_KEY, MATCHINGPRODUCTS_KEY, REPERTOIRE, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY, USAGE_MATCHSTATUS_DEFINITE_MATCH, USAGE_MATCHSTATUS_POTENTIAL_MATCH, USAGE_MATCHSTATUS_NO_MATCH_FOUND, USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY, USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY, USAGE_PRODUCT_DETAILS_ROWS_SECTION_KEY, USAGE_GROUP_ATTACHMENTS_SECTION_KEY, EDITABLE_FIELDS_GRID, TITLE_EDITABLE_FIELDS_VIEW, DATA_GRID_TABLE, GENERAL_DATA_EDITABLE_FIELDS, SEARCH_ACTION, USAGE_GROUP_COMMENTS_KEY } from "../../../Consts";
import { ITableAction } from "../../../types/ITableAction";
import { showModal, hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { addMatchWorkToUsage, addUsageDetailsShareIPRow, clearModalSearchResults, expandMatchWorkResult, searchDataChanged, setEditableFields } from "../../../../redux/reducers/RepertoireReducer";
import { updateUsageField } from "../../../../redux/reducers/RepertoireReducer";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IMatchingWorksRowData } from "../../../types/usageTypes/IMatchingWorksRowData";
import { IMatchingProductsRowData } from "../../../types/usageTypes/IMatchingProductsRowData";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IUsageProductDetails } from "../../../types/usageTypes/IUsageProductDetails";
import UsageMaintenanceAttachmentsGridContainer from "./UsageMaintenanceAttachmentsGridContainer";
import { MessageBanner, MessageType } from "../../../components/messageBanner/MessageBanner";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IUsagePool } from "../../../types/usageTypes/IUsagePool";
import { ITreeData } from "../../../types/ITreeData";
import { IUsagePoolState } from "../../../../redux/types/IUsagePoolState";
import UsageMaintenanceDetailsGrid from "./UsageMaintenanceDetailsGrid";
import AllocationSelectionView from "../../../adjustments/components/allocationSelectionView/AllocationSelectionView";
import { UsageAllocationMapper } from "../../../services/usageServices/UsageAllocationMapper";
import UsageMaintenanceMatchingWorksToolbar from "../usageMaintenanceMatchingWorksToolbar/UsageMaintenanceMatchingWorksToolbar";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IWorksSearchQuery } from "../../../types/IWorksSearchQuery";
import { IWorksSearchResult } from "../../../types/IWorksSearchResult";
import UsageSearchView from "../../../components/usageComponents/searchView/UsageSearchView";
import { IMatchProductsSearchQuery } from "../../../types/usageTypes/IMatchProductsSearchQuery";
import { IMatchWorksSearchQuery } from "../../../types/usageTypes/IMatchWorksSearchQuery";
import { IUsagesSearchQuery } from "../../../types/usageTypes/IUsagesSearchQuery";
import { IMatchWorksSearchResult } from "../../../types/usageTypes/IMatchWorksSearchResult";
import { IDistributionType } from "../../../types/usageTypes/IDistibutionType";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { SCREEN_SIZE_BIG_ENOUGH_FOR_TWO_SCREENS } from "../usageMaintenancePage/UsageMaintenancePage";
import UsageMaintenanceGridCommentsDataView from "./usageMaintenanceGridCommentsDataView/UsageMaintenanceGridCommentsDataView";

export interface IUsageMaintenanceGridsViewProps {
    usageMaintenanceGridsViewData: IRepertoireComponentDataItem,
    usageDataGridTableData: IRepertoireComponentDataItem,
    usageGroup: IUsageGroupState
    showModal: typeof showModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    hideModal: typeof hideModal;
    updateUsageField: typeof updateUsageField;
    getWorkDetails: (dataSource: string, workID: number, isOpenedInSecondTab?: boolean) => void;
    getProductDetails: (dataSource: string, tableItem: IMatchingProductsRowData, isOpenedInSecondTab) => void;
    lookupValues?: ILookupDictionary;
    addUsageDetailsShareIPRow?: typeof addUsageDetailsShareIPRow;
    resetMessageBanner: () => void;
    editableFields: IRepertoireField[];
    setEditableFields: typeof setEditableFields;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    sources?: ITreeData[];
    getUsagePool: (poolId: number, matchingSources: ITreeData[]) => void;
    pool?: IUsagePoolState;
    expandedUsageDetailsRow?: number[];
    expandUsageDetail?: (index: number) => void;
    updatedScroll?: number;
    usageMaintenanceMatchingWorksToolbar?: IRepertoireComponentDataItem;
    searchViewData?: IRepertoireComponentDataItem;
    searchResultsTableData?: IRepertoireComponentDataItem;
    dataSource?: string;
    searchWorks?: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    worksSearchResults?: IWorksSearchResult[];
    expandedWorkResults?: number[];
    searchSuccessful?: boolean;
    expandAll?: boolean;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    expandWorkResult?: (workResult: number) => void;
    expandAllResults?: () => void;
    currentPage?: number;
    resetPagination?: (repertoireSection: string) => void;
    updatePagination?: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    sortSearchResults?: (name: string, results: any) => void;
    username?: string;
    searchUsages?: (query: IUsagesSearchQuery, modalOpen: boolean, usageType: string, lookupSources: ITreeData[], continuationToken?: string) => void;
    searchMatchWorks?: (query: IMatchWorksSearchQuery, modalOpen: boolean) => void;
    searchMatchProducts?: (query: IMatchProductsSearchQuery, modalOpen: boolean) => void;
    matchWorkSearchResult?: IMatchWorksSearchResult[];
    expandedMatchWorkResults?: number[];
    distributionTypes?: IDistributionType[];
    lookupSources?: ITreeData[];
    usageMatchingDefaultsWorks?: string[];
    addMatchworkToUsage?: typeof addMatchWorkToUsage;
    usageExpandAllResults?: () => void;
    expandMatchWorkResult?: (matchWorkResults: number) => void;
    addNewWork: (lookups: ILookupDictionary, actionList?: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[], workGroupId?: string, workGroup?: string, paneIndex?: number, isFromUsage?: boolean, usage?: IUsageGroupState, isOpenedInSecondTab?: boolean) => void;
    paneIndex?: number;
    formatFields?: FormatFields[];
    updateUsagesAdvanced?: boolean;
    incrementTempID?: () => void;
    enableRegroupAndDeleteUI?:boolean;
}

interface IUsageMaintenanceGridsViewState {
    activeHeaderSection: string;
    isExpandedUsageMatchingSection: boolean;
    isExpandedUsageRowsSection: boolean;
    toolbarWidth: number;
    dataAction: IDataActionToolbar[];
    searchCriteriaVisible: boolean;
    expandUsageDetailRowsLocal: number[];
}

export class UsageMaintenanceGridsView extends React.PureComponent<
    IUsageMaintenanceGridsViewProps,
    IUsageMaintenanceGridsViewState
> {

    constructor(props: IUsageMaintenanceGridsViewProps) {
        super(props);
        this.state = {
            activeHeaderSection: USAGE_ROWS_SECTION_KEY,
            isExpandedUsageMatchingSection: false,
            isExpandedUsageRowsSection: false,
            toolbarWidth: null,
            dataAction: undefined,
            searchCriteriaVisible: false,
            expandUsageDetailRowsLocal: []
        };
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { usageMaintenanceGridsViewData, usageGroup } = this.props;

        if (usageMaintenanceGridsViewData && usageMaintenanceGridsViewData.fields) {

            let filteredFields = usageMaintenanceGridsViewData.fields.filter(
                (field: IRepertoireField) =>
                    (usageGroup.usageType.toString() === "UsageToWork"
                        && field.name !== USAGE_MATCHING_PRODUCTS_SECTION_KEY) ||
                    (usageGroup.usageType.toString() === "UsageToProduct"
                        && field.name !== USAGE_MATCHING_WORKS_SECTION_KEY)
            ).filter(
                (field: IRepertoireField) =>
                    (usageGroup.usageType.toString() === "UsageToWork"
                        && field.name !== USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY) ||
                    (usageGroup.usageType.toString() === "UsageToProduct"
                        && field.name !== USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY)
            )

            return filteredFields.map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section.name)}
                >
                    {section.data}
                </div>
            ));
        }
    }

    getTableActions = (sectionKey: string) => {
        let actions: ITableAction[] = []
        switch (sectionKey) {
            case USAGE_MATCHING_WORKS_SECTION_KEY:
                actions = []
                break;
            case USAGE_MATCHING_PRODUCTS_SECTION_KEY:
                actions = [{ name: SEARCH_ACTION }]
                break;
        }
        return actions;
    }

    changeData = (value: any, name: IRepertoireStateKeys) => {
        if ((name === MATCHINGWORKS_KEY || name === MATCHINGPRODUCTS_KEY)) {
            if (value.length === 1) {
                value[0].status = DEFINITE_MATCH;
                this.props.updateUsageField(USAGE_MATCHSTATUS_DEFINITE_MATCH, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY);
            }
            else if (value.length > 1) {
                this.props.updateUsageField(USAGE_MATCHSTATUS_POTENTIAL_MATCH, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY);
            }
            else if (value.length === 0) {
                this.props.updateUsageField(USAGE_MATCHSTATUS_NO_MATCH_FOUND, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY);
            }
        }
        if (name === 'usage') {

        }
        this.props.updateUsageField(value, name as IRepertoireStateKeys);
    }

    openWork(openItem: IMatchingWorksRowData): void {
        this.props.getWorkDetails(REPERTOIRE, openItem.workID, true)
    }

    openProduct(openItem: IMatchingProductsRowData): void {
        const isScreenWidthBigEnoughForTwoScreens = window.innerWidth > SCREEN_SIZE_BIG_ENOUGH_FOR_TWO_SCREENS;
        this.props.getProductDetails(REPERTOIRE, openItem, isScreenWidthBigEnoughForTwoScreens)

    }

    clearAllRowsButThis = (value: any) => {
        const { usageGroup } = this.props;
        if (usageGroup.matchingWorks) {
            const matchingWorks = usageGroup.matchingWorks.filter(x => x.workID == value.workID);
            this.props.updateUsageField(matchingWorks, MATCHINGWORKS_KEY);

            if (matchingWorks.filter(x => x.status == USAGE_MATCHSTATUS_DEFINITE_MATCH).length == 1) {
                this.props.updateUsageField(USAGE_MATCHSTATUS_DEFINITE_MATCH, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY);
            }
        }
        else {
            const matchingProducts = usageGroup.matchingProducts.filter(x => x.productCoreID == value.productCoreID);
            this.props.updateUsageField(matchingProducts, MATCHINGPRODUCTS_KEY);

            if (matchingProducts.filter(x => x.status == USAGE_MATCHSTATUS_DEFINITE_MATCH).length == 1) {
                this.props.updateUsageField(USAGE_MATCHSTATUS_DEFINITE_MATCH, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY);
            }
        }
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFields,
            usageDataGridTableData,
            setEditableFields,
            editableFieldsDataView
        } = this.props;
        setEditableFields(DATA_GRID_TABLE, USAGE_ROWS_SECTION_KEY);

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;
        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS_GRID,
            componentViewData: fieldsViewData,
            dataGridViewData: usageDataGridTableData,
            onClickSave: this.onSaveEditableFields.bind(this),
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = this.props;
        updateComponentFieldsItems(editableFields["default"], DATA_GRID_TABLE, USAGE_ROWS_SECTION_KEY, DATA_GRID_TABLE, tabs, activeTab);
    }

    onExpandClicked = () => {

        this.setState({ isExpandedUsageMatchingSection: !this.state.isExpandedUsageMatchingSection });
    }

    onExpandClickedUsageDetails = () => {
        let newExpandUsageDetailRowLocal = [];
        if(!this.state.isExpandedUsageRowsSection){
            this.props.usageGroup.usages.map((item, index) => {
                newExpandUsageDetailRowLocal.push(index);
            });
            this.setState({ expandUsageDetailRowsLocal: newExpandUsageDetailRowLocal});
        }
        if(this.state.isExpandedUsageRowsSection){
            this.setState({ expandUsageDetailRowsLocal: newExpandUsageDetailRowLocal});
        }
        this.setState({ isExpandedUsageRowsSection: !this.state.isExpandedUsageRowsSection });
    }
    expandUsageDetailLocal = (index: number) => {
        let newExpandUsageDetailRowLocal = this.state.expandUsageDetailRowsLocal;
        if (newExpandUsageDetailRowLocal.includes(index)) {
            newExpandUsageDetailRowLocal = newExpandUsageDetailRowLocal.filter(item => item !== index);
        } else {
            newExpandUsageDetailRowLocal.push(index);
        }
        this.setState({ expandUsageDetailRowsLocal: newExpandUsageDetailRowLocal });
    }
    renderExpandAll = () => {

        const { isExpandedUsageMatchingSection } = this.state;


        return (
            <div className='arrowIconExpandDiv'>
                <div className={isExpandedUsageMatchingSection ? 'arrowIconExpandAllExpanded' : 'arrowIconExpandAll'}>
                    <i className="icon ms-Icon ms-Icon--DoubleChevronLeft" aria-hidden="true" id="expandResultsButton" onClick={() => this.onExpandClicked()} title={isExpandedUsageMatchingSection ? "Collapse Results" : "Expand Results"}></i>
                </div>
                {isExpandedUsageMatchingSection ? <div className='arrowIconExpandText'> {"Collapse Results"}</div> : <div className='arrowIconExpandText'> {"Expand Results"}</div>}
            </div>
        );
    }
    
    renderExpandAllUsageDetails = () => {
        const { isExpandedUsageRowsSection } = this.state;
        return (
            <div className='arrowIconExpandDiv'>
                <div className={isExpandedUsageRowsSection ? 'arrowIconExpandAllExpanded' : 'arrowIconExpandAll'}>
                    <i className="icon ms-Icon ms-Icon--DoubleChevronLeft" aria-hidden="true" id="expandResultsButton" onClick={() => this.onExpandClickedUsageDetails()} title={isExpandedUsageRowsSection ? "Collapse Results" : "Expand Results"}></i>
                </div>
                {isExpandedUsageRowsSection ? <div className='arrowIconExpandText'> {"Collapse Results"}</div> : <div className='arrowIconExpandText'> {"Expand Results"}</div>}
            </div>
        );
    }
    
    showSearchCriteria = (value: boolean) => {
        this.setState({ searchCriteriaVisible: value })
    }


    renderAccordions() {
        const { 
            usageDataGridTableData,
            expandedUsageDetailsRow,
            expandUsageDetail,
            usageGroup,
            lookupValues,
            searchViewData,
            searchResultsTableData,
            updatedScroll,
            usageMaintenanceMatchingWorksToolbar,
            pool,
            matchWorkSearchResult,
            resetMessageBanner,
            showModal,
            hideModal,
            clearModalSearchResults,
            addUsageDetailsShareIPRow,
            usageMatchingDefaultsWorks,
            searchSuccessful,
            expandMatchWorkResult,
            addMatchworkToUsage,
            expandedMatchWorkResults, 
            lookupSources,
            distributionTypes,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            resetPagination,
            updatePagination,
            usageExpandAllResults,
            searchUsages,
            searchMatchWorks,
            searchMatchProducts,
            paneIndex,
            addNewWork,
            formatFields,
            updateUsagesAdvanced,
            activeTab,
            tabs,
            incrementTempID,
            enableRegroupAndDeleteUI,
        } = this.props;
        const { activeHeaderSection, isExpandedUsageMatchingSection,isExpandedUsageRowsSection } = this.state;
        const {
            toolbarWidth,
            dataAction,
        } = this.state;
        let filteredSearchViewData = { fields: [] };
        let searchTypeField;
        let isSearchTypeHidden;
        if (searchViewData) {
            const filtered = searchViewData.fields.filter(f => f.componentInstance === USAGE_MATCHING_WORKS_SECTION_KEY);
            searchTypeField = filtered && filtered.find(x => x.name === 'searchType');
            isSearchTypeHidden = searchTypeField && searchTypeField.hidden
            filteredSearchViewData = { fields: searchViewData.fields.filter(f => !['usageType', 'searchType'].includes(f.name)) };
        }
        switch (activeHeaderSection) {
            case USAGE_ROWS_SECTION_KEY:
                return (
                    <>
                        <div className="marginVerticalLarge flex-end-Content widthFull">
                            <button className="contributorGearBtn" title="Configure data fields"
                                    onClick={this.showFieldsModal.bind(this)}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                        <div className="searchResultsDiv">
                            {this.renderExpandAllUsageDetails()}
                            <br/>
                        </div>
                        <UsageMaintenanceDetailsGrid
                            dataGridTableData={usageDataGridTableData}
                            tableContents={usageGroup.usages.filter(item => item.isDeleted !== true)}
                            isExpandable={true}
                            stateKey={undefined}
                            changeData={this.changeData.bind(this)}
                            lookupValues={lookupValues}
                            showModal={showModal}
                            addUsageDetailsShareIPRow={addUsageDetailsShareIPRow}
                            resetMessageBanner={resetMessageBanner}
                            pool={pool}
                            expandedUsageDetailsRow={this.state.expandUsageDetailRowsLocal}
                            expandUsageDetailRow={this.expandUsageDetailLocal.bind(this)}
                            componentInstance={USAGE_ROWS_SECTION_KEY}
                            updateUsagesAdvanced={updateUsagesAdvanced}
                            enableRegroupAndDeleteUI={enableRegroupAndDeleteUI}
                            expandedUsageMatchingSection={isExpandedUsageRowsSection}
                        />
                    </>)
            case USAGE_PRODUCT_DETAILS_ROWS_SECTION_KEY:
                let usageProductDetails: IUsageProductDetails[] = [];
                usageGroup.usages.map(usage =>
                    usage.productDetails.map(pd =>
                        usageProductDetails.push(pd)
                    ));
                return (
                    <DataGridTable
                        dataGridTableData={usageDataGridTableData}
                        componentInstance={USAGE_PRODUCT_DETAILS_ROWS_SECTION_KEY}
                        tableContents={usageProductDetails}
                        isExpandable={false}
                        stateKey={undefined}
                        changeData={undefined}
                        showRowNumber={false}
                        sortData={undefined}
                    />
                );
            case USAGE_MATCHING_WORKS_SECTION_KEY:
                let openWorkFlow: boolean;
                function sortByDateDesc(a: any, b: any) {
                    return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
                }

                if (usageGroup.workflows !== null && usageGroup.workflows.length > 0) {
                    usageGroup.workflows.sort(sortByDateDesc);
                    openWorkFlow = usageGroup.workflows[0].entityType === "Usage (Unidentified Performance)" && usageGroup.workflows[0].status === "Open";
                }
                return (
                    <div>
                        {openWorkFlow ?
                            <div className="row">
                                <MessageBanner
                                    resetMessageBanner={resetMessageBanner}
                                    messageType={MessageType.Danger}
                                    messages={["Outstanding Unidentified Claim Workflow must be processed before this usage group can be matched."]}
                                />
                            </div>
                            : null}
                        {/* <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                            </div> */}
                        <div className="row">
                            <div style={{ background: '#f5f5f5', width: '98%', margin: '16px', padding: '7px' }}>
                                <div className="UsageMaintenanceMatchingWorksToolbar">
                                    <UsageMaintenanceMatchingWorksToolbar
                                        scroll={updatedScroll}
                                        toolbarWidth={toolbarWidth}
                                        toolbarData={usageMaintenanceMatchingWorksToolbar}
                                        saveResultData={undefined}
                                        searchWorks={undefined}
                                        resetMessageBanner={undefined}
                                        changesMade={undefined}
                                        usageGroup={usageGroup}
                                        activeTab={undefined}
                                        tabs={undefined}
                                        showModal={showModal}
                                        showYesNoPrompt={undefined}
                                        hideModal={hideModal}
                                        clearModalSearchResults={clearModalSearchResults}
                                        currentUser={undefined}
                                        usageMaintenanceGeneralDataViewData={undefined}
                                        showSearchCriteria={this.showSearchCriteria}
                                        formatFields={formatFields}
                                        skipWorkWorkflowItem={undefined}
                                        lookupValues={lookupValues}
                                        completeWorkflowOnSave={undefined}
                                        addNewWork={addNewWork}
                                        paneIndex={paneIndex}
                                        componentInstance={USAGE_MATCHING_WORKS_SECTION_KEY}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {this.state.searchCriteriaVisible && <div style={{ margin: '10px' }}>
                                <UsageSearchView
                                    searchViewData={filteredSearchViewData}
                                    searchResultsTableData={searchResultsTableData}
                                    componentInstance={USAGE_MATCHING_WORKS_SECTION_KEY}
                                    modalProps={undefined}
                                    searchUsages={searchUsages}
                                    searchSuccessful={searchSuccessful}
                                    indexOfFirstResult={indexOfFirstResult}
                                    indexOfLastResult={indexOfLastResult}
                                    resultsPerPage={resultsPerPage}
                                    currentPage={currentPage}
                                    resetPagination={resetPagination}
                                    updatePagination={updatePagination}
                                    hideModal={hideModal}
                                    searchMatchWorks={searchMatchWorks}
                                    searchMatchProducts={searchMatchProducts}
                                    matchWorkSearchResult={matchWorkSearchResult}
                                    getUsageDetails={undefined}
                                    expandMatchWorkResult={expandMatchWorkResult}
                                    expandedMatchWorkResults={expandedMatchWorkResults}
                                    addMatchworkToUsage={addMatchworkToUsage}
                                    searchDataChanged={searchDataChanged}
                                    lookupValues={lookupValues}
                                    lookupSources={lookupSources}
                                    distributionTypes={distributionTypes}
                                    usageMatchingDefaultsWorks={usageMatchingDefaultsWorks}
                                    usageExpandAllResults={usageExpandAllResults}
                                    usageGroup={usageGroup}
                                    getWorkDetails={this.openWork.bind(this)}

                                />
                            </div>}
                            <div className="searchResultsDiv">
                                {this.renderExpandAll()}
                            </div>
                            <div className="matchingWorksSection" style={{ margin: '20px' }}>
                                <DataGridTable
                                    dataGridTableData={usageDataGridTableData}
                                    componentInstance={USAGE_MATCHING_WORKS_SECTION_KEY}
                                    tableContents={usageGroup.matchingWorks}
                                    tableActions={this.getTableActions(USAGE_MATCHING_WORKS_SECTION_KEY)}
                                    showModal={showModal}
                                    clearModalSearchResults={clearModalSearchResults}
                                    stateKey={MATCHINGWORKS_KEY}
                                    hideModal={hideModal}
                                    isExpandable={true}
                                    changeData={this.changeData.bind(this)}
                                    showRowNumber={false}
                                    sortData={undefined}
                                    usageType={usageGroup.usageType}
                                    openWorkFlow={openWorkFlow}
                                    dependentRowAction={this.openWork.bind(this)}
                                    clearAllRowsButThis={this.clearAllRowsButThis.bind(this)}
                                    isExpandedUsageMatchingSection={isExpandedUsageMatchingSection}
                                />
                            </div>
                        </div>
                    </div>
                )
            case USAGE_MATCHING_PRODUCTS_SECTION_KEY:
                return (
                    <DataGridTable
                        dataGridTableData={usageDataGridTableData}
                        componentInstance={USAGE_MATCHING_PRODUCTS_SECTION_KEY}
                        tableContents={usageGroup.matchingProducts}
                        tableActions={this.getTableActions(USAGE_MATCHING_PRODUCTS_SECTION_KEY)}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        stateKey={MATCHINGPRODUCTS_KEY}
                        hideModal={hideModal}
                        isExpandable={true}
                        changeData={this.changeData.bind(this)}
                        showRowNumber={false}
                        sortData={undefined}
                        usageType={usageGroup.usageType}
                        dependentRowAction={this.openProduct.bind(this)}
                        clearAllRowsButThis={this.clearAllRowsButThis.bind(this)}
                    />
                );
            case USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY:
                return (
                    <AllocationSelectionView
                        searchResults={usageGroup.allocations?.map((allocation, index) => UsageAllocationMapper.mapAllocationUsageToHeaderRowType(allocation, index))}
                        viewData={usageDataGridTableData}
                        componentInstance={USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY}
                    />
                );
            case USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY:

                return (
                    <AllocationSelectionView
                        searchResults={usageGroup.allocations?.map((allocation, index) => UsageAllocationMapper.mapAllocationUsageToHeaderRowType(allocation, index))}
                        viewData={usageDataGridTableData}
                        componentInstance={USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY}
                    />
                );
            case USAGE_GROUP_COMMENTS_KEY:
                return (
                    <UsageMaintenanceGridCommentsDataView
                        usage={usageGroup}
                        isReadonly={false}
                        lookupValues={lookupValues}
                        updateUsageField={this.changeData}
                        incrementTempID={incrementTempID}
                        dataGridTableData={usageDataGridTableData}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        tabs={tabs}
                        activeTab={activeTab}
                    />
                )
            case USAGE_GROUP_ATTACHMENTS_SECTION_KEY:
                return (
                    <UsageMaintenanceAttachmentsGridContainer paneIndex={paneIndex} />
                )
        }
    }

    render() {
        return (
            <div className="GridsView">
                <div className="headerContainer">
                    {this.renderGridViewHeaderSections()}
                </div>
                {this.renderAccordions()}
            </div>

        );
    }
}