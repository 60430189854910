import * as React from "react";
import { SizedTextDataInput } from "../../../../components/textDataInput/TextDataInput";
import { hideModal } from "../../../../../redux/reducers/ModalReducer";
import { updateUsageField } from "../../../../../redux/reducers/RepertoireReducer";


export interface IUsageMaintenanceGridCommentsModalViewProps {
    hideModal?: typeof hideModal;
    modalProps: any;
    updateUsageField: typeof updateUsageField;
    index: number;
    title: string;
}

const UsageMaintenanceGridCommentsModalView:  React.FC<IUsageMaintenanceGridCommentsModalViewProps> =  ({
    title,
    hideModal,
    modalProps,
    updateUsageField,
    index,
}) => {

        const changeData = (value: any, fieldName: string) => {
            const newModalProps = { ...modalProps };
            newModalProps.comments[modalProps.index][fieldName].value = value;

            updateUsageField(newModalProps.comments, "comments");
        }

        return (
            <div className="modal-dialog settingsModal" style={{ width: 952 }}>
                <div className="modal-content">
                    <div style={{ height: 'auto', background: 'white' }}>
                        <div className='modal-header' style={{
                            background: "#38429C",
                            color: "white",
                        }}>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => hideModal()}>
                                <i className="icon ms-Icon ms-Icon--ChromeClose" style={{ color: "white" }} title="Close"></i>
                            </button>
                            <h1 className="modal-title">{title}</h1>
                        </div>
                        <div style={{ padding: 15 }}></div>
                        <div className="row flexWrapRow paddingVeryLarge">
                            <div className="subjectTextBox">
                                <SizedTextDataInput
                                    label="Subject"
                                    value={modalProps.comments[modalProps.index].subject.value}
                                    changeData={(value) => {
                                        changeData(value, "subject");
                                    }}
                                    fieldName={"subject"}
                                    isHidden={false}
                                    Width={920}
                                />
                            </div>
                            <div className="subjectTextBox" style={{ marginLeft: 16 }}>
                                <label>Comments:</label>
                                <textarea style={{ minHeight: '200px', width: 920 }}
                                    value={modalProps.comments[modalProps.index].comment.value}
                                    onChange={(e) => {
                                        changeData(e.target.value, "comment");
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div className="form-group col-md-4 col-sm-5 col-xs-12">
                                <button
                                    className="button btn-defaultSecondary"
                                    onClick={() => {
                                        changeData("", "subject");
                                        changeData("", "comment");
                                    }}
                                >
                                    Clear
                                </button>
                            </div>
                            <div className="form-group col-md-4 col-sm-5 col-xs-12">
                                <button
                                    className="button btn-defaultPrimary"
                                    onClick={() => {
                                        hideModal();
                                    }}
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

export default UsageMaintenanceGridCommentsModalView;