import { IUsageGroupState } from "../../../redux/types/IUsageGroupState";
import { IUsage } from "../../types/usageTypes/IUsage";
import { IUsageRowData } from "../../types/usageTypes/IUsageRowData";
import { IUsageGroup } from "../../types/usageTypes/IUsageGroup";
import { IPerformanceNumber } from "../../types/usageTypes/IPerformanceNumber";
import { IUsageDetailsRowData } from "../../types/usageTypes/IUsageDetailsRowData";
import { IMatchingWorksRowData } from "../../types/usageTypes/IMatchingWorksRowData";
import { UsageSearchRequests } from "./UsageSearchRequests";
import { CONFIGURATION_PARAMETER_USAGE_MAINTENANCE_GROUP, CONFIGURATION_PARAMETER_USAGE_SORT_NUMBER_MATCHING_WORKS_KEY, REPERTOIRE, USAGETOWORK, USAGETOPRODUCT, EMPTY_STRING_VALUE, CHECKBOX_INPUT } from "../../Consts";
import { IMatchWorksSearchResult } from "../../types/usageTypes/IMatchWorksSearchResult";
import { IMatchWorksSearchQuery } from "../../types/usageTypes/IMatchWorksSearchQuery";
import { IUsageMatch } from "../../types/usageTypes/IUsageMatch";
import { IUsageDetails } from "../../types/usageTypes/IUsageDetails";
import { IMatchingWork } from "../../types/IMatchingWork";
import { IMatchingProductsRowData } from "../../types/usageTypes/IMatchingProductsRowData";
import { IMatchProductsSearchQuery } from "../../../repertoire/types/usageTypes/IMatchProductsSearchQuery";
import { IProductSearchResult } from "../../types/IProductSearchResult";
import { startCase } from "lodash";
import { DateHelper } from "../../../core/services/DateHelper";
import { IUsageGroupResult, IUsageSearchResultRowType } from "../../types/usageTypes/IUsageGroupsSearchResult";
import { IUsageDetailsAdjustmentsDataItem } from "../../usage/components/usageRowDropdown/UsageRowDropdown";
import { IUsageProductDetails } from "../../types/usageTypes/IUsageProductDetails";
import { IAttachedFile } from "../../../redux/types/IAttachedFile";
import { IStoragePathItemData } from "../../../dataingestion/types/IStoragePathItemData";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../../redux/types/FormatFields";
import { IProductState } from "../../../redux/types/IProductState";
import { IProductSourceIP } from "../../types/IProductSourceIP";
import { ProductDetailsMapper } from "../ProductDetailsMapper";
import { PRODUCT_TYPE_LOOKUP } from "../../../lookup/Consts";
import { getLookupDefault } from "../../../lookup/services/LookupHelpers";
import { IMoreDates } from "../../types/usageTypes/IMoreDates";
import { IDraftSetListSearchResult } from "../../../membersportal/types/IDraftSetListSearchResult";
import { IUsageGroupComment } from "../../../redux/types/IUsageGroupComment";

export class UsageDetailsMapper {
    public static mapUsageToState(usageGroup: IUsageGroup, workMatches?: IMatchingWorksRowData[], productMatches?: IMatchingProductsRowData[],isMembersPortal?:boolean,reGroupIds?:Number[],originalUsageGroupID?:string): IUsageGroupState {
        if (!usageGroup)
            return null;

        const {
            id,
            fullTitle,
            fullContributor,
            fullPerformer,
            distributionCode,
            poolCode,
            sourceMajor,
            sourceMinor,
            usageType,
            productType,
            matchStatus,
            allocationStatus,
            allocations,
            remittingSocietyWorkCode,
            fileName,
            dateLoaded,
            workflows,
            totalWeight,
            attachedFiles,
            location,
            setListAdditional,
            moreDates,
            approvalStatus,
            enableApprovalStatusEdit,
            venueDetails,
            sourceIP,
            qaStatus,
            rejectReason
        } = usageGroup;
let usagesValues=UsageDetailsMapper.mapUsageGroupToUsages(usageGroup);
const updatedMoreDates:IMoreDates[]=[]; 
        let setList: IDraftSetListSearchResult = null;
        if (isMembersPortal) {   
            if (usagesValues.length > 1) {
                usagesValues.splice(0,1);
                usagesValues.map(element=>{
                    let value:IMoreDates={
                        programStartDate: element.programStartDate?element.programStartDate:null,
                        programEndDate: element.programEndDate?element.programEndDate:null,
                        usageDate: element.usageDate
                    }
                    updatedMoreDates.push(value)
                })
            }
            if(usageGroup.matches.length > 0 && usageGroup.matches[0].productData)
                setList = {
                    title: usageGroup.matches[0].productData.title,
                    productCoreID: usageGroup.matches[0].productData.productCoreID,
                    dataSource: null,
                    setListID: usageGroup.matches[0].productData.productCoreID.toString(),
                    productStatus: usageGroup.matches[0].productData.status,
                    type: usageGroup.matches[0].productData.type,
                    id1: usageGroup.matches[0].productData.id1,
                    id2: usageGroup.matches[0].productData.id2,
                    number: [],
                    contributor: usageGroup.matches[0].productData.contributor,
                    artist: usageGroup.matches[0].productData.artist,
                    source: usageGroup.matches[0].productData.source
                }
            
           
        }
        const usageGroupState: IUsageGroupState = {
            id : (reGroupIds && reGroupIds.length > 0) ? null : id,
            fullTitle,
            fullContributor,
            fullPerformer,
            distributionCode,
            poolCode,
            sourceMajor,
            sourceMinor,
            usageType,
            productType,
            matchStatus,
            allocationStatus,
            allocations:((reGroupIds && reGroupIds.length > 0) ? [] : allocations),
            remittingSocietyWorkCode,
            fileName,
            totalWeight:((reGroupIds && reGroupIds.length > 0) ? null : totalWeight),
            dateLoaded :(reGroupIds && reGroupIds.length > 0) ? null : dateLoaded,
            usages: UsageDetailsMapper.mapUsageGroupToUsages(usageGroup,reGroupIds),
            matchingWorks: workMatches || UsageDetailsMapper.mapUsageGroupToMatchingWorks(usageGroup, usageType),
            matchingProducts: productMatches || UsageDetailsMapper.mapUsageGroupToMatchingProducts(usageGroup, usageType),
            workflows,
            files: UsageDetailsMapper.mapAttachmentsToFileState(attachedFiles),
            location,
            setListAdditional,
            venueDetails,
            moreDates:updatedMoreDates?updatedMoreDates:moreDates,
            approvalStatus,
            enableApprovalStatusEdit,
            sourceIP,
            livePerformanceSetList: setList,
            qaStatus,
            rejectReason,
            originalUsageGroupID
        }

        return usageGroupState;
    }


    public static mapStateUsageGroup(state: IUsageGroupState,isMembersPortal:boolean): IUsageGroup {
        if (!state)
            return null;

        const {
            id,
            fullTitle,
            fullContributor,
            fullPerformer,
            distributionCode,
            poolCode,
            sourceMajor,
            sourceMinor,
            usageType,
            productType,
            matchStatus,
            allocationStatus,
            allocations,
            remittingSocietyWorkCode,
            fileName,
            totalWeight,
            dateLoaded,
            workflows,
            files,
            location,
            setListAdditional,
            venueDetails,
            moreDates,
            approvalStatus,
            sourceIP,
            qaStatus,
            rejectReason
        } = state;

        let usageList = UsageDetailsMapper.mapStateToUsages(state);
        
                if (isMembersPortal && moreDates.length>=1) {           
            moreDates.map((element,index)=>{
                const updatedUsagesObj: IUsage = {
                    id: index + 1,
                    bundle: "",
                    bundleRetailPriceExTax: "",
                    bundleTracks: "",
                    bundleTracksPayable: "",
                    carrierType: "",
                    cartNumber: "",
                    count: 1,
                    distributionMethod: "",
                    durationPerPlay: 0,
                    inDispute: "",
                    musicDuration: null,
                    programEndDate: element.programEndDate.value,
                    programName: "",
                    programStartDate: element.programStartDate.value,
                    usageDate: element.usageDate.value,
                    weight: 1,
                    usageDetails: [],
                    names: [],
                    performanceNumbers: [],
                    performers: [],
                    contributors: [],
                    productDetails: null,
                    publisher: "",
                    receivedAmount: "",
                    recordLabel: "",
                    recordName: "",
                    recordingDate: "",
                    recordingType: "",
                    releaseDate: "",
                    royaltyAmount: "",
                    royaltyAmountPerPerformance: "",
                    timing: "",
                    trackNumber: "",
                    unitRetailPrice: "",
                    unitRetailPriceExVat: "",
                    exploitationTerritoryAbbreviatedName: ""
                }
                usageList.push(updatedUsagesObj);
            })
        }
        const usageGroup: IUsageGroup = {
            id,
            fullTitle,
            fullContributor,
            fullPerformer,
            distributionCode,
            poolCode,
            sourceMajor,
            sourceMinor,
            usageType:isMembersPortal?'UsageToProduct':usageType,
            productType,
            matchStatus,
            allocationStatus,
            allocations,
            remittingSocietyWorkCode,
            fileName,
            dateLoaded,
            totalWeight:totalWeight?totalWeight:0,
            usages: usageList,
            matches: UsageDetailsMapper.mapStateToMatches(state.matchingWorks, state.matchingProducts),
            workflows,
            attachedFiles: UsageDetailsMapper.mapStateToAttachedFiles(files, id),
            location,
            setListAdditional,
            venueDetails,
            moreDates,
            approvalStatus,
            sourceIP,
            qaStatus,
            rejectReason
        }
        return usageGroup;
    }

    public static convertDateFormat(dateString) {
        if (dateString.includes('/')) {
            const [date, time] = dateString.split(' ');
            const [month, day, year] = date.split('/');
            return `${year}-${month}-${day}T${time}`;
        }
        return dateString;
    }

    public static mapStateToComments(comments: IUsageGroupComment[]) {
        if (comments && comments.length > 0) {
            const newComments = [];
            comments.map(a =>
                newComments.push({
                    comment: a.comment.value,
                    subject: a.subject.value,
                    date: a.date.value,
                    isModified: a?.isModified,
                }));

            return newComments;
        }else {
            return [];
        }
    }

    public static mapStateToUsages(state: IUsageGroupState): IUsage[] {
        const usages: IUsage[] = [];
        state.usages.map(u => {
            const usage: IUsage = {
                id: u.catalogue.value,
                bundle: u.bundle.value,
                bundleRetailPriceExTax: u.bundlePrice.value,
                bundleTracks: "",
                bundleTracksPayable: "",
                carrierType: u.carrierType.value,
                cartNumber: "",
                count: isNaN(parseInt(u.usageCount.value)) ? null : Number(u.usageCount.value),
                distributionMethod: "",
                durationPerPlay: parseFloat(u.durationPerPlay.value),
                inDispute: "",
                musicDuration: null,
                programEndDate: u.programEndDate.value?u.programEndDate.value:"",
                programName: "",
                programStartDate: u.programStartDate.value?u.programStartDate.value:"",
                publisher: "",
                receivedAmount: "",
                recordLabel: u.recordLabel.value,
                recordName: "",
                recordingDate: "",
                recordingType: "",
                releaseDate: "",
                royaltyAmount: "",
                royaltyAmountPerPerformance: "",
                timing: "",
                trackNumber: "",
                unitRetailPrice: "",
                unitRetailPriceExVat: "",
                usageDate: UsageDetailsMapper.convertDateFormat(u.usageDate.value),
                weight: isNaN(parseInt(u.weight.value)) ? null : Number(u.weight.value),
                usageDetails: [],
                names: [],
                performanceNumbers: [],
                performers: [],
                contributors: [],
                productDetails: u.productDetails,
                use: u.use.value,
                dimension1: u.dimension1.value,
                dimension2: u.dimension2.value,
                dimension3: u.dimension3.value,
                dimension4: u.dimension4.value,
                dimension5: u.dimension5.value,
                weightingCode: u.weightingCode.value,
                evpp: u.evpp.value,
                estimatedValue: u.estimatedValue.value,
                countryOfUsage: u.countryOfUsage.value,
                weightMultiplier: u.weightMultiplier.value,
                exploitationTerritoryAbbreviatedName: u.territoryName.value,
                amountForAllocationRule: u.amountForAllocationRule.value,
                isSelected:u.isSelected.value,
                guid:u.guid.value,
                isDeleted: u.isDeleted,
                versionNumber: u.versionNumber.value
            }
            const usageDetails: IUsageDetails[] = [];
            u.usageDetails.map(a =>
                usageDetails.push({
                    remittingSocietyWorkCode: state.remittingSocietyWorkCode,
                    rightOwner: a.name,
                    rightOwnerNationalNumber: "",
                    rightOwnerIPINameNumber: a.ipiNumber,
                    rightOwnerLicensorsNumber: "",
                    amount: a.amount,
                    currencyCode: "",
                    society: "",
                    role: "",
                    territoryCode: 0,
                    territoryName: "",
                    userAdjustment: false,
                    adjustmentNumber: "",
                    adjustmentReasonCode: "",
                    adjustmentCommentary: "",
                    sharePercentage: "0", 
                    additionalInformation: "",
                    applicableRoyaltyRate: a.applicableRoyaltyRate,
                    royaltyRate: a.royaltyRate,
                    inboundFeesInError: a.inboundFeesInError,
                    outboundFeesInError: a.outboundFeesInError,
                    adjustments: a.adjustments,
                    errorMessage: a.errorMessage
                }));
            const performanceNumbers: IPerformanceNumber[] = [];
            u.performanceNumbers.map(b =>
                performanceNumbers.push({
                    number: b.number,
                    typeCode: b.typeCode
                }));
            usage.usageDetails = usageDetails;
            usage.performanceNumbers = performanceNumbers;
            usages.push(usage);
        });
        return usages;
    }

    public static mapStateToMatches(matchingWorksRows: IMatchingWorksRowData[], matchingProductsRows: IMatchingProductsRowData[]): IUsageMatch[] {
        if (matchingWorksRows && matchingWorksRows.length > 0) {
            const matches: IUsageMatch[] = [];
            matchingWorksRows.map(a =>
                matches.push({
                    matchSource: a.source.replace(/\s/g, ''),
                    matchType: a.status.replace(/\s/g, ''),
                    id: a.workID,
                    workData: a
                }));

            return matches;
        }
        else if (matchingProductsRows && matchingProductsRows.length > 0) {
            const matches: IUsageMatch[] = [];
            matchingProductsRows.map(a =>
                matches.push({
                    matchSource: a.source.replace(/\s/g, ''),
                    matchType: a.status.replace(/\s/g, ''),
                    id: a.productCoreID,
                    productData: a
                }));

            return matches;
        }
        else {
            return [];
        }
    }

    public static getUsageTitle(usageGroup: IUsageGroup): string {
        if (usageGroup.fullTitle === undefined) {
            return 'New Usage';
        } else {
            return usageGroup.fullTitle + ' (' + usageGroup.id + ')';
        }
    }

    public static mapUsageGroupToUsages(usageGroup: IUsageGroup,reGroupIds?:Number[]): IUsageRowData[] {
        if (!usageGroup.usages)
            return null;
        const usagesState: IUsageRowData[] = [];
        let updatedUsage;
        if(reGroupIds && reGroupIds.length>0){
            updatedUsage = usageGroup.usages.filter((_, index) => reGroupIds.includes(index))
        }
        else{
            updatedUsage = usageGroup.usages
        }
        updatedUsage.map(u => {
            const usageState: IUsageRowData = {
                catalogue: { value: '', inputType: 'row' },
                usageCount: { value: u.count.toString(), inputType: 'row' },
                amountForAllocationRule: { value: u.amountForAllocationRule?.toString(), inputType: 'row' },
                durationPerPlay: { value: u.durationPerPlay.toString(), inputType: 'row' },
                weight: { value: u.weight.toString(), inputType: 'row' },
                distribution: { value: usageGroup.distributionCode, inputType: 'row' },
                bundle: { value: u.bundle, inputType: 'row' },
                trackPrice: { value: u.unitRetailPriceExVat, inputType: 'row' },
                bundlePrice: { value: u.bundleRetailPriceExTax, inputType: 'row' },
                usageDate: { value: u.usageDate, inputType: 'row' }, 
                fileName: { value: usageGroup.fileName, inputType: 'row' },
                dateLoaded: { value: usageGroup.dateLoaded, inputType: 'row' },
                amount: u.royaltyAmount,
                sendingLicensee: u.publisher,
                recordLabel: { value: u.recordLabel, inputType: 'row' },
                programName: { value: u.programName, inputType: 'row' },
                performanceNumbers: u.performanceNumbers,
                usageDetails: UsageDetailsMapper.mapUsageToUsagesDetails(u),
                productDetails: UsageDetailsMapper.mapUsageToProductDetails(u),
                programStartDate: { value: u.programStartDate, inputType: 'row' },
                programEndDate: { value: u.programEndDate, inputType: 'row' },
                carrierType: { value: u.carrierType, inputType: 'text'},
                use: { value: u.use, inputType: 'text' },
                dimension1: { value: u.dimension1, inputType: 'text' },
                dimension2: { value: u.dimension2, inputType: 'text' },
                dimension3: { value: u.dimension3, inputType: 'text' },
                dimension4: { value: u.dimension4, inputType: 'text' },
                dimension5: { value: u.dimension5, inputType: 'text' },
                weightingCode: { value: u.weightingCode, inputType: 'row' },
                evpp: { value: u.evpp, inputType: 'text' },
                estimatedValue: { value: u.estimatedValue, inputType: 'text' },
                countryOfUsage: { value: u.countryOfUsage, inputType: 'dropdown' },
                weightMultiplier: {value: u.weightMultiplier, inputType: 'row'},
                trackNumber: { value: u.trackNumber, inputType: 'row' },
                recordName: { value: u.recordName, inputType: 'row' },
                recordingDate: { value: u.recordingDate, inputType: 'row' },
                additionalInformation: { value: u.usageDetails[0]?.additionalInformation, inputType: 'row' },
                territoryName: {value: u.exploitationTerritoryAbbreviatedName, inputType: 'row'},
                isSelected:{value:'',inputType:CHECKBOX_INPUT},
                guid: { value: u.guid, inputType: 'text' },
                versionNumber: { value: u.versionNumber, inputType: 'text' },
                isDeleted: u.isDeleted
            }
            usagesState.push(usageState);
        });
        return usagesState;
    }

    public static mapUsageToProductDetails(usage: IUsage): IUsageProductDetails[] {
        if (!usage.productDetails || usage.productDetails === null)
            return [];

        const usageProductDetails: IUsageProductDetails[] = [];
        usage.productDetails.map(pd => {
            const usageProductDetail: IUsageProductDetails = {
                avIndexNumber: pd.avIndexNumber,
                avWorkCategory: pd.avWorkCategory,
                avWorkTitle: pd.avWorkTitle,
                originalEpisodeTitle: pd.originalEpisodeTitle
            }
            usageProductDetails.push(usageProductDetail);
        });
        return usageProductDetails;
    }

    public static mapUsageToUsagesDetails(usage: IUsage): IUsageDetailsRowData[] {
        if (!usage.usageDetails)
            return null;

        const usageDetailsState: IUsageDetailsRowData[] = [];
        let id = 1;
        usage.usageDetails.map(ud => {
            const usageDetailState: IUsageDetailsRowData = {
                id: id.toString(),
                ipiNumber: ud.rightOwnerIPINameNumber,
                name: ud.rightOwner,
                share: ud.sharePercentage === EMPTY_STRING_VALUE ? "0" : ud.sharePercentage,
                society: ud.society,
                role: ud.role,
                applicableRoyaltyRate: ud.applicableRoyaltyRate === EMPTY_STRING_VALUE ? "0" : ud.applicableRoyaltyRate,
                amount: ud.amount === EMPTY_STRING_VALUE ? "0" : ud.amount,
                licenseRefID: 0,
                royaltyRate: ud.royaltyRate === EMPTY_STRING_VALUE ? "0" : ud.royaltyRate,
                inboundFeesInError: ud.inboundFeesInError === null ? EMPTY_STRING_VALUE : ud.inboundFeesInError,
                outboundFeesInError: ud.outboundFeesInError === null ? EMPTY_STRING_VALUE : ud.outboundFeesInError,
                adjustments: ud.adjustments === null ? [] : UsageDetailsMapper.mapUsagesDetailsToAdjustments(ud.adjustments),
                errorMessage: ud.errorMessage
            }
            if (usageDetailState.adjustments && usageDetailState.adjustments.length > 0) {
                usageDetailState.adjustments.push({
                    direction: 'From',
                    id: id.toString(),
                    ipiNumber: ud.rightOwnerIPINameNumber,
                    name: ud.rightOwner,
                    share: ud.sharePercentage === EMPTY_STRING_VALUE ? "0" : ud.sharePercentage,
                    applicableRoyaltyRate: ud.applicableRoyaltyRate === EMPTY_STRING_VALUE ? "0" : ud.applicableRoyaltyRate,
                    amount: ud.amount === EMPTY_STRING_VALUE ? "0" : ud.amount,
                    licenseRefID: 0,
                    royaltyRate: ud.royaltyRate === EMPTY_STRING_VALUE ? "0" : ud.royaltyRate,
                    inboundFeesInError: ud.inboundFeesInError === null ? EMPTY_STRING_VALUE : ud.inboundFeesInError,
                    outboundFeesInError: ud.outboundFeesInError === null ? EMPTY_STRING_VALUE : ud.outboundFeesInError,
                })
            }
            usageDetailsState.push(usageDetailState);
            id++;
        });
        return usageDetailsState;
    }

    public static mapUsagesDetailsToAdjustments(adjustments: IUsageDetailsAdjustmentsDataItem[]): IUsageDetailsAdjustmentsDataItem[] {
        const adjustmentsState: IUsageDetailsAdjustmentsDataItem[] = [];
        adjustments.map(adj => {
            const adjustmentState: IUsageDetailsAdjustmentsDataItem = {
                direction: adj.direction,
                id: adj.id ? adj.id.toString() : EMPTY_STRING_VALUE,
                ipiNumber: adj.ipiNumber,
                name: adj.name,
                share: adj.share,
                applicableRoyaltyRate: adj.applicableRoyaltyRate === EMPTY_STRING_VALUE ? "0" : adj.applicableRoyaltyRate,
                amount: adj.amount === EMPTY_STRING_VALUE ? "0" : adj.amount,
                licenseRefID: adj.licenseRefID ? adj.licenseRefID : 0,
                royaltyRate: adj.royaltyRate === EMPTY_STRING_VALUE ? "0" : adj.royaltyRate,
                inboundFeesInError: adj.inboundFeesInError === null ? EMPTY_STRING_VALUE : adj.inboundFeesInError,
                outboundFeesInError: adj.outboundFeesInError === null ? EMPTY_STRING_VALUE : adj.outboundFeesInError,
            }
            adjustmentsState.push(adjustmentState);
        });
        return adjustmentsState;
    }

    public static mapUsageGroupToMatchingWorks(usageGroup: IUsageGroup, usageType: string): IMatchingWorksRowData[] {
        if (!usageGroup.matches || usageGroup.matches.length <= 0 || usageType.replace(/([A-Z])/g, ' $1').trim() === USAGETOPRODUCT)
            return null;

        const matchingWorks: IMatchingWorksRowData[] = [];

        const searchQuery: IMatchWorksSearchQuery =
        {
            workIDs: usageGroup.matches.map(mw => mw.id),
            title: "",
            number: "",
            artist: "",
            writers: "",
            societyAccountNumber: "",
            workBatchID: "",
            dataSource: REPERTOIRE,
            hasOpenWorkflow: false
        };

        UsageSearchRequests.getWorksByIdList(searchQuery)
            .then((res: IMatchWorksSearchResult[]) => {
                res.forEach((w) => {
                    const match: IUsageMatch = usageGroup.matches.find(x => x.id === w.workID);

                    const matchingWork: IMatchingWorksRowData = {
                        status: startCase(match.matchType),
                        title: w.title,
                        composers: w.publishers.concat(w.writers),
                        performers: w.artists,
                        number: w.number[0],
                        source: startCase(match.matchSource),
                        workID: match.id
                    }
                    matchingWorks.push(matchingWork);
                });
            });

        return matchingWorks;
    }

    public static mapUsageGroupToMatchingProducts(usageGroup: IUsageGroup, usageType: string): IMatchingProductsRowData[] {
        if (!usageGroup.matches || usageGroup.matches.length <= 0 || usageType.replace(/([A-Z])/g, ' $1').trim() === USAGETOWORK)
            return null;

        const matchingProducts: IMatchingProductsRowData[] = [];

        const searchQuery: IMatchProductsSearchQuery =
        {
            productIDs: usageGroup.matches.map(mw => mw.id),
            title: "",
            number: "",
            artist: "",
            contributor: "",
            productBatchID: "",
            productType: usageGroup?.productType ? usageGroup?.productType : "",
            dataSource: REPERTOIRE,
        };

        UsageSearchRequests.getProducts(searchQuery)
            .then((res: IProductSearchResult[]) => {
                res.forEach((w) => {
                    const match: IUsageMatch = usageGroup.matches.find(x => x.id === w.productCoreID);
                    const matchingProduct: IMatchingProductsRowData = {
                        status: startCase(match.matchType),
                        id1: w.id1,
                        id2: w.id2,
                        artist: w.artist,
                        contributor: w.contributor,
                        productCoreID: w.productCoreID,
                        source: startCase(match.matchSource),
                        title: w.title,
                        type: w.type,
                        number: w.number,
                        productionType:w.productionType,
                        musicDuration:w.musicDuration
                    }
                    matchingProducts.push(matchingProduct);
                });
            });

        return matchingProducts;
    }

    public static mapAttachmentsToFileState(attachments: IAttachedFile[]): IStoragePathItemData[] {
        const files: IStoragePathItemData[] = [];
        if (attachments) {
            attachments.map(file => {
                files.push(
                    {
                        fullName: `repertoireAttachments/usage/${file.guid}`,
                        inProgress: false,
                        isDirectory: false,
                        isDirty: false,
                        isEditing: false,
                        lastModified: file.lastModifiedDate && file.lastModifiedDate.toString(),
                        sources: [],
                        metadata: undefined,
                        name: file.fileName,
                        pools: [],
                        formats: [],
                        distributions: [],
                        validationError: undefined,
                        attachmentTypeID: file.attachmentTypeID,
                        attachmentTypeCode: file.attachmentTypeCode,
                    }
                )
            })
        }
        return files;
    }

    public static mapStateToAttachedFiles(files: IStoragePathItemData[], usageGroupID: string): IAttachedFile[] {
        const attachedFiles: IAttachedFile[] = [];
        if (files) {
            files.map(
                file => {
                    let guid: string = "";
                    const fullNameSplit: string[] = file.fullName.split('/');
                    if (fullNameSplit.length > 2)
                        guid = fullNameSplit[2];

                    attachedFiles.push({
                        fileName: file.name,
                        usageGroupID: usageGroupID,
                        guid: guid,
                        attachmentTypeID: file.attachmentTypeID,
                        attachmentTypeCode: file.attachmentTypeCode,
                    })
                }
            )
        }
        return attachedFiles;
    }
    public static createNewUsage(lookups: ILookupDictionary, distributionCode?: string): IUsageGroup {


        const usage: IUsageGroup = {
        id: "0",
        fullTitle: "",
        fullContributor: "",
        fullPerformer: "",
        distributionCode: distributionCode || "",
        poolCode: "",
        sourceMajor: "",
        sourceMinor: "",
        usageType:"",
        productType: getLookupDefault(PRODUCT_TYPE_LOOKUP, lookups),
        matchStatus: "",
        allocationStatus: "",
        allocations: null,
        remittingSocietyWorkCode: "",
        fileName: "",
        dateLoaded: "",
        totalWeight: 0,
        usages: [
        {
            id: "0",
    bundle: "",
    bundleRetailPriceExTax: "",
    bundleTracks: "",
    bundleTracksPayable: "",
    carrierType: "",
    cartNumber: "",
    contributors: [],
    count: 0,
    distributionMethod: "",
    durationPerPlay: 0,
    inDispute: "",
    musicDuration: null,
    names: [],
    performanceNumbers: [],
    performers: [],
    programEndDate: "",
    programName: "",
    programStartDate: "",
    publisher: "",
    receivedAmount: "",
    recordLabel: "",
    recordName: "",
    recordingDate: "",
    recordingType: "",
    releaseDate: "",
    royaltyAmount: "",
    royaltyAmountPerPerformance: "",
    timing: "",
    trackNumber: "",
    unitRetailPrice: "",
    unitRetailPriceExVat: "",
    usageDate:"",
    exploitationTerritoryAbbreviatedName:"",
    usageDetails: [],
    weight: 0,
    productDetails: [],
    amountForAllocationRule: null
    }
    ],
    matches: [],
    workflows: [],
    attachedFiles: [],
    location: null,
    setListAdditional: {
        otherArtistPerform: false,
        performanceType: '',
        promoter: '',
        stage: ''},
    venueDetails:{
        firstName:'',
        lastName:'',
        phoneNumber:'',
        venueType:'',
        otherTypeOfVenue:''
    },
    moreDates:[
    ],
        qaStatus: null,
        rejectReason: null,
        }

        return usage;
    }

    public static createNewUsageState(lookups: ILookupDictionary, distributionCode?: string, currentUserNameNumber?: number): IUsageGroupState {
        let usageGroup = UsageDetailsMapper.createNewUsage(lookups, distributionCode);
        if (currentUserNameNumber) {
            usageGroup.sourceIP = currentUserNameNumber;
            usageGroup.productType = "SET LIST";
        }
        return UsageDetailsMapper.mapUsageToState(usageGroup)
    }

}
