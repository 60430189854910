import * as React from "react";
import { ALL_ROLES, COMPONENT_INSTANCE_POOLS, DELETE_POOL_PROMPT_STATE_KEY, EVENT_HANDLER_THROTTLE_TIME, REPERTOIRE_SIDE_MENU_USAGE_POOLS_KEY, UPDATE_POOLS_ROLE, USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW, USAGE_POOL_MAINTENANCE_TOOLBAR } from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { showModal, showYesNoPrompt, hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { getDataAction } from "../../../components/toolBar/ToolbarHelper";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { addTab, clearModalSearchResults, removeTab, setChangesMade, setEditableFields, sortWeightSettingsFieldData, updateUsagePoolField } from "../../../../redux/reducers/RepertoireReducer";
import UsagePoolMaintenanceToolbar, { IUsagePoolMaintenanceToolbarProps } from "../poolMaintenanceToolbar/PoolMaintenanceToolbar";
import UsagePoolMaintenanceGeneralDataView from "../poolMaintenanceGeneralDataView/PoolMaintenanceGeneralDataView";
import { IUsagePoolState } from "../../../../redux/types/IUsagePoolState";
import { ITreeData } from "../../../types/ITreeData";
import UsagePoolMaintenanceGridsView from "../poolMaintenanceGridsView/PoolMaintenanceGridsView";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import UsagePoolMaintenanceGeneralDataWrapperFormik from "../poolMaintenanceGeneralDataView/UsagePoolMaintenanceGeneralDataViewFormikWrapper";

export interface IUsagePoolMaintenancePageProps {
    usagePoolMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    usagePoolMaintenanceToolbarData?: IRepertoireComponentDataItem;
    usagePoolMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    usagePoolMaintenanceGridsViewData: IRepertoireComponentDataItem;
    weightSettingsPoolMaintenance?: IRepertoireComponentDataItem;
    usagePoolMaintenanceAllocationSettingsGridViewData: IRepertoireComponentDataItem;
    usagePoolMaintenanceWeightSettingsGridViewData: IRepertoireComponentDataItem;
    usagePoolMaintenanceEstimatedValuePerPointSettingsGridViewData: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    pool: IUsagePoolState;
    setChangesMade: typeof setChangesMade;
    activeAccordion: string[];
    updateUsagePoolField: typeof updateUsagePoolField;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: typeof showModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    removeTab: typeof removeTab;
    addTab: typeof addTab;
    saveChanges: (pool: IUsagePoolState) => void;
    deleteUsagePool: (poolID: number, poolCode: string, sources: ITreeData[], activeTab: number) => void;
    copyUsagePool: (dataAction: IDataActionToolbar[]) => void;
    resetMessageBanner: () => void;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    showUpdateFieldsModal: typeof showUpdateFieldsModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    lookupValues: ILookupDictionary;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    editableFields: IRepertoireField[];
    sources?: ITreeData[];
    clearModalSearchResults: typeof clearModalSearchResults;
    searchPoolVersionHistory: (poolID: number, activeTabItem: ITabReduxItem, actionList: IDataActionToolbar[], sources: ITreeData[]) => void;
    roles?: string[];
    incrementTempID?: () => void;
    sortWeightSettingsFieldData: typeof sortWeightSettingsFieldData;
    updatedScroll?: number;
    useNewUIPoolMaintenanceGeneralView?: boolean;
    setValidationWarningMessage: (value: boolean) => void;
}

interface IUsagePoolMaintenancePageState {
    toolbarWidth: number;
    isBulkCheckboxActive?: boolean;
    isChangeGeneralForm: boolean;
}
export type IUsagePoolMaintenanceToolbarKeys = keyof IUsagePoolMaintenancePageProps;

export class PoolMaintenancePage extends React.PureComponent<
    IUsagePoolMaintenancePageProps,
    IUsagePoolMaintenancePageState
> {
    toolbarParentRef;
    poolGeneralDataFormikRef;
    constructor(props: IUsagePoolMaintenancePageProps) {
        super(props)
        this.state = {
            toolbarWidth: null,
            isBulkCheckboxActive:false,
            isChangeGeneralForm: false,
        };
        this.toolbarParentRef = React.createRef();
        this. poolGeneralDataFormikRef = React.createRef();
    }

    componentDidMount(): void {
        setEditableFields(USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_POOLS)
    }

    handleSubmitGeneralPoolDataFormikForm = async () => {
        if (this.poolGeneralDataFormikRef.current) {
            await this.poolGeneralDataFormikRef.current.submitForm(); 
            await this.poolGeneralDataFormikRef.current.validateForm(this.poolGeneralDataFormikRef.current.values);                 
        }
    };

    saveUsagePool = async () => {
        const { saveChanges, pool, useNewUIPoolMaintenanceGeneralView, setValidationWarningMessage } = this.props;
        this.setState({ isBulkCheckboxActive: false });

        let newPool = pool;

        if (useNewUIPoolMaintenanceGeneralView && this.poolGeneralDataFormikRef.current) {
            await this.handleSubmitGeneralPoolDataFormikForm()
            if (this.poolGeneralDataFormikRef.current.errors && Object.keys(this.poolGeneralDataFormikRef.current.errors).length > 0) {
                setValidationWarningMessage(true);
                return;
            }
            let generalPool = this.poolGeneralDataFormikRef.current.values;
         newPool = {
            ...pool, 
            cat1: generalPool.cat1,
            cat2: generalPool.cat2,
            allocationRule: generalPool?.allocationRule?.data ? generalPool.allocationRule.data: null,
            description: generalPool.description,
            distributionCategoryType: generalPool?.distributionCategoryType?.data ? generalPool.distributionCategoryType.data: null,
            invoice: generalPool.invoice,
            licenseeID: generalPool.licenseeID,
            payment: generalPool.payment,
            poolCode: generalPool.poolCode,
            isForeign: generalPool.isForeign
         };

            this.setIsChangeGeneralForm(false);
        }
        saveChanges(newPool);
    }

    setIsChangeGeneralForm = (isChangeGeneralForm) => {
        this.setState({
            isChangeGeneralForm: isChangeGeneralForm
        });
    }


    deleteAction() {
        const { removeTab, usagePoolMaintenanceToolbarData, showYesNoPrompt, hideModal, activeTab } = this.props;

        const title: string = usagePoolMaintenanceToolbarData.fields.find(f => f.name === DELETE_POOL_PROMPT_STATE_KEY).data;
        const props: IYesNoPromptViewModalProps = {
            yesCallback: () => {
                this.deleteUsagePool();
                hideModal();
            },
            noCallback: () => { hideModal(); }
        }
        showYesNoPrompt(title, props);
    }

    deleteUsagePool() {
        const { deleteUsagePool, pool, sources, activeTab } = this.props;
        deleteUsagePool(pool.poolID, pool.poolCode, sources, activeTab);
    }

    undoChanges = () => {
        const { pool } = this.props;
        //TODO
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    onUpdateComponentFieldItems(fields: IRepertoireField[], componentName: string) {
        const { updateComponentFieldsItems, activeTab, tabs } = this.props;
        const dataActions = getDataAction("", USAGE_POOL_MAINTENANCE_TOOLBAR);
        updateComponentFieldsItems(fields, componentName, COMPONENT_INSTANCE_POOLS, REPERTOIRE_SIDE_MENU_USAGE_POOLS_KEY, tabs, activeTab)
    }
    changeBulkCheckbox = (value: boolean) => {
        this.setState({ isBulkCheckboxActive: value });
    }

    render() {
        const {
            usagePoolMaintenanceGeneralDataViewData,
            usagePoolMaintenanceToolbarData,
            usagePoolMaintenanceSaveResultData,
            usagePoolMaintenanceGridsViewData,
            weightSettingsPoolMaintenance,
            usagePoolMaintenanceAllocationSettingsGridViewData,
            usagePoolMaintenanceWeightSettingsGridViewData,
            usagePoolMaintenanceEstimatedValuePerPointSettingsGridViewData,
            dataGridTableData,
            setChangesMade,
            updateUsagePoolField,
            pool,
            tabs,
            activeTab,
            showModal,
            hideModal,
            setEditableFields,
            updateComponentFieldsItems,
            editableFields,
            editableFieldsDataView,
            showUpdateFieldsModal,
            lookupValues,
            sources,
            copyUsagePool,
            clearModalSearchResults,
            searchPoolVersionHistory,
            roles,
            incrementTempID,
            sortWeightSettingsFieldData,
            updatedScroll,
            useNewUIPoolMaintenanceGeneralView
        } = this.props;
        const {
            toolbarWidth,
            isBulkCheckboxActive
        } = this.state;
        const isReadonly: boolean = tabs[activeTab].isReadonly;

        if (this.props.pool) {
            const {
                poolCode,
                description,
                cat1,
                cat2,
                allocationRule,
                distributionCategoryType,
                invoice,
                poolEnabled,
                licenseeID,
                poolSources,
                poolRightTypes,
                payment,
                poolAllocationSettings,
                poolWeightSettings,
                datesAndDimensions,
                weightSettingsIsEnabled,
                evppSettingsIsEnabled,
                poolEstimatedValuePerPointSettings,
                isForeign
            } = this.props.pool


            const {
                toolbarWidth,
                isChangeGeneralForm
            } = this.state;

            const changesMade = tabs[activeTab].changesMade || (useNewUIPoolMaintenanceGeneralView && isChangeGeneralForm);

            const title = poolCode;
            return (
                <div>
                    <div><span className="title">{title}</span></div>
                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>
                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <UsagePoolMaintenanceToolbar
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            changesMade={changesMade}
                            saveResultData={usagePoolMaintenanceSaveResultData}
                            toolbarData={usagePoolMaintenanceToolbarData}
                            saveChanges={this.saveUsagePool.bind(this)}
                            deleteUsagePool={this.deleteAction.bind(this)}
                            copyUsagePool={copyUsagePool}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            activeTab={activeTab}
                            tabs={tabs}
                            showYesNoPrompt={showYesNoPrompt}
                            hideModal={hideModal}
                            pool={pool}
                            isReadonly={isReadonly}
                            clearModalSearchResults={clearModalSearchResults}
                            searchVersionHistory={searchPoolVersionHistory}
                            sources={sources}
                            roles={roles}
                        />
                    </div>

                    {useNewUIPoolMaintenanceGeneralView ? <UsagePoolMaintenanceGeneralDataWrapperFormik 
                        usagePoolMaintenanceGeneralDataViewData={usagePoolMaintenanceGeneralDataViewData}
                        poolCode={poolCode}
                        description={description}
                        cat1={cat1}
                        cat2={cat2}
                        allocationRule={allocationRule}
                        invoice={invoice}
                        poolEnabled={poolEnabled}
                        licenseeID={licenseeID}
                        updateUsagePoolField={updateUsagePoolField}
                        changesMade={changesMade}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        editableFieldsDataView={editableFieldsDataView}
                        dataGridTableEditableFieldsData={usagePoolMaintenanceGridsViewData}
                        setEditableFields={setEditableFields}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        editableFields={editableFields}
                        activeTab={activeTab}
                        tabs={tabs}
                        hideModal={hideModal}
                        showModal={showModal}
                        isReadonly={isReadonly}
                        lookupValues={lookupValues}
                        payment={payment}
                        roles={roles}
                        dataGridTableData={dataGridTableData}
                        distributionCategoryType={distributionCategoryType}
                        poolDataFormikRef={this.poolGeneralDataFormikRef}
                        setIsChangeGeneralForm={this.setIsChangeGeneralForm}
                        isForeign={isForeign}
                    /> : 
                    <UsagePoolMaintenanceGeneralDataView
                        usagePoolMaintenanceGeneralDataViewData={usagePoolMaintenanceGeneralDataViewData}
                        poolCode={poolCode}
                        description={description}
                        cat1={cat1}
                        cat2={cat2}
                        allocationRule={allocationRule}
                        invoice={invoice}
                        poolEnabled={poolEnabled}
                        licenseeID={licenseeID}
                        updateUsagePoolField={updateUsagePoolField}
                        changesMade={changesMade}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        editableFieldsDataView={editableFieldsDataView}
                        dataGridTableEditableFieldsData={usagePoolMaintenanceGridsViewData}
                        setEditableFields={setEditableFields}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        editableFields={editableFields}
                        activeTab={activeTab}
                        tabs={tabs}
                        hideModal={hideModal}
                        showModal={showModal}
                        isReadonly={isReadonly}
                        lookupValues={lookupValues}
                        payment={payment}
                        roles={roles}
                        distributionCategoryType={distributionCategoryType}
                        isForeign={isForeign}
                    />}
                    <UsagePoolMaintenanceGridsView
                        weightSettingsPoolMaintenance={weightSettingsPoolMaintenance}
                        poolEnabled={poolEnabled}
                        usagePoolSources={poolSources}
                        dataGridTableData={dataGridTableData}
                        usagePoolMaintenanceGridsViewData={usagePoolMaintenanceGridsViewData}
                        usagePoolMaintenanceAllocationSettingsGridViewData={usagePoolMaintenanceAllocationSettingsGridViewData}
                        usagePoolMaintenanceWeightSettingsGridViewData={usagePoolMaintenanceWeightSettingsGridViewData}
                        usagePoolMaintenanceEstimatedValuePerPointSettingsGridViewData={usagePoolMaintenanceEstimatedValuePerPointSettingsGridViewData}
                        tabs={tabs}
                        activeTab={activeTab}
                        updateUsagePoolField={updateUsagePoolField}
                        lookupValues={lookupValues}
                        componentInstance={null}
                        isReadonly={isReadonly}
                        addTab={addTab}
                        rightTypes={poolRightTypes}
                        allocationSettings={poolAllocationSettings}
                        weightSettings={poolWeightSettings}
                        lookupSources={sources}
                        poolID={this.props.pool.poolID}
                        incrementTempID={incrementTempID}
                        datesAndDimensions={datesAndDimensions}
                        saveChanges={this.saveUsagePool.bind(this)}
                        weightSettingsIsEnabled={weightSettingsIsEnabled}
                        evppSettingsIsEnabled={evppSettingsIsEnabled}
                        poolEVPPSettings={poolEstimatedValuePerPointSettings}
                        sortWeightSettingsFieldData={sortWeightSettingsFieldData}
                        isBulkCheckboxActive={isBulkCheckboxActive}
                        changeBulkCheckbox={this.changeBulkCheckbox}
                    />
                </div>

            );
        }
        return <div>Loading...</div>;
    }
}

