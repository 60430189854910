import * as React from "react";
import { hideModal, showModal } from "../../../redux/reducers/ModalReducer";
import { getLookupValues } from '../../../lookup/services/LookupHelpers';
import { JOB_TYPE_LOOKUP } from "../../../lookup/Consts";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { BookExportJobParameters } from "../jobManualView/BookExportJobParameters";
import { SaveAndClose } from "../jobManualView/SaveAndClose";
import {
     getCWRAckHWM,
     getAutomaticAgreementGHWM, 
     getEmuwHwm, 
     getManualJobDataSources, 
     getOpenDistributionsThunk, 
     getPoolsThunk, 
     getUsageFilenames,
     upsertScheduledJobAsync,
     getScheduledJob,
     getAllUsageFilenamesFromDistribution, 
     triggerManualJob, 
     getAllDistributionsThunk} from "../../../redux/thunks/HomeThunks";
import { ITriggerManualJob } from "../../../redux/types/ITriggerManualJob";
import { 
    BOOK_EXPORT_JOB_TYPE, 
    CWR_ACK2_JOB_TYPE, 
    UNDISTRIBUTED_SHARES_JOB_TYPE, 
    SUSPENSE_RELEASE_JOB_TYPE, 
    UNIDENTIFIED_PERFORMANCE_JOB_TYPE, 
    PD_RULES_JOB_TYPE, 
    GENERATE_STATEMENT_DETAILS_JOB_TYPE, 
    GENERATE_PAYMENT_DETAILS_JOB_TYPE, 
    MANUAL_USAGE_MATCHING_JOB_TYPE, 
    POST_STATEMENTS_ON_PORTAL_JOB_TYPE, 
    EXTRACT_MATCHED_USAGE_WORKS_JOB_TYPE, 
    GENERATE_BUSINESS_CENTRAL_PAYMENT_JOB_TYPE, 
    BOOK_CLAIM_SUSPENSE_RELEASE_JOB_TYPE, 
    AAG_JOB_TYPE, 
    CISNet_JOB_TYPE, 
    CWR_GENERATION_JOB_TYPE,
    AVI_SUBMISSION_JOB_TYPE,
    ATF_JOB_TYPE, 
    DRAFT_AGREEMENT_SUBMISSION_JOB_TYPE,
    MANUAL_UNDO_USAGE_INGESTION_JOB_TYPE} from "../../Consts";
import { IAckHWMState } from "../../types/IAckHWMState";
import { IAutomaticAgreementGHWMState } from "../../types/IAutomaticAgreementGHWMState";
import { CWRACK2JobParameters } from "../jobManualView/CWRACK2JobParameters";
import { AutomaticAgreementGHWM } from "../jobManualView/AutomaticAgreementGHWM";
import { ComboBoxJobParameterControl } from "../../types/JobParameterControls/ComboBoxJobParameterControl";
import { MultiselectJobParameterControl } from "../../types/JobParameterControls/MultiselectJobParameterControl";
import { NumericJobParameterControl } from "../../types/JobParameterControls/NumericJobParameterControl";
import { JobParameterControlCollection } from "../../types/JobParameterControls/JobParameterControlCollection";
import { IDataSource, IDataSourceBase } from "../../types/JobParameterControls/IDataSource";
import { IGenericJobTypeLookupInstance } from "../../types/IGenericJobTypeLookupInstance";
import { IJobParameterControlBase } from "../../types/JobParameterControls/IJobParameterControlBase";
import { JobParameterControlFactory } from "../../helpers/JobParameterControlFactory";
import { JobParameterCustomEvents } from "../../helpers/JobParameterCustomEvents";
import { GenerateStatementDetailsJobParameters } from "../jobManualView/GenerateStatementDetailsJobParameters";
import { IComponentDataItem } from "../../../core/types/IComponentDataItem";
import DropdownDataInput from "../../../repertoire/components/dropdownDataInput/DropdownDataInput";
import { ComboBox } from "office-ui-fabric-react";
import { ExtractMatchedUsageWorksJobParameters } from "../jobManualView/ExtractMatchedUsageWorksJobParameters";
import USGMFormFieldsView from "./USGMFormFieldsView";
import { ICEATFJobParameters } from "../jobManualView/ICEATFJobParameters";
import CWRGenerationJobParameters from "../jobManualView/CWRGenerationJobParameters";
import { ICwrgParameters } from "../../../redux/types/ICwrgParameters";
import DraftAgreementSubmissionPostingJobParameters from "../jobManualView/DraftAgreementSubmissionPostingJobParameters";
import UUIFormFieldsView from "./UUIFormFieldsView";


export interface IManualJobViewModalProps {
    manualJobDetails: any[];
    getManualJobParameters: (value: any) => void;
}

export interface IManualJobViewProps {
    lookups: ILookupDictionary;
    props: IManualJobViewModalProps;
    hideModal: typeof hideModal;
    showModal: typeof showModal;
    manualJobType: string;
    manualJobParameters: any;
    triggerManualJob: typeof triggerManualJob;
    getUsageFilenames:typeof getUsageFilenames;
    getScheduledJob:typeof getScheduledJob;
    upsertScheduledJobAsync:typeof upsertScheduledJobAsync;
    getAllUsageFilenamesFromDistribution:typeof getAllUsageFilenamesFromDistribution;
    cwrAckHWM: IAckHWMState[];
    automaticAgreementGHWM: IAutomaticAgreementGHWMState[];
    getCWRAckHWM: typeof getCWRAckHWM;
    getAutomaticAgreementGHWM: typeof getAutomaticAgreementGHWM;
    getEmuwHwm: (distributionId: number) => void
    manualJobDataSources: { [key: string]: IDataSourceBase };
    manualJobViewData: IComponentDataItem;
    getPaymentRunIDs: () => void;
    paymentRunIDs: any;
    createNewJob?: () => any;
    updateManualJobParams: (params: any) => void;
    getOpenDistributions: typeof getOpenDistributionsThunk;
    getAllDistributions: typeof getAllDistributionsThunk;
    getPools: typeof getPoolsThunk;
    openDistributions?: any;
    allDistributions?:any;
    pools?: any;
    emuwHighWaterMark?: any;
    usageFileNames?:any;
    scheduledJob?: any;
    getCWRGHighWaterMark?: (cwrgParams: ICwrgParameters) => void;
    cwrgHighWaterMark?: any;
    setCWRGHighWaterMark?: (hwm: string) => void;
    getSeparatePublishedCheckbox?: () => void;
    separatePublishedCheckbox?: boolean;
    getDaspHighWaterMark?: () => void;
    daspHighWaterMark?: any;
    setDaspHighWaterMark?: (hwm: string) => void;
}

interface IManualJobViewState {
    jobType: string;
    selectedValue?: string;
    isUseAllDistributionEnabled: boolean;
}


export class ManualJobView extends React.Component<IManualJobViewProps, IManualJobViewState> {

    constructor(props: IManualJobViewProps) {
        super(props);

        this.state = {
            jobType: "",
            selectedValue: "",
            isUseAllDistributionEnabled: false
        }
    }

    renderJobTypes = () => {
        const { lookups } = this.props;
        const { jobType } = this.state;
        const jobTypes = getLookupValues(JOB_TYPE_LOOKUP, lookups);
        const options = [];
        options.push(<option value="empty" title="emtpy" key=""></option>)
        if (jobTypes) {
            jobTypes.sort((x,y) => x.code.localeCompare(y.code))
                .filter(x => x.extraFields["AllowManualRun"] == true).map((j) => {
                const selected: boolean = j.code === jobType;
                options.push(<option defaultValue={j.description} value={j.code} title={j.description} key={j.code}>{[j.code, j.description].join(' - ')}</option>)
            })

        }
        return options;
    }

    setJobType = (jobType: string | boolean | string[]) => {
        const { getCWRAckHWM, getAutomaticAgreementGHWM } = this.props;
        if (jobType === CWR_ACK2_JOB_TYPE) {
            getCWRAckHWM();
        }
        if (jobType === AAG_JOB_TYPE) {
            getAutomaticAgreementGHWM();
        }

        this.setState({ jobType: jobType.toString() });
    }

    setManualJobParameters(manualJobParameters) {
        const { updateManualJobParams, getEmuwHwm } = this.props
        const { isUseAllDistributionEnabled } =this.state

        if (manualJobParameters.jobType === EXTRACT_MATCHED_USAGE_WORKS_JOB_TYPE) {
            const jobParameters = JSON.parse(manualJobParameters.jobParameters);
            
            let selectedDistributionId = jobParameters.selectedOpenDistributionID;
            getEmuwHwm(selectedDistributionId);
        }

        updateManualJobParams(manualJobParameters);
    }

    replaceJobControl = (jobControls, control) => {
        let newJobControls = jobControls.slice(0);
        const index = jobControls.indexOf(control);
        newJobControls[index] = control;
        return newJobControls;
    }

    filterPoolsByDistributionForUsageJob(jobControls, jobType) {
        const distributionCode = this.state.selectedValue;

        let poolControl = jobControls.find(x => x.ControlID === `${ jobType }_Pool`);
        let dataControls = poolControl.DataSourceRepo;
        const distribution = dataControls.openDistributions.find(x => x.code == distributionCode);
        const poolCodesInDistribution = distribution ? distribution.pools : [];
        poolControl = poolControl as ComboBoxJobParameterControl;
        poolControl.FilteredValues = poolCodesInDistribution;
        return this.replaceJobControl(jobControls, poolControl);
    }

    selectValue = (selectedValue) => {
        this.setState({ selectedValue: selectedValue });
    }

    getFormControlByJobType(jobType: string): JobParameterControlCollection {
        const { lookups, manualJobDataSources } = this.props;
        let result = new JobParameterControlCollection();
        const jobTypes = getLookupValues(JOB_TYPE_LOOKUP, lookups);
        let selectedJob = jobTypes.filter(e => e.code == jobType)[0];

        let genericJob = selectedJob as IGenericJobTypeLookupInstance;

        if ((genericJob.extraFields.GenericParametersJson) && (genericJob.extraFields.GenericParametersJson != '')) {
            let genericJobBaseParameters = JSON.parse(genericJob.extraFields.GenericParametersJson) as { controlList: IJobParameterControlBase[] }
            let jobControls = JobParameterControlFactory.InstantiateControlList(genericJobBaseParameters.controlList, manualJobDataSources)
            JobParameterCustomEvents.SubscribeCustomEvents(jobControls, jobType)
            if (jobType === MANUAL_USAGE_MATCHING_JOB_TYPE || jobType === MANUAL_UNDO_USAGE_INGESTION_JOB_TYPE) {
                let distributionControl: ComboBoxJobParameterControl = (jobControls.find(x => x.ControlID === `${ jobType }_Distribution`) as ComboBoxJobParameterControl);
                distributionControl.OnSelect = this.selectValue
                jobControls = this.filterPoolsByDistributionForUsageJob(jobControls, jobType);
                distributionControl.CurrentValue = this.state.selectedValue;
                jobControls = this.replaceJobControl(jobControls, distributionControl);
            }
            result.addRange(jobControls);
        }

        return result;
    }

    renderJobSelect = () => {
        return (
            <DropdownDataInput
                label="Select Job Type"
                value={this.state.jobType}
                fieldName={"selectJobType"}
                options={this.renderJobTypes()}
                allowNull={true}
                isHidden={false}
                changeData={this.setJobType}
            />
        )
    }

    renderJobParam = () => {
        const { 
            manualJobType, 
            hideModal, 
            triggerManualJob,
            getUsageFilenames,
            usageFileNames,
            getAllUsageFilenamesFromDistribution,
            getScheduledJob,
            upsertScheduledJobAsync,
            scheduledJob,
            manualJobDataSources, 
            cwrAckHWM, 
            automaticAgreementGHWM,
            manualJobViewData, 
            lookups, 
            getPaymentRunIDs, 
            paymentRunIDs, 
            manualJobParameters, 
            updateManualJobParams, 
            createNewJob, 
            showModal,
            getOpenDistributions,
            getAllDistributions, getPools, openDistributions,allDistributions, pools, emuwHighWaterMark } = this.props;
        const { jobType, isUseAllDistributionEnabled } = this.state;

        if (jobType == CWR_GENERATION_JOB_TYPE) {
            return <>
                <CWRGenerationJobParameters
                    globalManualJobParams={manualJobParameters}
                    showModal={this.props.showModal}
                    lookupValues={lookups}
                    upsertScheduledJobAsync={upsertScheduledJobAsync}
                    createNewJob={this.props.createNewJob.bind(this)}
                    getCWRGHighWaterMark={this.props.getCWRGHighWaterMark.bind(this)}
                    setCWRGHighWaterMark={this.props.setCWRGHighWaterMark.bind(this)}
                    cwrgHighWaterMark={this.props.cwrgHighWaterMark}
                    updateManualJobParams={updateManualJobParams}
                    manualJobParameters={manualJobParameters}
                    triggerManualJob={triggerManualJob}
                    getSeparatePublishedCheckbox={this.props.getSeparatePublishedCheckbox.bind(this)}
                    separatePublishedCheckbox={this.props.separatePublishedCheckbox}
                />
            </>
        } else if (jobType == DRAFT_AGREEMENT_SUBMISSION_JOB_TYPE) {
            return <>
                <DraftAgreementSubmissionPostingJobParameters
                    createNewJob={this.props.createNewJob.bind(this)}
                    getDaspHighWaterMark={this.props.getDaspHighWaterMark.bind(this)}
                    setDaspHighWaterMark={this.props.setDaspHighWaterMark.bind(this)}
                    daspHighWaterMark={this.props.daspHighWaterMark}
                    updateManualJobParams={updateManualJobParams}
                    triggerManualJob={triggerManualJob}
                />
            </>
        } else if (jobType == BOOK_EXPORT_JOB_TYPE) {
            return <>
                <div className="row">
                    <BookExportJobParameters globalManualJobParams={manualJobParameters} showModal={this.props.showModal} setManualJobParameters={this.setManualJobParameters.bind(this)} lookupValues={lookups} />
                </div>
                <div className="row">
                    <SaveAndClose updateManualJobParams={updateManualJobParams} manualJobParameters={manualJobParameters} triggerManualJob={triggerManualJob} createNewJob={this.props.createNewJob.bind(this)} />
                </div>
            </>
        }
        else if(jobType == ATF_JOB_TYPE){
            return <ICEATFJobParameters 
                createNewJob={this.props.createNewJob.bind(this)}
                triggerManualJob={triggerManualJob}
                setManualJobParameters={this.setManualJobParameters.bind(this)}
                getOpenDistributions={getOpenDistributions}
                openDistributions={openDistributions}
                lookupValues={lookups}
            />
            
        } else if (jobType == CWR_ACK2_JOB_TYPE) {
            return <>
                <div className="row">
                    <CWRACK2JobParameters setManualJobParameters={this.setManualJobParameters.bind(this)} cwrAckHWM={cwrAckHWM} lookups={lookups} />
                </div>
                <div className="row">
                    <SaveAndClose manualJobParameters={manualJobParameters} triggerManualJob={triggerManualJob} createNewJob={this.props.createNewJob.bind(this)} />
                </div>
            </>
        } else if(jobType == AAG_JOB_TYPE){
            return<>
                <div className="row">
                    <AutomaticAgreementGHWM setManualJobParameters={this.setManualJobParameters.bind(this)} automaticAgreementGHWM={automaticAgreementGHWM} lookups={lookups} />
                </div>
                <div className="row">
                    <SaveAndClose manualJobParameters={manualJobParameters} triggerManualJob={triggerManualJob} createNewJob={this.props.createNewJob.bind(this)} />
                </div>
            </>
        } else if (jobType == EXTRACT_MATCHED_USAGE_WORKS_JOB_TYPE) {
            return <>
                <div className="row">
                    <ExtractMatchedUsageWorksJobParameters
                        setManualJobParameters={this.setManualJobParameters.bind(this)}
                        getOpenDistributions={getOpenDistributions}
                        getAllDistributions = {getAllDistributions}
                        getPools={getPools}
                        openDistributions={openDistributions}
                        allDistributions = {allDistributions}
                        pools={pools}
                        highWaterMark={emuwHighWaterMark}
                        isUseAllDistributionEnabled = {isUseAllDistributionEnabled}
                        handleIsUseAllDistributionCheckbox = {() => this.setState({ isUseAllDistributionEnabled: !isUseAllDistributionEnabled })}
                    />
                </div>
                <div className="row">
                    <SaveAndClose manualJobParameters={manualJobParameters} triggerManualJob={triggerManualJob} createNewJob={this.props.createNewJob.bind(this)} />
                </div>
            </>
        } else if (jobType === MANUAL_USAGE_MATCHING_JOB_TYPE) {
            let formControls: JobParameterControlCollection = this.getFormControlByJobType(MANUAL_USAGE_MATCHING_JOB_TYPE)
            return <USGMFormFieldsView
                triggerManualJob={triggerManualJob} 
                createNewJob={this.props.createNewJob.bind(this)}
                manualJobDataSources={manualJobDataSources}
                lookups={lookups}
                upsertScheduledJobAsync={upsertScheduledJobAsync}
                getUsageFilenames={getUsageFilenames}
                getAllUsageFilenamesFromDistribution={getAllUsageFilenamesFromDistribution}
                usageFileNames={usageFileNames}
                getScheduledJob={getScheduledJob}
                scheduledJob={scheduledJob}
            />
        } else if (jobType === MANUAL_UNDO_USAGE_INGESTION_JOB_TYPE) {
            let formControls: JobParameterControlCollection = this.getFormControlByJobType(MANUAL_UNDO_USAGE_INGESTION_JOB_TYPE)
            return <UUIFormFieldsView
                triggerManualJob={triggerManualJob} 
                createNewJob={this.props.createNewJob.bind(this)}
                manualJobDataSources={manualJobDataSources}
                lookups={lookups}
                upsertScheduledJobAsync={upsertScheduledJobAsync}
                getUsageFilenames={getUsageFilenames}
                getAllUsageFilenamesFromDistribution={getAllUsageFilenamesFromDistribution}
                usageFileNames={usageFileNames}
                getScheduledJob={getScheduledJob}
                scheduledJob={scheduledJob}
            />
        } else if (jobType == UNIDENTIFIED_PERFORMANCE_JOB_TYPE || jobType == SUSPENSE_RELEASE_JOB_TYPE ||
            jobType == UNDISTRIBUTED_SHARES_JOB_TYPE || jobType == PD_RULES_JOB_TYPE ||jobType == CISNet_JOB_TYPE ||
            jobType == GENERATE_PAYMENT_DETAILS_JOB_TYPE || jobType == GENERATE_STATEMENT_DETAILS_JOB_TYPE ||
            jobType == POST_STATEMENTS_ON_PORTAL_JOB_TYPE || jobType == GENERATE_BUSINESS_CENTRAL_PAYMENT_JOB_TYPE ||
            jobType === BOOK_CLAIM_SUSPENSE_RELEASE_JOB_TYPE || jobType == AVI_SUBMISSION_JOB_TYPE) {
            let formControls: JobParameterControlCollection = this.getFormControlByJobType(jobType)
            let setParamsAndTriggerManualJob = (manualJob) => {
                let params = JSON.stringify(formControls);
                return triggerManualJob({ jobType: jobType, jobParameters: params });
            }
            return <>
                <div className="row">
                    {formControls.Render()}
                </div>
                <div className="row">
                    <SaveAndClose manualJobParameters={manualJobParameters} triggerManualJob={setParamsAndTriggerManualJob} createNewJob={this.props.createNewJob.bind(this)} />
                </div>
            </>
        }
        else {
            return <div></div>
        }
    }

    render() {
        return (
            <div className="form-group col-md-12">
                <div className="row">
                    <div className="form-group col-md-6">
                        <label>Select Job Type:</label>
                        <select value={this.state.jobType}
                            onChange={event => this.setJobType(event.target.value)}>
                            {this.renderJobTypes()}
                        </select>
                    </div>
                    <div className="form-group col-md-6">
                    </div>
                </div>

                {this.renderJobParam()}
            </div>
        );
    }
}
