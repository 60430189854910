import * as React from "react";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IUsagesSearchQuery } from "../../../types/usageTypes/IUsagesSearchQuery";
import { IMatchWorksSearchQuery } from "../../../types/usageTypes/IMatchWorksSearchQuery";
import { IUsageGroupResult, IUsageGroupsSearchResult, IUsageSearchResultRowType } from "../../../types/usageTypes/IUsageGroupsSearchResult";
import { IMatchWorksSearchResult } from "../../../types/usageTypes/IMatchWorksSearchResult";
import CustomButton from "../customButton/CustomButton";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";

import {
    EMPTY_STRING_VALUE,
    SEARCH_VIEW_USAGES,
    DISTRIBUTION_SEARCH_USAGES_STATE_KEY,
    SOURCEMAJOR_SEARCH_USAGES_STATE_KEY,
    SOURCEMINOR_SEARCH_USAGES_STATE_KEY,
    USAGE_MATCHING_WORKS_SECTION_KEY,
    REPERTOIRE,
    USAGE_MATCHING_PRODUCTS_SECTION_KEY,
    USAGETOWORK,
    POOL_SEARCH_USAGES_STATE_KEY,
    SEARCH_VIEW_HAS_OPEN_WORKFLOW,
    DONOTCARE_ABOUTWORKFLOWS,
    WITHOUTOPEN_WORKFLOWS,
    HASOPEN_WORKFLOWS,
    WORK_IS_DISTRIBUTED_STATE_KEY,
    REG_DATE_STATE_KEY,
    USAGETYPE_SEARCH_USAGES_STATE_KEY,
    USAGETOPRODUCT_NO_SPACES,
    USAGE_GROUP_MATCH_STATUS_STATE_KEY,
    ADVANCED,
    REPERTOIRE_PAGE_FORM_ITEM_SIZE,
    USAGESEARCH_VIEW,
    SEARCH_VIEW_USAGES_USAGE_TO_WORK,
    SEARCH_VIEW_USAGES_USAGE_TO_PRODUCT,
    PAYMENTRUN_DISTRIBUTION_CODE_STATE_KEY,
} from "../../../Consts";
import { InputSizedDropdown } from "../../usageComponents/dropdownInput/DropdownInput";
import { IInlineActionButtonWrapper, SizedTextDataInput } from "../../usageComponents/textInput/TextInput";
import { ResultsTable } from "../resultTable/ResultsTable";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { getLookupValues, mapUsageLookupKey } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import { addMatchWorkToUsage, searchDataChanged, addMatchProductToUsage, updateUsageGroupMatches, addWorkOrProductToUsageSearchCriteria, updateAdjustmentField } from "../../../../redux/reducers/RepertoireReducer";
import { IMatchProductsSearchQuery } from "../../../types/usageTypes/IMatchProductsSearchQuery";
import { IProductSearchResult } from "../../../types/IProductSearchResult";
import { SizedDropdownTreeDataInput } from "../../dropdownTreeDataInput/DropdownTreeDataInput";
import { ITreeData } from "../../../types/ITreeData";
import { ReactElement } from "react";
import { SizedRadiobuttonDataInput } from "../../radioButtonDataInput/RadiobuttonDataInput";
import { ComponentsHelper } from "../../../../core/services/ComponentHelper";
import { SizedCheckboxDataInput } from "../../checkboxDataInput/CheckboxDataInput";
import SizedDateDataInput from "../../dateDataInput/DateDataInput";
import { IDistributionType } from "../../../types/usageTypes/IDistibutionType";
import { IUsageGroupState } from "../../../../redux/types/IUsageGroupState";
import { If } from "../../../../core/components/If";
import ShowMore from "../../showMore/ShowMore";
import SelectionTable from "../../../components/usageComponents/selectionTable/SelectionTable";
import SearchViewKeyEventListener from "../../searchView/SearchViewKeyEventListener";
import { IUsagePoolSearchQuery } from "../../../types/usageTypes/IUsagePoolSearchQuery";
import { IUsagePool } from "../../../types/usageTypes/IUsagePool";
import { IAdjustmentDetails } from "../../../../redux/types/IAdjustmentDetails";
import { IAdjustmentState, IAdjustmentStateKeys } from "../../../../redux/types/IAdjustmentState";
import { IDistributionSearchQuery } from "../../../types/usageTypes/IDistributionSearchQuery";
import { IDistribution } from "../../../types/usageTypes/IDistribution";


export interface IUsageSearchViewProps {
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchSuccessful: boolean;
    componentInstance: string;
    modalProps: any;
    searchUsages?: (query: IUsagesSearchQuery, modalOpen: boolean, usageType: string, lookupSources: ITreeData[], continuationToken?: string) => void;
    searchMatchWorks?: (query: IMatchWorksSearchQuery, modalOpen: boolean) => void;
    searchMatchProducts?: (query: IMatchProductsSearchQuery, modalOpen: boolean) => void;
    usageGroupsSearchResult?: IUsageGroupResult[];
    matchWorkSearchResult?: IMatchWorksSearchResult[];
    matchProductsSearchResult?: IProductSearchResult[];
    activeTab?: number;
    tabs?: ITabReduxItem[];
    scroll?: number;
    toolbarWidth?: number;
    dataGridTableData?: IRepertoireComponentDataItem;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    setIsHundredResultsPerPage?: (isHundredResultsPerPage: boolean) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    sourceMajors?: { code: string; description: string; }[];
    sourceMinors?: { code: string; description: string; }[];
    getUsageDetails: (usageID: string, openEntityForWorflowSession?: boolean) => void;
    getDistributionDetails?: (distributionID: number, lookupValues: ILookupDictionary) => void;
    getUsagePool?: (poolId: number, matchingSources: ITreeData[], isPoolMaintainence?: boolean) => void;
    distributions?: { id: number, code: string; description: string; }[];
    pools?: { id: number, code: string; description: string; }[];
    lookupValues?: ILookupDictionary;
    sourceMajor?: { code: string; description: string; }[];
    sourceMinor?: { code: string; description: string; }[];
    allocationStatus?: { code: string; description: string; }[];
    includeFeesInError?: { code: string; description: string; }[];
    matchStatus?: { code: string; description: string; }[];
    showModal?: typeof showModal;
    hideModal: typeof hideModal;
    expandedMatchWorkResults?: number[];
    usageExpandAll?: boolean,
    usageExpandAllResults?: () => void;
    expandMatchWorkResult: (matchWorkResults: number) => void;
    addMatchworkToUsage?: typeof addMatchWorkToUsage;
    addMatchProductToUsage?: typeof addMatchProductToUsage;
    searchDataChanged: typeof searchDataChanged;
    usageType?: string;
    onClickNumber?: (id: number) => void;
    lookupSources?: ITreeData[];
    onlySearchResults?: boolean;
    isDistributed?: boolean;
    dateOfRegistration?: string;
    sortSearchResults?: (name: string, results: any) => void;
    distributionTypes: IDistributionType[];
    usageGroup?: IUsageGroupState;
    continuationToken?: string;
    showFieldsModal?: (componentFieldName: string) => void;
    searchDisabled?: boolean;
    updateUsageGroupMatches?: typeof updateUsageGroupMatches;
    saveUsageGroup?: (updatedUsageGroup: IUsageSearchResultRowType) => void;
    saveResultData?: IRepertoireComponentDataItem;
    searchType?: string;
    expanderFieldsData?: IRepertoireField[];
    matchingEntityForUsageSearchCriteria?: { id: number, number: string };
    addWorkOrProductToUsageSearchCriteria?: typeof addWorkOrProductToUsageSearchCriteria;
    usageMatchingDefaultsWorks?: string[];
    usageMatchingDefaultsProducts?: string[];
    isHundredResultsPerPage?: boolean;
    matchingSources?: ITreeData[];
    searchUsagePoolsByCodeDistributionPools?: (query: IUsagePoolSearchQuery) => void;
    poolCodeToSearch?: string;
    updatePoolCodeToSearch?: (value: string) => void;
    customer?: string;
    usagePoolsSearchResults?: IUsagePool[];
    getWorkDetails?: (tableItem?: any) => void;
    updateAdjustmentDetail?: (value?: any) => void;
    adjustmentDetails?: IAdjustmentDetails[];
    adjustment?: IAdjustmentState;
    workAdjustmentIndex?: number;
    searchDistributionByCode?: (query: IDistributionSearchQuery) => void;
    distributionCodeToSearch?: string;
    updateDistributionCodeToSearch?: (value: string) => void;
    usageDistributionsSearchResults?: IDistribution[];
}

const initialState = () => {
    return {
        tableContents: [],
        type: EMPTY_STRING_VALUE,
        distribution: EMPTY_STRING_VALUE,
        pool: EMPTY_STRING_VALUE,
        fileName: "",
        title: "",
        composer: "",
        artist: "",
        sourceMajor: null,
        sourceMinor: EMPTY_STRING_VALUE,
        rightShareOwner: "",
        allocationStatus: EMPTY_STRING_VALUE,
        includeFeesInError: EMPTY_STRING_VALUE,
        totalWeight: "",
        licenseesWorkNumber: "",
        matchStatus: EMPTY_STRING_VALUE,
        setPageOne: 0,
        number: "",
        writers: "",
        societyAccountNumber: "",
        workBatchID: "",
        dataSource: REPERTOIRE,
        hasOpenWorkflow: null,
        productBatchID: "",
        contributor: "",
        productType: "",
        matches: "",
        matchID: null,
        titleContains: '',
        distributionsFilter: null,
        sourcesFilter: null,
        approvalStatus: EMPTY_STRING_VALUE,
        weight: "",
        estimatedValue: 0,
        estimatedFromValue: "",
        estimatedToValue: "",
        isLoading: false,
        performanceNumber: ""
    };
}

interface IUsageSearchViewState {
    tableContents: IUsageGroupsSearchResult[] | IMatchWorksSearchResult;
    type: string;
    distribution: string;
    pool: string;
    fileName: string;
    title: string;
    composer: string;
    artist: string;
    sourceMajor: number;
    sourceMinor: string;
    rightShareOwner: string;
    allocationStatus: string;
    includeFeesInError: string;
    totalWeight: string;
    licenseesWorkNumber: string;
    matchStatus: string;
    setPageOne: number;
    number: string;
    writers: string;
    societyAccountNumber: string;
    workBatchID: string;
    dataSource: string;
    hasOpenWorkflow: boolean;
    productBatchID: string;
    contributor: string;
    productType: string;
    matches: string;
    matchID: number;
    distributionsFilter: string[];
    titleContains: string;
    sourcesFilter: string[];
    matchingWork?: string;
    matchingProduct?: string;
    approvalStatus?: string;
    weight: string;
    estimatedValue?: number;
    estimatedFromValue?: string;
    estimatedToValue?: string;
    matchingSources?: ITreeData[];
    isLoading?: boolean;
    performanceNumber?: string;
}

interface IUsageSearchViewDataKeys {
    type: string;
    distribution: string;
    fileName: string;
    title: string;
    composer: string;
    artist: string;
    sourceMajor: string;
    sourceMinor: string;
    rightShareOwner: string;
    allocationStatus: string;
    includeFeesInError: string;
    totalWeight: string;
    licenseesWorkNumber: string;
    matchStatus: string;
    number: string;
    writers: string;
    societyAccountNumber: string;
    workBatchID: string;
    dataSource: string;
    hasOpenWorkflow: boolean;
    productBatchID: string;
    contributor: string;
    productType: string;
    matches: string;
    distributionsFilter: string[];
    titleContains: string;
    sourcesFilter: string[];
    matchingWork: string;
    matchingProduct: string;
    weight: string;
    estimatedValue?: number;
    estimatedFromValue?: string;
    estimatedToValue?: string;
    matchingSources?: ITreeData[];
}

export type UsageSearchViewStateKeys = keyof IUsageSearchViewDataKeys;

export default class UsageSearchView extends React.PureComponent<
    IUsageSearchViewProps,
    IUsageSearchViewState
> {
    constructor(props: IUsageSearchViewProps) {
        super(props);

        this.state = initialState();
    }

    componentDidMount = () => {

        const { usageGroup, searchDataChanged, usageMatchingDefaultsProducts, usageMatchingDefaultsWorks, componentInstance } = this.props;

        let usageMatchingDefaults = componentInstance === USAGE_MATCHING_PRODUCTS_SECTION_KEY ? usageMatchingDefaultsProducts :
            componentInstance === USAGE_MATCHING_WORKS_SECTION_KEY ? usageMatchingDefaultsWorks : []

        if (usageMatchingDefaults && usageGroup) {

            let defaultVal: any = null;
            usageMatchingDefaults.map((fieldName) => {

                switch (fieldName) {
                    case "title":
                        defaultVal = usageGroup.fullTitle.split('/')[0];
                        break;
                    case "artist":
                        defaultVal = usageGroup.fullPerformer.split('/')[0];
                        break;
                    case "writers":
                        defaultVal = usageGroup.fullContributor.split('/')[0];
                        break;
                    case "contributor":
                        defaultVal = usageGroup.fullContributor.split('/')[0];
                        break;

                    default:
                        defaultVal = null;
                        break;

                }
                if (defaultVal) {
                    this.setState({
                        [fieldName]: defaultVal
                    } as Pick<IUsageSearchViewState, keyof IUsageSearchViewDataKeys>);

                    searchDataChanged(fieldName, defaultVal);
                }
            });

        }

    };

    componentDidUpdate = (prevProps: IUsageSearchViewProps, prevState: IUsageSearchViewState) => {
        const { usageType, matchingEntityForUsageSearchCriteria } = this.props;
        const { titleContains, distributionsFilter } = this.state;
        this.setState({
            tableContents: undefined
        });
        if(prevProps.matchWorkSearchResult!== this.props.matchWorkSearchResult || prevProps.usageGroupsSearchResult!== this.props.usageGroupsSearchResult || prevProps.matchProductsSearchResult !== this.props.matchProductsSearchResult){
            this.setState({isLoading:false});
        }
        if ((!prevState.titleContains && titleContains) || titleContains !== prevState.titleContains)
            this.onClickSearch();
        else if (distributionsFilter) {
            if (!prevState.distributionsFilter)
                this.onClickSearch();
            else if (prevState.distributionsFilter && prevState.distributionsFilter.length !== distributionsFilter.length)
                this.onClickSearch();
            else if (prevState.distributionsFilter && prevState.distributionsFilter.length === distributionsFilter.length && !distributionsFilter.every((v, i) => v === prevState.distributionsFilter[i]))
                this.onClickSearch()
        }
        if (usageType === 'Usage To Work' && prevProps.matchingEntityForUsageSearchCriteria !== matchingEntityForUsageSearchCriteria) {
            this.setState(() => ({ matchingWork: matchingEntityForUsageSearchCriteria.number, matchID: matchingEntityForUsageSearchCriteria.id }));
        }
        else if (usageType === 'Usage To Product' && prevProps.matchingEntityForUsageSearchCriteria !== matchingEntityForUsageSearchCriteria) {
            this.setState(() => ({ matchingProduct: matchingEntityForUsageSearchCriteria.number, matchID: matchingEntityForUsageSearchCriteria.id }));
        }

    };

    changeData = (value: any, fieldName: IRepertoireStateKeys) => {
        const { searchDataChanged } = this.props;

        this.setState({
            [fieldName]: value
        } as Pick<IUsageSearchViewState, keyof IUsageSearchViewDataKeys>);

        searchDataChanged(fieldName, value);
    };

    changeDataDisabled = () => {
    }

    keyDownSubmit = (value: any, fieldName: IRepertoireStateKeys) => {
        if (value.key === 'Enter') {
            this.onClickSearch();
        }
    }

    disabledSearchFields = (name: string) => {
        let fieldsToCheck = ["ipBaseNumber", "ipNameNumber", "ipName", "ipiNumber", "fullName"];
        if (fieldsToCheck.indexOf(name) > -1) {
            if (this.state[fieldsToCheck.filter(x => x !== name)[0]] || this.state[fieldsToCheck.filter(x => x !== name)[1]] || this.state[fieldsToCheck.filter(x => x !== name)[2]] || this.state[fieldsToCheck.filter(x => x !== name)[3]]) {
                return true;
            }
        }
        else
            return false;
    }

    setHasOpenWorkFlow(option: string) {
        let openWorkflowsearch: boolean = null;

        if (option.length > 0)
            switch (option) {
                case DONOTCARE_ABOUTWORKFLOWS:
                    openWorkflowsearch = null;
                    break;
                case HASOPEN_WORKFLOWS:
                    openWorkflowsearch = true;
                    break;
                case WITHOUTOPEN_WORKFLOWS:
                    openWorkflowsearch = false;
                    break;
            }

        this.setState({ hasOpenWorkflow: openWorkflowsearch });
    }

    matchingWorkTitle = ''
    setMatchingWorkTitle = (title: string) => {
        this.matchingWorkTitle = title;
    }

    showSearchModal = () => {
        const { showModal, usageType } = this.props;
        showModal(USAGESEARCH_VIEW, usageType === 'Usage To Work' ? SEARCH_VIEW_USAGES_USAGE_TO_WORK : SEARCH_VIEW_USAGES_USAGE_TO_PRODUCT, { setTitle: this.setMatchingWorkTitle }, true, "Search");
    };

    clearSearchField = (fieldName: string) => {
        this.matchingWorkTitle = '';
        this.changeData('', fieldName);
    };

    onSearchPools = (poolCodeToSearch: string) => {
        const { searchUsagePoolsByCodeDistributionPools, updatePoolCodeToSearch } = this.props;

        updatePoolCodeToSearch(poolCodeToSearch);

        if (poolCodeToSearch && poolCodeToSearch.length >= 5) {
            const searchQuery: IUsagePoolSearchQuery = {
                poolCode: poolCodeToSearch,
                cat1: EMPTY_STRING_VALUE,
                cat2: EMPTY_STRING_VALUE,
                allocationRule: EMPTY_STRING_VALUE,
                pagination: {
                    count: 150,
                    skip: 0,
                    orderBy: "poolCode",
                },
            };
            searchUsagePoolsByCodeDistributionPools(searchQuery);
        }
    }

    onSearchDistributions = (distributionCodeToSearch: string) => {
        const { searchDistributionByCode, updateDistributionCodeToSearch } = this.props;

        updateDistributionCodeToSearch(distributionCodeToSearch)

        if (distributionCodeToSearch && distributionCodeToSearch.length >= 5) {
            const searchQuery: IDistributionSearchQuery = {
                year: EMPTY_STRING_VALUE,
                distributionCode: distributionCodeToSearch,
                distributionType: EMPTY_STRING_VALUE,
                distributionStatus: EMPTY_STRING_VALUE,
                cat1: EMPTY_STRING_VALUE,
                cat2: EMPTY_STRING_VALUE,
                description: EMPTY_STRING_VALUE,
                pagination: {
                    count: 150,
                    skip: 0,
                    orderBy: "distributionCode",
                },
                isBackendSearch: true,
            };

            searchDistributionByCode(searchQuery);
        }
    }

    renderSearchFields = (typeOfSearch?: string) => {
        const { searchViewData, lookupValues, distributions, pools, sourceMinors, sourceMajors, usageType, lookupSources, distributionTypes, usageGroup, expanderFieldsData, customer, usagePoolsSearchResults, usageDistributionsSearchResults
        } = this.props;

        const { type } = this.state;

        let filteredDistributions: { code: string; description: string; }[] = distributions;

        if (distributionTypes) {
            const codeCalc = distributionTypes.filter(x => x.description === type)[0]?.codeCalculationRule;
            if (codeCalc) {
                const prefix = JSON.parse(codeCalc).Prefix;
                filteredDistributions = distributions.filter(j => ((j.code.match(/[A-Z\s]+/)).join("") == prefix[0]));
            }
            else {
                filteredDistributions = distributions;
            }
        }

        let componentInstance = this.props.componentInstance;
        let componentInstanceForFields;

        if (componentInstance === SEARCH_VIEW_USAGES) {
            componentInstanceForFields = SEARCH_VIEW_USAGES + usageType.replace(/\s+/g, '');
        }
        else if (componentInstance === USAGE_MATCHING_WORKS_SECTION_KEY) {
            componentInstanceForFields = USAGE_MATCHING_WORKS_SECTION_KEY;
        }
        else if (componentInstance === USAGE_MATCHING_PRODUCTS_SECTION_KEY) {
            componentInstanceForFields = USAGE_MATCHING_PRODUCTS_SECTION_KEY;
        }

        if (searchViewData && searchViewData.fields) {
            // filter searhcFields based on component instance
            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField) =>
                    searchField.componentInstance === componentInstanceForFields
            );

            if (typeOfSearch === ADVANCED) {
                filteredSearchFields = filteredSearchFields.filter(x => !x.hidden && x.section === 'advanced');
            }
            else {
                filteredSearchFields = filteredSearchFields.filter(x => !x.hidden && x.section !== 'advanced');
            }


            if (usageGroup && usageGroup.usageType === USAGETOPRODUCT_NO_SPACES && usageGroup.productType) {
                filteredSearchFields = filteredSearchFields.filter(x => x.name !== 'productType')
            }

            var filteredSearchFieldsMapper = (searchField: IRepertoireField, index: number): ReactElement => {
                let reactElement: ReactElement = <></>;

                //condition to check if field is matching work/product textbox or not
                let condition = componentInstance === SEARCH_VIEW_USAGES && searchField.name === 'matchingWork' || searchField.name === 'matchingProduct';
                let InlineActionButtonWrapper: IInlineActionButtonWrapper = condition ? {
                    wrapperClassName: "matchingButtonWrapper",
                    buttonAction: (() => {
                        this.clearSearchField(searchField.name)
                        this.setState({ matchID: null });
                    }),
                    buttonText: "Clear",
                    buttonClassName: "button"
                } : undefined;

                if (searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal' || searchField.fieldType === 'readonly')
                    reactElement =
                        <div key={index}>
                            <SizedTextDataInput
                                fieldType={searchField.fieldType}
                                useEnterSubmit={true}
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={this.disabledSearchFields(searchField.name) ? this.changeDataDisabled : this.changeData}
                                handleKeyDown={this.keyDownSubmit}
                                value={condition ? this.matchingWorkTitle : this.state[searchField.name]}
                                readOnly={this.disabledSearchFields(searchField.name)}
                                isHidden={searchField.hidden}
                                InlineActionButtonWrapper={InlineActionButtonWrapper}
                            />
                            <If condition={condition}>
                                <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE} style={{ marginTop: 35 }}>
                                    <ShowMore
                                        options={[
                                            {
                                                text: expanderFieldsData ? expanderFieldsData.find(e => searchField.name === 'matchingWork' ? e.name === "selectWork" : e.name === 'selectProduct').data : '',
                                                onClick: () => { this.showSearchModal(); },
                                                icon: "assets/external.svg"
                                            }
                                        ]}
                                    />
                                </div>
                            </If>
                        </div>
                else if (searchField.name === SOURCEMAJOR_SEARCH_USAGES_STATE_KEY)
                    reactElement =
                        <SizedDropdownTreeDataInput
                            key={index}
                            label={searchField.data}
                            fieldName={searchField.name}
                            changeData={this.changeData}
                            value={this.state[searchField.name]}
                            options={lookupSources ? lookupSources : undefined}
                            allowNull={true}
                            isHidden={searchField.hidden}
                            componentInstance={componentInstance}
                        />
                else if (searchField.fieldType === 'boolean' && searchField.name === SEARCH_VIEW_HAS_OPEN_WORKFLOW)
                    reactElement =
                        <div key={index}>
                            <SizedRadiobuttonDataInput
                                selectedValue={this.state[searchField.name] === null ?
                                    DONOTCARE_ABOUTWORKFLOWS :
                                    this.state[searchField.name] === true ? HASOPEN_WORKFLOWS : WITHOUTOPEN_WORKFLOWS}
                                onSelectionChange={this.setHasOpenWorkFlow.bind(this)}
                                radioName="hasOpenWorkFlow"
                                title={searchField.data}
                                options={[
                                    { desc: ComponentsHelper.getFieldValueByName("doNotCareAboutWorkflowsDesc", searchViewData.fields), code: DONOTCARE_ABOUTWORKFLOWS },
                                    { desc: ComponentsHelper.getFieldValueByName("hasOpenWorkflowsDesc", searchViewData.fields), code: HASOPEN_WORKFLOWS },
                                    { desc: ComponentsHelper.getFieldValueByName("withoutOpenWorkflowsDesc", searchViewData.fields), code: WITHOUTOPEN_WORKFLOWS }
                                ]}
                            />
                        </div>
                else {
                    let options: { code: string, description: string }[] = [];
                    if (componentInstance === SEARCH_VIEW_USAGES && searchField.name === DISTRIBUTION_SEARCH_USAGES_STATE_KEY)
                        options = filteredDistributions.map((x) => ({ code: x.code, description: x.description }));
                    else if (componentInstance === SEARCH_VIEW_USAGES && searchField.name === POOL_SEARCH_USAGES_STATE_KEY) {
                        if (usagePoolsSearchResults) {
                            options = usagePoolsSearchResults.map((x) => ({ code: x.poolCode, description: x.description ? x.description : x.poolCode }));
                        }
                        else if (pools)
                            options = pools.map((x) => ({ code: x.code, description: x.description ? x.description : x.code }));
                    }
                    else if (componentInstance === SEARCH_VIEW_USAGES && searchField.name === PAYMENTRUN_DISTRIBUTION_CODE_STATE_KEY) {
                        if (usageDistributionsSearchResults) {
                        options = usageDistributionsSearchResults.map((x) => ({
                            code: x.distributionCode,
                            description: x.description ? x.description : x.distributionCode
                        }));
                    }
                        else if (distributions)
                            distributions.map((x) => ({ code: x.code, description: x.description ? x.description : x.code }));
                    }
                    else if (componentInstance === SEARCH_VIEW_USAGES && searchField.name === SOURCEMAJOR_SEARCH_USAGES_STATE_KEY)
                        options = sourceMajors;
                    else if (componentInstance === SEARCH_VIEW_USAGES && searchField.name === SOURCEMINOR_SEARCH_USAGES_STATE_KEY)
                        options = sourceMinors;
                    else if (componentInstance === SEARCH_VIEW_USAGES && searchField.name === USAGETYPE_SEARCH_USAGES_STATE_KEY) {
                        if (distributionTypes) options = distributionTypes.map((x): any => Object.assign(x, { code: x.description, description: x.description }));
                    }
                    else if (componentInstance === SEARCH_VIEW_USAGES && searchField.name === USAGE_GROUP_MATCH_STATUS_STATE_KEY) {
                        options = getLookupValues(mapUsageLookupKey(searchField.name, ''), lookupValues).map((x): any => Object.assign(x, { code: x.description, description: x.description, extraFields: { DisabledInDropdown: false } }));
                    }
                    else if (lookupValues && getLookupValues(mapUsageLookupKey(searchField.name, ''), lookupValues)) {
                        options = getLookupValues(mapUsageLookupKey(searchField.name, ''), lookupValues);
                    }
                    else
                        options = undefined;

                    reactElement =
                        <div key={index}>
                            <InputSizedDropdown
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={this.changeData}
                                value={this.state[searchField.name]}
                                options={options}
                                allowNull={true}
                                isHidden={searchField.hidden}
                                componentInstance={componentInstance}
                                backendSearch={customer === 'CEDRO' ? true : false}
                                onBackendSearch={searchField.name === POOL_SEARCH_USAGES_STATE_KEY ? this.onSearchPools.bind(this) : this.onSearchDistributions.bind(this)}
                            />
                        </div>
                }

                return reactElement;
            }

            return filteredSearchFields.map(filteredSearchFieldsMapper);
        }
    };

    onClickSearch = (continuationToken?: string) => {
        const {
            type,
            distribution,
            pool,
            fileName,
            title,
            composer,
            sourceMajor,
            rightShareOwner,
            allocationStatus,
            includeFeesInError,
            licenseesWorkNumber,
            matchStatus,
            setPageOne,
            number,
            writers,
            societyAccountNumber,
            workBatchID,
            dataSource,
            artist,
            hasOpenWorkflow,
            productBatchID,
            contributor,
            productType,
            matchID,
            titleContains,
            distributionsFilter,
            sourcesFilter,
            approvalStatus,
            weight,
            estimatedValue,
            estimatedFromValue,
            estimatedToValue,
            performanceNumber
        } = this.state;
        const {
            componentInstance,
            searchUsages,
            resetPagination,
            usageType,
            lookupSources,
            searchMatchWorks,
            searchMatchProducts,
            usageGroup,
        } = this.props;

        if (!continuationToken)
            resetPagination(componentInstance);
        this.setState({ setPageOne: setPageOne + 1, isLoading: true })
        switch (componentInstance) {
            case SEARCH_VIEW_USAGES: {

                let sourceMaj = '';
                let sourceMin = '';
                if (sourceMajor) {
                    const splitString = lookupSources.find(x => x.id === sourceMajor).hierarchy.split("/").reverse();
                    if (splitString.length > 2) {
                        sourceMin = splitString[0];
                        sourceMaj = splitString[1];
                    } else {
                        sourceMaj = splitString[0];
                    }
                }

                let searchQuery: IUsagesSearchQuery = {
                    usageType,
                    type,
                    distribution,
                    pool,
                    fileName,
                    title,
                    composer,
                    artist,
                    sourceMajor: sourceMaj,
                    sourceMinor: sourceMin,
                    rightShareOwner,
                    allocationStatus,
                    includeFeesInError,
                    totalWeight: weight,
                    licenseesWorkNumber,
                    matchStatus,
                    hasOpenWorkflow,
                    matchID,
                    titleContains,
                    distributionsFilter,
                    sourcesFilter,
                    approvalStatus,
                    estimatedValue,
                    estimatedFromValue,
                    estimatedToValue,
                    productType,
                    performanceNumber
                };
                searchUsages(searchQuery, true, usageType, lookupSources, continuationToken || null);
                break;
            }
            case SEARCH_VIEW_USAGES_USAGE_TO_WORK:
            case USAGE_MATCHING_WORKS_SECTION_KEY: {
                let searchQuery: IMatchWorksSearchQuery = {
                    workIDs: [],
                    title,
                    number,
                    artist,
                    writers,
                    societyAccountNumber,
                    workBatchID,
                    dataSource,
                    hasOpenWorkflow
                };
                searchMatchWorks(searchQuery, false);
                break;
            }
            case SEARCH_VIEW_USAGES_USAGE_TO_PRODUCT:
            case USAGE_MATCHING_PRODUCTS_SECTION_KEY: {
                let searchQuery: IMatchProductsSearchQuery = {
                    title,
                    number,
                    artist,
                    productBatchID,
                    contributor,
                    dataSource,
                    productIDs: [],
                    productType: usageGroup && usageGroup.usageType === USAGETOPRODUCT_NO_SPACES && usageGroup.productType ? usageGroup.productType : productType
                };
                searchMatchProducts(searchQuery, false);
                break;
            }
            default: {
                return;
            }
        }
    };

    renderSearchResults() {
        const {
            searchResultsTableData,
            componentInstance,
            usageGroupsSearchResult,
            matchWorkSearchResult,
            matchProductsSearchResult,
            modalProps,
            searchSuccessful,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            updatePagination,
            getUsageDetails,
            getDistributionDetails,
            getUsagePool,
            expandMatchWorkResult,
            expandedMatchWorkResults,
            usageExpandAll,
            usageExpandAllResults,
            addMatchworkToUsage,
            hideModal,
            usageType,
            onClickNumber,
            addMatchProductToUsage,
            onlySearchResults,
            lookupSources,
            searchUsages,
            sortSearchResults,
            distributions,
            pools,
            continuationToken,
            showFieldsModal,
            updateUsageGroupMatches,
            saveUsageGroup,
            saveResultData,
            addWorkOrProductToUsageSearchCriteria,
            isHundredResultsPerPage,
            setIsHundredResultsPerPage,
            matchingSources,
            getWorkDetails,
            adjustmentDetails,
            adjustment,
            workAdjustmentIndex
        } = this.props;

        const { setPageOne, titleContains, distributionsFilter, isLoading } = this.state;
        if (isLoading) {
            return (<div className="loaderWrapper">
                <div className="spinner-dualball">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>)
        }
        else if (usageGroupsSearchResult || matchWorkSearchResult || matchProductsSearchResult) {
            if (onlySearchResults && usageGroupsSearchResult === undefined) {
                const searchQuery: IUsagesSearchQuery = {
                    usageType: USAGETOWORK,
                    type: "",
                    distribution: "",
                    pool: "",
                    fileName: "",
                    title: "",
                    composer: "",
                    artist: "",
                    sourceMajor: "",
                    sourceMinor: "",
                    rightShareOwner: "",
                    allocationStatus: "",
                    includeFeesInError: "",
                    totalWeight: "",
                    licenseesWorkNumber: "",
                    matchStatus: "",
                    hasOpenWorkflow: null,
                    matchID: modalProps,
                    estimatedValue: 0,
                    estimatedFromValue: "",
                    estimatedToValue: "",
                    productType: "",
                    performanceNumber: ""
                };

                searchUsages(searchQuery, true, USAGETOWORK, lookupSources);
            }

            if (searchSuccessful && onlySearchResults && (!!usageGroupsSearchResult && usageGroupsSearchResult.length <= 0)) {
                return (
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                            No usages found.
                        </div>
                    </div>
                )
            }

            if (searchSuccessful && !onlySearchResults &&
                ((!!usageGroupsSearchResult && usageGroupsSearchResult.length <= 0)
                    || (!!matchWorkSearchResult && matchWorkSearchResult.length <= 0)
                    || (!!matchProductsSearchResult && matchProductsSearchResult.length <= 0))) {
                return (
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                            No results found.
                        </div>
                    </div>
                )
            }
            else if (componentInstance === SEARCH_VIEW_USAGES &&
                usageGroupsSearchResult && usageGroupsSearchResult.length >= 1) {
                return (<div className="row">
                    <div className="col-md-12 searchResultsDiv">
                        <ResultsTable
                            key={setPageOne}
                            searchResultsTableData={searchResultsTableData}
                            tableContents={usageGroupsSearchResult}
                            componentInstance={componentInstance}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            updatePagination={updatePagination}
                            repertoireSection={SEARCH_VIEW_USAGES}
                            getUsageDetails={getUsageDetails}
                            getDistributionDetails={getDistributionDetails}
                            matchingSources={matchingSources}
                            getUsagePool={getUsagePool}
                            usageType={usageType}
                            onClickNumber={onClickNumber}
                            sortSearchResults={sortSearchResults}
                            distributions={distributions}
                            pools={pools}
                            lookupSources={lookupSources}
                            changeData={this.changeData}
                            titleContains={titleContains}
                            distributionsFilter={distributionsFilter}
                            search={this.onClickSearch}
                            continuationToken={continuationToken}
                            showFieldsModal={showFieldsModal}
                            updateUsageGroupMatches={updateUsageGroupMatches}
                            saveUsageGroup={saveUsageGroup}
                            saveResultData={saveResultData}
                            isHundredResultsPerPage={isHundredResultsPerPage}
                            setIsHundredResultsPerPage={setIsHundredResultsPerPage}
                        />
                    </div>
                </div>)
            }
            else if (componentInstance === USAGE_MATCHING_WORKS_SECTION_KEY &&
                matchWorkSearchResult && matchWorkSearchResult.length >= 1) {
                return (<div className="row">
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={matchWorkSearchResult}
                        componentInstance={USAGE_MATCHING_WORKS_SECTION_KEY}
                        sourceItem={modalProps}
                        hideModal={undefined}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_USAGES}
                        expandedResults={expandedMatchWorkResults}
                        expandAll={usageExpandAll}
                        expandResult={expandMatchWorkResult}
                        expandAllResults={usageExpandAllResults}
                        addMatchworkToUsage={addMatchworkToUsage}
                        sortSearchResults={sortSearchResults}
                        getWorkDetails={getWorkDetails}
                        updateAdjustmentDetail={this.props.updateAdjustmentDetail}
                        adjustmentDetails={adjustmentDetails}
                        adjustment={adjustment}
                        workAdjustmentIndex={workAdjustmentIndex}
                    />
                </div>)
            }
            else if (componentInstance === USAGE_MATCHING_PRODUCTS_SECTION_KEY &&
                matchProductsSearchResult && matchProductsSearchResult.length >= 1) {
                return (<div className="row">
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={matchProductsSearchResult}
                        componentInstance={USAGE_MATCHING_PRODUCTS_SECTION_KEY}
                        sourceItem={modalProps}
                        hideModal={hideModal}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_USAGES}
                        expandedResults={expandedMatchWorkResults}
                        expandAll={usageExpandAll}
                        expandResult={expandMatchWorkResult}
                        expandAllResults={usageExpandAllResults}
                        addMatchworkToUsage={undefined}
                        addMatchProductToUsage={addMatchProductToUsage}
                        sortSearchResults={sortSearchResults}
                    />
                </div>)
            }
            else if (searchSuccessful == false) {
                return (
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                            Error while searching, please contact customer support if issue persists.
                        </div>
                    </div>
                )
            }
        }

    }
    resetToInitialState = () => {
        this.setState(initialState())
    }

    renderExtraFields = () => {
        const { isDistributed, dateOfRegistration, tabs, activeTab } = this.props;

        if (!tabs[activeTab].matchingWork) {
            return <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                    Loading
                </div>
            </div>;
        } else {
            return (
                <div className="searchFieldsGridUsageModal">
                    <div className="row">
                        <SizedCheckboxDataInput
                            label={"Is Distributed"}
                            fieldName={WORK_IS_DISTRIBUTED_STATE_KEY}
                            value={isDistributed}
                            changeData={null}
                            readonly={true}
                            isHidden={false} />
                    </div>
                    <div className="row">
                        <SizedDateDataInput
                            label={"Registration Date"}
                            fieldName={REG_DATE_STATE_KEY}
                            value={dateOfRegistration ? dateOfRegistration : ""}
                            changeData={null}
                            readOnly={true}
                            isHidden={false} />
                    </div>
                </div>
            )
        }

    }

    render() {
        const { onlySearchResults, searchDisabled, searchType } = this.props;

        if (onlySearchResults) {
            return (
                <div className="searchView">
                    <div className="row">
                        {this.renderExtraFields()}
                    </div>
                    <div>
                        {this.renderSearchResults()}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="searchView">
                    <div className="row">
                        <SearchViewKeyEventListener setStateInitial={this.resetToInitialState.bind(this)} />
                        {this.renderSearchFields()}
                    </div>
                    {searchType === ADVANCED &&
                        <>
                            <div className="headerSection">Advanced</div>
                            <div className="row">
                                {this.renderSearchFields(ADVANCED)}
                            </div>
                        </>
                    }
                    <div className="row">
                        <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                            <CustomButton buttonText="Search" buttonAction={() => this.onClickSearch()} disabled={searchDisabled} />
                        </div>
                    </div>
                    <div>
                        {this.renderSearchResults()}
                    </div>
                </div>
            )
        }
    }
}
