import * as React from "react";
import {
    removeTab,
    openTab,
    addArtistToWork,
    setDataSource,
    addWorkToProduct,
    addArtistToProduct,
    updateRelatedWork,
    updateWorkSourceIP,
    updateEditableFields,
    addMatchWorkToUsage,
    addMatchProductToUsage, searchDataChanged,
    updateSelectionCriteria,
    updateForeignSocietyInfo,
    displayRepresentationFlag,
    addIPToAdjustmentDetails,
    addWorkToLicenseRequest,
    addIPToUsageDetails,
    updateProductSourceIP,
    addWorkToClaimRequest,
    updateScheduledJobField,
    updateRepresentationSelector,
    addWorkOrProductToUsageSearchCriteria
} from "../redux/reducers/RepertoireReducer";
import { showModal, hideModal, showYesNoPrompt, updateComment } from "../redux/reducers/ModalReducer";
import TabsView from "./components/tabsView/TabsView";
import IRepertoireComponentDataItem from "../redux/types/IRepertoireComponentDataItem";
import { IContributorSearchQuery } from "./types/IContributorSearchQuery";
import { ILookupDictionary } from "../lookup/types/ILookupDictionary";
import { IArtist } from "./types/IArtist";
import { IContributorSearchResult } from "./types/IContributorSearchResult";
import { REPERTOIRE_PAGE_JOBS } from "./Consts";
import { IArtistSearchQuery } from "./types/IArtistSearchQuery";
import { ITabReduxItem } from "../redux/types/ITabReduxItem";
import { JobMonitoringService } from "../job/services/JobMonitoringService";
import { renderJobProgress } from "../redux/reducers/JobReducer";
import { IContributorItem } from "./types/IContributorItem";
import { IP } from "./types/IP";
import { DataSource } from "./types/DataSource";
import { IWorksSearchQuery } from "./types/IWorksSearchQuery";
import { IWorksSearchResult } from "./types/IWorksSearchResult";
import { IRepertoirePathInfo } from "./types/IRepertoirePathInfo";
import { setCurrentPage } from "../redux/reducers/HomeReducer";
import { IEnableModulesConfigurationParametersItem } from "../redux/types/IEnableModulesConfigurationParameterItem";
import { IActiveAccordion } from "../redux/types/IActiveAccordion";
import { IUsagesSearchQuery } from "./types/usageTypes/IUsagesSearchQuery";
import { IUsageGroupResult } from "./types/usageTypes/IUsageGroupsSearchResult";
import { IMatchWorksSearchQuery } from "./types/usageTypes/IMatchWorksSearchQuery";
import { IMatchWorksSearchResult } from "./types/usageTypes/IMatchWorksSearchResult";
import { IMatchProductsSearchQuery } from "./types/usageTypes/IMatchProductsSearchQuery";
import { IProductSearchResult } from "./types/IProductSearchResult";
import { ITreeData } from "./types/ITreeData";
import { addFileToUpload, CleanUploadFilesState, setInDropZone, setProgressUploadFileProccess, sortColumn } from "../redux/reducers/DataIngestionReducer";
import { IDistribution } from "./types/usageTypes/IDistribution";
import { IProductCore } from "./types/IProductCore";
import IDroppedFiles from "../redux/types/IDroppedFiles";
import { IComponentDataItem } from "../core/types/IComponentDataItem";
import { IDistributionState } from "../redux/types/IDistributionState";
import { getMatchingWorkInfo } from "../redux/thunks/RepertoireThunks";
import { IDistributionType } from "./types/usageTypes/IDistibutionType";
import { IIPsSearchQuery } from "./types/IIPsSearchQuery";
import { IipTransfer } from "./types/usageTypes/IipTransfer";
import { ILicenseUserSearchQuery } from "./types/ILicenseUserSearchQuery";
import { ILicenseUserSearchResult } from "./types/ILicenseUserSearchResult"
import { IProductSearchQuery } from "./types/IProductSearchQuery";
import { IDataActionToolbar } from "./types/IDataActionToolbar";
import { FormatFields } from "../redux/types/FormatFields";
import { IDistributionSubType } from "./types/usageTypes/IDistributionSubType";
import { IWorkSubjectSearchResult } from "./types/IWorkSubjectSearchResult";
import { ISubjectsSearchQuery } from "./types/ISubjectsSearchQuery";
import { ISubject } from "./types/ISubject";
import IShortCutKeys from "../redux/types/IShortCutKeys";
import CleanUpJobMonitoringService from "../membersportal/CleanUpJobMonitoringService";
import { RepertoireModal } from "./components/repertoireModal/RepertoireModal";
import { IMyPackagesDetails } from "./types/IMyPackagesDetails";
import { IUsageGroup } from "./types/usageTypes/IUsageGroup";


export interface IRepertoirePageProps {
    pathInfo: IRepertoirePathInfo;
    setDataSource: typeof setDataSource;
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    updateModalComment: typeof updateComment;
    displayModal: boolean;
    addArtistToWork: typeof addArtistToWork;
    addArtistToProduct: typeof addArtistToProduct;
    addWorkToProduct: typeof addWorkToProduct;
    updateWorkContributorIP: (dataSource: DataSource, workContributor: IContributorItem, ip: IP) => void;
    updateProductContributorIP: (productContributorID: number, ip: IP) => void;
    updateAgreementIP: (agreementAdminIPID: number, ip: IP) => void;
    updateAgreementWork: ( work: IWorksSearchResult) => void;
    updateAgreementCarveOutProduct: (agreementProductWorkID: number, product: IProductSearchResult) => void;
    updateMatchingWork: (workID: number, work: IWorksSearchResult) => void;
    updateMatchingProduct?: (productID: number, product: IProductSearchResult) => void;
    updateRelatedWork: typeof updateRelatedWork;
    updateWorkSourceIP: typeof updateWorkSourceIP;
    updateProductSourceIP: typeof updateProductSourceIP;
    renderJobProgress: typeof renderJobProgress;
    loadRepertoireFromPath: (pathInfo: IRepertoirePathInfo, location: Location) => void;
    modalContent: string;
    modalComponentInstance: string;
    modalProps: any;
    modalIndex: number;
    modalKey:string;
    displayModalCloseButton: boolean;
    modalTitle: string;
    getRepertoireFields: () => void;
    tabs: ITabReduxItem[];
    tabsPaneTwo: ITabReduxItem[];
    removeTab: typeof removeTab;
    openTab: typeof openTab;
    setActivePane: (paneIndex: number) => void;
    setCurrentPage: typeof setCurrentPage;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    workNotFoundViewData: IRepertoireComponentDataItem;
    loadingViewData: IRepertoireComponentDataItem;
    yesNoPromptViewData: IRepertoireComponentDataItem;
    repertoirePageDataView: IRepertoireComponentDataItem;
    tabsViewData: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    containerDetailsWindowComponentData: IComponentDataItem;
    dataSource: string;
    agreementsDataSource: string;
    productDataSource: string;
    lookupEntities: ILookupDictionary;
    reduxStorageLoaded: boolean;
    searchArtists: (query: IArtistSearchQuery, dataSource: string) => void;
    searchContributors: (
        query: IContributorSearchQuery
    ) => void;
    searchSubjects: (query: ISubjectsSearchQuery, modalOpen: boolean) => void;
    updateSubjectCode: (subject: ISubject) => void;
    searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    searchProducts: (query: IProductSearchQuery, modalOpen: boolean) => void;
    activeTab: number;
    activeTabPaneTwo: number;
    closeTabDisabled: boolean;
    activePane: number;

    workSubjectSearchResults: IWorkSubjectSearchResult[];

    worksSearchResults?: IWorksSearchResult[];
    artistsSearchResults?: IArtist[];
    contributorsSearchResults?: IContributorSearchResult[];

    searchSuccessful: boolean;
    showYesNoPrompt?: typeof showYesNoPrompt;
    currentUser?: string;
    updateEditableFields: typeof updateEditableFields;
    expandWorkResult?: (workResult: number) => void;
    expandProductResult?: (productResult: number) => void;
    modalMessage: string;
    updateWorkflowViewData: IRepertoireComponentDataItem;
    enabledModules: IEnableModulesConfigurationParametersItem[];
    getAgreementShareDisplayOptions: () => void;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    getShareDecimalsToDisplay: () => void;
    getUsageGridSearchResultsDisplayOptions: () => void;
    getUsageRegroupAndDeleteDisplayOptions:() =>void;
    getIPGridSearchResultsDisplayOptions: () => void;
    getProductMaintenanceEnableCuesheets:()=>void;
    getProductMaintenanceCuesheetsDataSource:()=>void;
    getPDArrangementMusicLyricOptions: () => void;
    getWorkMaintenanceGeneralViewDisplayOptions: () => void;
    getProductMaintenanceGeneralViewDisplayOptions: () => void;
    getPoolMaintenanceGeneralViewDisplayOptions: () => void;
    getShareToleranceSettings: () => void;
    getDistributionMaintenanceSettingsDisplayOptions:()=>void;
    getUsageMatchingDefaultsProducts: () => void;
    getUsageMatchingDefaultsWorks: () => void;
    getShortCutKeys: () => void;
    shortCutKeys: IShortCutKeys[];
    usageSearchViewData: IRepertoireComponentDataItem;
    usageSearchResultsTableData: IRepertoireComponentDataItem;
    searchUsages: (query: IUsagesSearchQuery, modalOpen: boolean, usageType: string, lookupSources: ITreeData[]) => void;
    usageGroupsSearchResult: IUsageGroupResult[];
    searchMatchWorks: (query: IMatchWorksSearchQuery, modalOpen: boolean) => void;
    searchMatchProducts: (query: IMatchProductsSearchQuery, modalOpen: boolean) => void;
    matchWorkSearchResult: IMatchWorksSearchResult[];
    expandMatchWorkResult: (matchWorkResults: number) => void;
    addMatchworkToUsage?: typeof addMatchWorkToUsage;
    expandedMatchWorkResults?: number[];
    getUsageDetails: (usageID: string) => void;
    getDistributions: () => void;
    matchProductsSearchResult: IProductSearchResult[];
    addMatchProductToUsage?: typeof addMatchProductToUsage;
    lookupValues: ILookupDictionary;
    searchDataChanged: typeof searchDataChanged;
    ipMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    myPackagesGeneralDataViewData?: IRepertoireComponentDataItem;
    addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    getSources: () => void;
    lookupSources: ITreeData[];
    updateSelectionCriteria: typeof updateSelectionCriteria;
    distributions?: IDistribution[];
    products?: IProductCore[];
    displayRepresentation: typeof displayRepresentationFlag;
    isReadOnlyModal?: boolean;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    isRepertoireModule?: boolean;
    importDistributionPools: (distribution: IDistributionState, file: string, lookupValues: ILookupDictionary) => void;
    updateForeignSocietyInfo: typeof updateForeignSocietyInfo;
    getMatchingWorkInfo: typeof getMatchingWorkInfo;
    distributionTypes: IDistributionType[];
    getDistributionTypes: () => void;
    distributionSubTypes: IDistributionSubType[];
    getDistributionSubTypes: () => void;
    getLookupEntities?: () => void;
    ipTransferViewData: IRepertoireComponentDataItem;
    searchIPs?: (query: IIPsSearchQuery) => void;
    ipTransfer: (ipTransferObject: IipTransfer) => void;
    addIpToAdjustmentDetail?: typeof addIPToAdjustmentDetails;
    addIPToUsageDetails?: typeof addIPToUsageDetails;
    usageMatchingDefaultsWorks?: string[];
    usageMatchingDefaultsProducts?: string[];
    searchLicenseUser: (query: ILicenseUserSearchQuery) => void;
    licenseUserSearchResults: ILicenseUserSearchResult[];
    updateLicenceUserValues?: (params: any) => void;
    addWorkToLicenseRequest?: typeof addWorkToLicenseRequest;
    addWorkToClaimRequest?: typeof addWorkToClaimRequest;
    getCommentCategoryFlag: () => void;
    getAgreementAttachmentTypeRequired: () => void;
    getExcludeSourceTypePickShowFields: () => void;
    updateScheduledJobField?: typeof updateScheduledJobField;
    getCustomerDateConfiguration: () => void;
    getCustomerTimeZoneConfiguration: () => void;
    closeAllTabsInSecondPane: () => void;
    customerDateConfiguration?: string;
    updateRepresentationSelector?: typeof updateRepresentationSelector;
    customerTimeZoneConfiguration?: string;
    getRecalculateDomesticorForeignThunk?:()=>void;
    customer?: string;
    getDownloadFileFormat?: () => void;
    myPackagesResult?:IMyPackagesDetails[];
    productSearchResults?: IProductSearchResult[];
    getProductDetails?: (dataSource: string, productCoreID: number, isForWorkflowSession?: boolean, openNewTab?: boolean) => void;
    editableFields?: any;
    expandedWorkResults?: number[];
    expandedProductResults?: number[];
    usageExpandAll?: boolean,
    expandAll?: boolean;
    expandAllResults?: () => void;
    usageExpandAllResults?: () => void;
    addNewWork?: (lookups: ILookupDictionary, actionList: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[], workGroupId?: string, workGroup?: string) => void;
    addWorkOrProductToUsageSearchCriteria?: typeof addWorkOrProductToUsageSearchCriteria;
    isRepertoireSearch?: boolean;
    commentCategoryFlag?: boolean;
    addNewProduct?: (lookups: ILookupDictionary, isNew?: boolean, productMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[]) => void;
    incrementTempID?: () => void;
    updateUsageField?: (fieldName: string, value: any) => void;
}

const RepertoirePage: React.FC<IRepertoirePageProps> = ({
    getRepertoireFields,
    shortCutKeys,
    getShortCutKeys,
    renderJobProgress,
    searchViewData,
    pathInfo,
    loadRepertoireFromPath,
    getAgreementShareDisplayOptions,
    getShareDecimalsToDisplay,
    getUsageGridSearchResultsDisplayOptions,
    getIPGridSearchResultsDisplayOptions,
    getProductMaintenanceEnableCuesheets,
    getProductMaintenanceCuesheetsDataSource,
    getPDArrangementMusicLyricOptions,
    getWorkMaintenanceGeneralViewDisplayOptions,
    getProductMaintenanceGeneralViewDisplayOptions,
    getPoolMaintenanceGeneralViewDisplayOptions,
    getShareToleranceSettings,
    getDistributionMaintenanceSettingsDisplayOptions,
    getUsageMatchingDefaultsProducts,
    getUsageMatchingDefaultsWorks,
    usageSearchViewData,
    getDistributions,
    lookupSources,
    getSources,
    distributionTypes,
    getDistributionTypes,
    distributionSubTypes,
    getDistributionSubTypes,
    lookupValues,
    getLookupEntities,
    getCommentCategoryFlag,
    getAgreementAttachmentTypeRequired,
    getExcludeSourceTypePickShowFields,
    getCustomerDateConfiguration,
    getCustomerTimeZoneConfiguration,
    customerDateConfiguration,
    customerTimeZoneConfiguration,
    getRecalculateDomesticorForeignThunk,
    getDownloadFileFormat,
    repertoirePageDataView,
    displayModal,
    tabs,
    tabsPaneTwo,
    tabsViewData,
    removeTab,
    openTab,
    setActivePane,
    activeTab,
    activeTabPaneTwo,
    closeTabDisabled,
    setCurrentPage,
    showYesNoPrompt,
    hideModal,
    displayRepresentation,
    activePane,
    updateModalComment,
    modalContent,
    modalComponentInstance,
    displayModalCloseButton,
    modalProps,
    modalIndex,
    modalKey,
    searchResultsTableData,
    workNotFoundViewData,
    loadingViewData,
    dataGridTableData,
    dataSource,
    setDataSource,
    agreementsDataSource,
    productDataSource,
    modalTitle,
    searchArtists,
    searchContributors,
    searchWorks,
    searchSubjects,
    searchProducts,
    artistsSearchResults,
    contributorsSearchResults,
    worksSearchResults,
    productSearchResults,
    workSubjectSearchResults,
    addArtistToWork,
    addArtistToProduct,
    addWorkToProduct,
    updateWorkContributorIP,
    updateProductContributorIP,
    updateAgreementIP,
    updateAgreementWork,
    updateAgreementCarveOutProduct,
    getProductDetails,
    updateMatchingWork,
    updateMatchingProduct,
    yesNoPromptViewData,
    searchSuccessful,
    updateRelatedWork,
    updateWorkSourceIP,
    updateProductSourceIP,
    editableFields,
    updateEditableFields,
    expandedWorkResults,
    expandedProductResults,
    expandAll,
    expandWorkResult,
    expandProductResult,
    expandAllResults,
    modalMessage,
    updateWorkflowViewData,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    currentPage,
    resetPagination,
    updatePagination,
    searchUsages,
    usageGroupsSearchResult,
    usageSearchResultsTableData,
    searchMatchWorks,
    searchMatchProducts,
    matchWorkSearchResult,
    addMatchworkToUsage,
    addMatchProductToUsage,
    expandMatchWorkResult,
    usageExpandAllResults,
    expandedMatchWorkResults,
    usageExpandAll,
    getUsageDetails,
    matchProductsSearchResult,
    searchDataChanged,
    ipMaintenanceGeneralDataViewData,
    addNewAccount,
    addNewWork,
    updateSelectionCriteria,
    updateRepresentationSelector,
    distributions,
    isReadOnlyModal,
    addFileToUpload,
    isDropZone,
    setProgressUploadFileProccess,
    containerDetailsWindowComponentData,
    droppedFiles,
    setInDropZone,
    importDistributionPools,
    updateForeignSocietyInfo,
    getMatchingWorkInfo,
    ipTransferViewData,
    showModal,
    searchIPs,
    ipTransfer,
    addIpToAdjustmentDetail,
    addWorkOrProductToUsageSearchCriteria,
    usageMatchingDefaultsWorks,
    usageMatchingDefaultsProducts,
    isRepertoireSearch,
    searchLicenseUser,
    licenseUserSearchResults,
    updateLicenceUserValues,
    addWorkToLicenseRequest,
    addIPToUsageDetails,
    addWorkToClaimRequest,
    commentCategoryFlag,
    addNewProduct,
    updateSubjectCode,
    incrementTempID,
    updateScheduledJobField,
    customer,
    sortColumn,
    closeAllTabsInSecondPane,
    updateUsageField,
    getUsageRegroupAndDeleteDisplayOptions,
}) => {

    const [loaded, setLoaded] = React.useState(false);
    const [viewData, setViewData] = React.useState([]);

    React.useEffect(() => {
        if (!searchViewData || searchViewData.fields.length === 0) {
            getRepertoireFields && getRepertoireFields();
        }
        if (!usageSearchViewData || usageSearchViewData.fields.length === 0) {
            getRepertoireFields && getRepertoireFields();
        }
        if (!(shortCutKeys && shortCutKeys.length > 0)) {
            getShortCutKeys && getShortCutKeys();
        }
        if (!customerDateConfiguration) {
            getCustomerDateConfiguration && getCustomerDateConfiguration();
        }
        if (!customerTimeZoneConfiguration) {
            getCustomerTimeZoneConfiguration && getCustomerTimeZoneConfiguration();
        }
        if (pathInfo && pathInfo.submodule) {
            loadRepertoireFromPath && loadRepertoireFromPath(pathInfo, window.location);
        }
        if (!lookupSources) {
            getSources && getSources();
        }

        if (!lookupValues || Object.keys(lookupValues).length == 0) {
            getLookupEntities && getLookupEntities();
        }
        if (!distributionTypes) {
            getDistributionTypes && getDistributionTypes();
        }
        if (!distributionSubTypes) {
            getDistributionSubTypes && getDistributionSubTypes();
        }


        renderJobProgress && JobMonitoringService.startMonitoringProgress(renderJobProgress);
        getAgreementShareDisplayOptions && getAgreementShareDisplayOptions();
        getShareDecimalsToDisplay && getShareDecimalsToDisplay();
        getUsageGridSearchResultsDisplayOptions && getUsageGridSearchResultsDisplayOptions();
        getRecalculateDomesticorForeignThunk && getRecalculateDomesticorForeignThunk();
        getIPGridSearchResultsDisplayOptions && getIPGridSearchResultsDisplayOptions();
        getProductMaintenanceEnableCuesheets && getProductMaintenanceEnableCuesheets();
        getUsageRegroupAndDeleteDisplayOptions && getUsageRegroupAndDeleteDisplayOptions();
        getProductMaintenanceCuesheetsDataSource && getProductMaintenanceCuesheetsDataSource();
        getPDArrangementMusicLyricOptions && getPDArrangementMusicLyricOptions(),
        getWorkMaintenanceGeneralViewDisplayOptions && getWorkMaintenanceGeneralViewDisplayOptions();
        getProductMaintenanceGeneralViewDisplayOptions && getProductMaintenanceGeneralViewDisplayOptions();
        getPoolMaintenanceGeneralViewDisplayOptions && getPoolMaintenanceGeneralViewDisplayOptions();
        getShareToleranceSettings && getShareToleranceSettings();
        getDistributionMaintenanceSettingsDisplayOptions && getDistributionMaintenanceSettingsDisplayOptions();
        getUsageMatchingDefaultsProducts && getUsageMatchingDefaultsProducts();
        getUsageMatchingDefaultsWorks && getUsageMatchingDefaultsWorks();
        getDistributions && getDistributions();
        getDownloadFileFormat && getDownloadFileFormat();
        getCommentCategoryFlag();
        getAgreementAttachmentTypeRequired();
        getExcludeSourceTypePickShowFields();

    }, []);

    React.useEffect(() => {
        if (!loaded) {
            if (!repertoirePageDataView) {
                return;
            }

            const { fields } = repertoirePageDataView;

            fields.forEach(item => {
                viewData[item.name] = item.data;

                if (Object.keys(viewData).length === fields.length) {
                    setLoaded(true);
                    setViewData(viewData);
                }
            });
        }
    }, [loaded, repertoirePageDataView]);

    React.useEffect(() => {
        const prevPath = pathInfo && pathInfo.submodule;
        const newPath = pathInfo && pathInfo.submodule;

        if (newPath && newPath !== prevPath) {
            loadRepertoireFromPath(pathInfo, window.location);
        }
    }, [pathInfo, loadRepertoireFromPath]);

        let contentClassName: string = "container-fluid pageContent";

        return (
            <div> 
                <CleanUpJobMonitoringService />
                <div className={contentClassName}>
                    {tabs && tabs.length > 0 ? (<div className="row">
                        <TabsView
                            tabs={tabs}
                            tabsPaneTwo={tabsPaneTwo}
                            tabsViewData={tabsViewData}
                            activePane={activePane}
                            removeTab={removeTab}
                            activeTab={activeTab}
                            activeTabPaneTwo={activeTabPaneTwo}
                            openTab={openTab}
                            setActivePane={setActivePane}
                            closeTabDisabled={closeTabDisabled}
                            hideModal={hideModal}
                            showYesNoPrompt={showYesNoPrompt}
                            displayRepresentation={displayRepresentation}
                            setCurrentPage={setCurrentPage}
                            portalType="MatchingEngine"
                            displayModal={displayModal}
                            shortCutKeys={shortCutKeys}
                            closeAllTabsInSecondPane={closeAllTabsInSecondPane}
                        />
                    </div>) : null}
                </div>
                <a target="_blank" id="downloadRepertoireAttachments"></a>
                {displayModal ? (
                <RepertoireModal
                updateUsageField={updateUsageField}
                searchViewData={searchViewData}
                usageSearchViewData={usageSearchViewData}
                lookupSources={lookupSources}
                distributionTypes={distributionTypes}
                getDistributionTypes={getDistributionTypes}
                lookupValues={lookupValues}
                tabs={activePane === 0 ? tabs : tabsPaneTwo}
                activeTab={activePane === 0 ? activeTab : activeTabPaneTwo}
                hideModal={hideModal}
                isDropZone={true} 
                updateModalComment={updateModalComment}
                modalContent={modalContent}
                modalComponentInstance={modalComponentInstance}
                displayModalCloseButton={displayModalCloseButton}
                modalProps={modalProps}
                modalIndex={modalIndex}
                modalKey={modalKey}
                searchResultsTableData={searchResultsTableData}
                workNotFoundViewData={workNotFoundViewData}
                loadingViewData={loadingViewData}
                dataGridTableData={dataGridTableData}
                dataSource={dataSource}
                setDataSource={setDataSource}
                agreementsDataSource={agreementsDataSource}
                productDataSource={productDataSource}
                modalTitle={modalTitle}
                searchArtists={searchArtists}
                searchContributors={searchContributors}
                searchWorks={searchWorks}
                searchSubjects={searchSubjects}
                searchProducts={searchProducts}
                artistsSearchResults={artistsSearchResults}
                contributorsSearchResults={contributorsSearchResults}
                worksSearchResults={worksSearchResults}
                productSearchResults={productSearchResults}
                workSubjectSearchResults={workSubjectSearchResults}
                addArtistToWork={addArtistToWork}
                addArtistToProduct={addArtistToProduct}
                addWorkToProduct={addWorkToProduct}
                updateWorkContributorIP={updateWorkContributorIP}
                updateProductContributorIP={updateProductContributorIP}
                updateAgreementIP={updateAgreementIP}
                updateAgreementWork={updateAgreementWork}
                updateAgreementCarveOutProduct={updateAgreementCarveOutProduct}
                getProductDetails={getProductDetails}
                updateMatchingWork={updateMatchingWork}
                updateMatchingProduct={updateMatchingProduct}
                yesNoPromptViewData={yesNoPromptViewData}
                searchSuccessful={searchSuccessful}
                updateRelatedWork={updateRelatedWork}
                updateWorkSourceIP={updateWorkSourceIP}
                updateProductSourceIP={updateProductSourceIP}
                editableFields={editableFields}
                updateEditableFields={updateEditableFields}
                expandedWorkResults={expandedWorkResults}
                expandedProductResults={expandedProductResults}
                expandAll={expandAll}
                expandWorkResult={expandWorkResult}
                expandProductResult={expandProductResult}
                expandAllResults={expandAllResults}
                modalMessage={modalMessage}
                updateWorkflowViewData={updateWorkflowViewData}
                indexOfFirstResult={indexOfFirstResult}
                indexOfLastResult={indexOfLastResult}
                resultsPerPage={resultsPerPage}
                currentPage={currentPage}
                resetPagination={resetPagination}
                updatePagination={updatePagination}
                searchUsages={searchUsages}
                usageGroupsSearchResult={usageGroupsSearchResult}
                usageSearchResultsTableData={usageSearchResultsTableData}
                searchMatchWorks={searchMatchWorks}
                searchMatchProducts={searchMatchProducts}
                matchWorkSearchResult={matchWorkSearchResult}
                addMatchworkToUsage={addMatchworkToUsage}
                addMatchProductToUsage={addMatchProductToUsage}
                expandMatchWorkResult={expandMatchWorkResult}
                usageExpandAllResults={usageExpandAllResults}
                expandedMatchWorkResults={expandedMatchWorkResults}
                usageExpandAll={usageExpandAll}
                getUsageDetails={getUsageDetails}
                matchProductsSearchResult={matchProductsSearchResult}
                searchDataChanged={searchDataChanged}
                ipMaintenanceGeneralDataViewData={ipMaintenanceGeneralDataViewData}
                addNewAccount={addNewAccount}
                addNewWork={addNewWork}
                updateSelectionCriteria={updateSelectionCriteria}
                updateRepresentationSelector={updateRepresentationSelector}
                distributions={distributions}
                isReadOnlyModal={isReadOnlyModal}
                addFileToUpload={addFileToUpload}
                setProgressUploadFileProccess={setProgressUploadFileProccess}
                containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                droppedFiles={droppedFiles}
                setInDropZone={setInDropZone}
                importDistributionPools={importDistributionPools}
                updateForeignSocietyInfo={updateForeignSocietyInfo}
                getMatchingWorkInfo={getMatchingWorkInfo}
                ipTransferViewData={ipTransferViewData}
                showModal={showModal}
                searchIPs={searchIPs}
                ipTransfer={ipTransfer}
                addIpToAdjustmentDetail={addIpToAdjustmentDetail}
                addWorkOrProductToUsageSearchCriteria={addWorkOrProductToUsageSearchCriteria}
                usageMatchingDefaultsWorks={usageMatchingDefaultsWorks}
                usageMatchingDefaultsProducts={usageMatchingDefaultsProducts}
                isRepertoireSearch={isRepertoireSearch}
                searchLicenseUser={searchLicenseUser}
                licenseUserSearchResults={licenseUserSearchResults}
                updateLicenceUserValues={updateLicenceUserValues}
                addWorkToLicenseRequest={addWorkToLicenseRequest}
                addIPToUsageDetails={addIPToUsageDetails}
                addWorkToClaimRequest={addWorkToClaimRequest}
                commentCategoryFlag={commentCategoryFlag}
                addNewProduct={addNewProduct}
                updateSubjectCode={updateSubjectCode}
                incrementTempID={incrementTempID}
                updateScheduledJobField={updateScheduledJobField}
                customer={customer}
                sortColumn={sortColumn}
                />) : null}
            </div>
        );
}

export default RepertoirePage;
