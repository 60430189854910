import * as React from "react";
import DataGridTable from "../../../../components/dataGridTable/DataGridTable";
import { ADD_NEW_ACTION, USAGE_MAINTENANCE_GRIDS_VIEW_COMMENTS_STATE_KEY } from "../../../../Consts";
import { clearModalSearchResults } from "../../../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../../../redux/types/ITabReduxItem";
import IRepertoireComponentDataItem from "../../../../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../../../../lookup/types/ILookupDictionary";
import { IUsageGroupState, IUsageGroupStateKeys } from "../../../../../redux/types/IUsageGroupState";

export interface IUsageMaintenanceGridCommentsDataViewProps {
    dataGridTableData?: IRepertoireComponentDataItem;
    usage: IUsageGroupState;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    updateUsageField: (value: any, fieldName: string, index?: number) => void;
    showModal: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    ) =>void;
    incrementTempID?: () => void;
    isMandatory?: boolean;
    clearModalSearchResults?: typeof clearModalSearchResults | (() => void);
    tabs?: ITabReduxItem[];
    activeTab?: number;
}

const UsageMaintenanceGridCommentsDataView:  React.FC<IUsageMaintenanceGridCommentsDataViewProps> = ({
    isReadonly,
    lookupValues,
    updateUsageField,
    incrementTempID,
    dataGridTableData,
    showModal,
    clearModalSearchResults,
    usage,
    tabs,
    activeTab
}) => {
const [viewData, setViewData] = React.useState([]);

React.useEffect(() => {
    if (usage.comments) {
        setViewData(usage.comments);
    }
}, [usage]);

const showCommentsModal = (     
    modalContent: string,
    modalComponentInstance: string,
    modalProps: any,
    displayModalCloseButton: boolean,
    title: string,
    modalMessage?: string,
    isReadOnly?: boolean,
    key?: string,
    index?: number) => {

        console.log("showCommentsModal", modalContent, modalComponentInstance, {comments: viewData, index: index }, true, "Comment Details");
       // USAGE_MAINTENANCE_COMMENTS_MODAL, null, tableContentItem, true, "Comment Details"
    showModal(modalContent, modalComponentInstance, {comments: viewData, index: index }, true, "Comment Details");
}


const changeData = (value: any, fieldName: string) => {
    updateUsageField(value, "comments" as IUsageGroupStateKeys);
};

return (
    <>
        <div className="topMargin paddingHorizontalVeryLarge">
            <div className="row">
            </div>
                {
                    <div className="row">
                        <DataGridTable
                            tableContents={viewData ? viewData : []}
                            changeData={changeData}
                            dataGridTableData={dataGridTableData}
                            stateKey={USAGE_MAINTENANCE_GRIDS_VIEW_COMMENTS_STATE_KEY}
                            componentInstance={USAGE_MAINTENANCE_GRIDS_VIEW_COMMENTS_STATE_KEY}
                            showRowNumber={false}
                            isReadOnly={isReadonly}
                            tableActions={isReadonly ? [] : [{ name: ADD_NEW_ACTION }]}
                            lookupValues={lookupValues}
                            incrementTempID={incrementTempID}
                            tabs={tabs}
                            activeTab={activeTab}
                            showDropdownsAsCodes={false}
                            clearModalSearchResults={clearModalSearchResults}
                            showModal={showCommentsModal}                    
                            />
                    </div>
                }
            </div>
    </>
);



};

export default UsageMaintenanceGridCommentsDataView;