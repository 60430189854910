import * as React from "react";
import { hideModal, showModal, updateComment } from "../../../redux/reducers/ModalReducer";
import {
    addArtistToWork, setDataSource, addWorkToProduct, addArtistToProduct, updateRelatedWork, updateWorkSourceIP, updateEditableFields,
    addMatchWorkToUsage, addMatchProductToUsage, searchDataChanged, updateSelectionCriteria, updateForeignSocietyInfo, addIPToAdjustmentDetails, addWorkOrProductToUsageSearchCriteria, addWorkToLicenseRequest, addIPToUsageDetails, updateProductSourceIP, addWorkToClaimRequest, updateScheduledJobField,
    updateRepresentationSelector,
    updateUsageField
} from "../../../redux/reducers/RepertoireReducer";
import CommentsView from "../modalViews/commentsView/CommentsView";
import {
    COMMENTS_VIEW,
    SEARCH_VIEW,
    WORK_NOT_FOUND_VIEW,
    LOADING_VIEW,
    YES_NO_PROMPT_VIEW,
    VERSION_HISTORY_VIEW,
    PRODUCT_WORKS_KEY,
    INTRAY,
    REPERTOIRE,
    WORK_SUBMISSION_VIEW,
    COMPONENTS_KEY,
    FIELD_SETTING_VIEW,
    SEARCH_VIEW_PRODUCTS,
    SEARCH_VIEW_IPS,
    MESSAGE_VIEW,
    ASSING_WORKFLOW_VIEW,
    UPDATE_WORKFLOW_VIEW,
    USAGESEARCH_VIEW,
    CONTRIBUTORS_SEARCH_VIEW,
    SELECTION_CRITERIA_VIEW,
    DISTRIBUTION_VERSION_HISTORY_VIEW,
    IMPORT_DATA_VIEW,
    DISTRIBUTIONS_PAGE_VIEW,
    FOREIGN_SOCIETY_INFO_VIEW,
    MATCHING_WORK_MORE_INFO_VIEW,
    SEARCH_VIEW_USAGES,
    IP_TRANSFER_VIEW,
    WORK_MAINTENANCE_TOOLBAR,
    DATA_SOURCE,
    WORK_SEARCH_VIEW,
    REJECT_UP_WORKFLOW,
    SCHEDULED_JOB_MODAL_VIEW,
    SHORTCUT_KEY_MODAL,
    JOBS_IN_PROGRESS_MODAL,
    REPERTOIRE_PAGE_JOBS,
    IMPORT_POOL_DATA_VIEW_DISTRIBUTIONS,
    TERRITORY_SELECTION_VIEW,
    APPLICABLE_WORKS_KEY,
    POOL_SELECTION_VIEW,
    AGREEMENTS_APPLICABLE_WORKS_SEARCH,
    USAGE_MAINTENANCE_COMMENTS_MODAL
} from "../../Consts";
import { DataSource } from "../../types/DataSource";
import { IArtist } from "../../types/IArtist";
import { IArtistSearchQuery } from "../../types/IArtistSearchQuery";
import { IContributorItem } from "../../types/IContributorItem";
import { IContributorSearchQuery } from "../../types/IContributorSearchQuery";
import { IContributorSearchResult } from "../../types/IContributorSearchResult";
import { IP } from "../../types/IP";
import { IWorksSearchQuery } from "../../types/IWorksSearchQuery";
import { IWorksSearchResult } from "../../types/IWorksSearchResult";
import AssingWorkflowTo from "../assingWorkflow/AssingWorkflowView";
import FieldSettingView from "../modalViews/fieldSettingView/FieldSettingView";
import LoadingView from "../modalViews/loadingView/LoadingView";
import PopUpInfo from "../modalViews/popUpInfo/PopUpInfo";
import VersionHistoryView from "../modalViews/versionHistoryView/VersionHistoryView";
import WorkNotFoundView from "../modalViews/workNotFoundView/WorkNotFoundView";
import SubmissionPromptView from "../modalViews/workSubmission/WorkSubmission";
import YesNoPromptView from "../modalViews/yesNoPromptView/YesNoPromptView";
import SearchView from "../searchView/SearchView";
import SelectorView from "../selectorView/SelectorView";
import UpdateWorkflowView from "../updateWorkflow/UpdateWorkflowView";
import UsageSearchView from "../usageComponents/searchView/UsageSearchView";
import { IUsagesSearchQuery } from "../../types/usageTypes/IUsagesSearchQuery";
import { IUsageGroupResult } from "../../types/usageTypes/IUsageGroupsSearchResult";
import { IMatchWorksSearchQuery } from "../../types/usageTypes/IMatchWorksSearchQuery";
import { IMatchWorksSearchResult } from "../../types/usageTypes/IMatchWorksSearchResult";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { IMatchProductsSearchQuery } from "../../types/usageTypes/IMatchProductsSearchQuery";
import { IProductSearchResult } from "../../types/IProductSearchResult";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { ITreeData } from "../../types/ITreeData";
import SelectionCriteriaView from "../modalViews/selectionCriteriaView/SelectionCriteriaView";
import { ISelectionCriteria } from "../../types/ISelectionCriteria";
import { IDistribution } from "../../types/usageTypes/IDistribution";
import { ImportView } from "../modalViews/importView/ImportView";
import { addFileToUpload, fileMetadataChanged, setInDropZone, setProgressUploadFileProccess, sortColumn } from "../../../redux/reducers/DataIngestionReducer";
import { IComponentDataItem } from "../../../core/types/IComponentDataItem";
import IDroppedFiles from "../../../redux/types/IDroppedFiles";
import { IDistributionState } from "../../../redux/types/IDistributionState";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import ForeignSocietyInfoView from "../modalViews/foreignSocietyInfoView/ForeignSocietyInfoView";
import { getMatchingWorkInfo } from "../../../redux/thunks/RepertoireThunks";
import { IDistributionType } from "../../types/usageTypes/IDistibutionType";
import { IpTransferView } from "../modalViews/ipTransferView/IpTransferView";
import { IIPsSearchQuery } from "../../types/IIPsSearchQuery";
import { IipTransfer } from "../../types/usageTypes/IipTransfer";
import { ILicenseUserSearchQuery } from "../../types/ILicenseUserSearchQuery";
import { ILicenseUserSearchResult } from "../../types/ILicenseUserSearchResult";
import { IProductSearchQuery } from "../../types/IProductSearchQuery";
import { IDataActionToolbar } from "../../types/IDataActionToolbar";
import { FormatFields } from "../../../redux/types/FormatFields";
import { getDataAction } from "../toolBar/ToolbarHelper";
import ProductWorkSearchModalViewContainer from "../modalViews/productWorkSearchView/ProductWorkSearchModalContainer";
import UsageWorkSearchModalContainer from "../modalViews/usageWorkSearchView/UsageWorkSearchModalContainer";
import UsageProductSearchModalViewContainer from "../modalViews/usageProductSearchModalView/UsageProductSearchModalViewContainer";
import { SearchRequests } from "../../services/SearchRequests";
import { WORK_MAINTENANCE_GROUP } from "../../../membersportal/ConfigurationConsts";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY } from "../../ConfigurationConsts";
import RejectWorkflowView from "../modalViews/rejectWorkflowView/RejectWorkflowView";
import { IWorkSubjectSearchResult } from "../../types/IWorkSubjectSearchResult";
import { ISubjectsSearchQuery } from "../../types/ISubjectsSearchQuery";
import { ISubject } from "../../types/ISubject";
import ScheduledJobModalView from "../modalViews/scheduledJobModalView/ScheduledJobModalView";
import ShortcutKeyModalView from "../shortcutKeyModalView/ShortcutKeyModalView";
import JobsInProgressModal from "../jobsInProgressModal/JobsInProgressModal";
import TerritorySelectionView from "../modalViews/territorySelectionView/TerritorySelectionView";
import ActionButton from "../actionButton/ActionButton";
import PoolSelectionView from "../modalViews/poolSelectionView/PoolSelectionView";
import AgreementApplicableWorkSearchView from "../modalViews/agreementApplicableWorkSearchView/AgreementApplicableWorkSearchView";
import UsageMaintenanceGridCommentsModalView from "../../usage/components/usageMaintenanceGridsView/usageMaintenanceGridCommentsDataView/UsageMaintenanceGirdCommentsModalView";
import { IUsageGroup } from "../../types/usageTypes/IUsageGroup";

export interface IRepertoireModalProps {
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    updateModalComment?: typeof updateComment;
    addArtistToWork?: typeof addArtistToWork;
    addArtistToProduct?: typeof addArtistToProduct;
    addWorkToProduct?: typeof addWorkToProduct;
    updateWorkContributorIP?: (dataSource: DataSource, workContributor: IContributorItem, ip: IP) => void;
    updateProductContributorIP?: (productContributorID: number, ip: IP) => void;
    updateAgreementIP?: (agreementAdminIPID: number, ip: IP) => void;
    updateAgreementWork?: (work: IWorksSearchResult) => void;
    updateAgreementCarveOutProduct?: (agreementProductWorkID: number, product: IProductSearchResult) => void;
    getProductDetails?: (dataSource: string, productCoreID: number, isForWorkflowSession?: boolean, openNewTab?: boolean) => void;
    updateMatchingWork?: (workID: number, work: IWorksSearchResult) => void;
    updateMatchingProduct?: (productID: number, product: IProductSearchResult) => void;
    modalContent: string;
    modalComponentInstance: string;
    modalProps: any;
    modalIndex?: number;
    modalKey?:string;
    displayModalCloseButton: boolean;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    workNotFoundViewData: IRepertoireComponentDataItem;
    loadingViewData: IRepertoireComponentDataItem;
    yesNoPromptViewData: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    dataSource?: string;
    setDataSource?: typeof setDataSource;
    agreementsDataSource?: string;
    productDataSource?: string;
    modalTitle: string;
    searchSubjects?: (query: ISubjectsSearchQuery, modalOpen: boolean) => void;
    updateSubjectCode?: (subject: ISubject) => void;
    searchArtists?: (query: IArtistSearchQuery, dataSource: string) => void;
    artistsSearchResults?: IArtist[],
    contributorsSearchResults?: IContributorSearchResult[],
    searchContributors?: (
        query: IContributorSearchQuery
    ) => void;
    searchWorks?: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    searchProducts?: (query: IProductSearchQuery, modalOpen: boolean) => void;
    worksSearchResults?: IWorksSearchResult[];
    productSearchResults?: IProductSearchResult[];
    searchSuccessful: boolean;
    updateRelatedWork?: typeof updateRelatedWork;
    updateWorkSourceIP?: typeof updateWorkSourceIP;
    updateProductSourceIP?: typeof updateProductSourceIP;
    componentInstance?: string;
    editableFields?: any;
    updateEditableFields?: typeof updateEditableFields;
    expandedWorkResults?: number[];
    expandedProductResults?: number[];
    expandAll?: boolean;
    expandWorkResult?: (workResult: number) => void;
    expandProductResult?: (productResult: number) => void;
    expandAllResults?: () => void;
    modalMessage: string;
    updateWorkflowViewData?: IRepertoireComponentDataItem;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    usageSearchViewData?: IRepertoireComponentDataItem;
    usageSearchResultsTableData?: IRepertoireComponentDataItem;
    searchUsages?: (query: IUsagesSearchQuery, modalOpen: boolean, usageType: string, lookupSources: ITreeData[], continuationToken?: string) => void;
    usageGroupsSearchResult?: IUsageGroupResult[];
    searchMatchWorks?: (query: IMatchWorksSearchQuery, modalOpen: boolean) => void;
    matchWorkSearchResult?: IMatchWorksSearchResult[];
    usageExpandAllResults?: () => void;
    expandMatchWorkResult?: (matchWorkResults: number) => void;
    addMatchworkToUsage?: typeof addMatchWorkToUsage;
    addMatchProductToUsage?: typeof addMatchProductToUsage,
    expandedMatchWorkResults?: number[];
    usageExpandAll?: boolean,
    getUsageDetails?: (usageID: string) => void;
    searchMatchProducts?: (query: IMatchProductsSearchQuery, modalOpen: boolean) => void;
    matchProductsSearchResult?: IProductSearchResult[];
    lookupValues: ILookupDictionary;
    searchDataChanged: typeof searchDataChanged;
    ipMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    addNewAccount?: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    addNewWork?: (lookups: ILookupDictionary, actionList: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[], workGroupId?: string, workGroup?: string) => void;
    lookupSources?: ITreeData[];
    selectionCriteria?: ISelectionCriteria;
    updateSelectionCriteria?: typeof updateSelectionCriteria;
    updateRepresentationSelector?: typeof updateRepresentationSelector;
    distributions?: IDistribution[];
    isReadOnlyModal?: boolean;
    droppedFiles?: IDroppedFiles;
    setInDropZone?: typeof setInDropZone;
    addFileToUpload?: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone?: boolean;
    setProgressUploadFileProccess?: typeof setProgressUploadFileProccess;
    containerDetailsWindowComponentData?: IComponentDataItem;
    importDistributionPools?: (distribution: IDistributionState, file: string, lookupValues: ILookupDictionary) => void;
    tabs?: ITabReduxItem[];
    activeTab?: number;
    updateForeignSocietyInfo?: typeof updateForeignSocietyInfo;
    getMatchingWorkInfo?: typeof getMatchingWorkInfo;
    distributionTypes?: IDistributionType[];
    getDistributionTypes?: () => void;
    ipTransferViewData?: IRepertoireComponentDataItem;
    searchIPs?: (query: IIPsSearchQuery, keepModalOpenAfterSearch?: boolean) => void;
    ipTransfer?: (ipTransferObject: IipTransfer) => void;
    addIpToAdjustmentDetail?: typeof addIPToAdjustmentDetails;
    addIPToUsageDetails?: typeof addIPToUsageDetails;
    addWorkOrProductToUsageSearchCriteria?: typeof addWorkOrProductToUsageSearchCriteria;
    usageMatchingDefaultsWorks?: string[];
    usageMatchingDefaultsProducts?: string[];
    searchLicenseUser?: (query: ILicenseUserSearchQuery) => void;
    licenseUserSearchResults?: ILicenseUserSearchResult[];
    workSubjectSearchResults: IWorkSubjectSearchResult[];
    updateLicenceUserValues?: (params: any) => void;
    isRepertoireSearch?: boolean;
    addWorkToLicenseRequest?: typeof addWorkToLicenseRequest;
    addWorkToClaimRequest?: typeof addWorkToClaimRequest;
    commentCategoryFlag?: boolean;
    addNewProduct?: (lookups: ILookupDictionary, isNew?: boolean, productMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[]) => void;
    incrementTempID?: () => void;
    updateScheduledJobField?: typeof updateScheduledJobField;
    title?: string;
    customer?: string;
    updateUsageField?: any;
}

export interface IShowFieldSettingViewModalProps {
    componentViewData: IRepertoireComponentDataItem;
    componentInstance: string;
    dataGridViewData: IRepertoireComponentDataItem;
    onClickSave: () => void;
    inverted?: boolean;
    key?: string;
}


export const RepertoireModal: React.SFC<IRepertoireModalProps> = ({ 
    hideModal,
    updateModalComment,
    modalContent,
    modalComponentInstance,
    displayModalCloseButton,
    modalProps,
    modalIndex,
    modalKey,
    searchViewData,
    searchResultsTableData,
    workNotFoundViewData,
    loadingViewData,
    dataGridTableData,
    dataSource,
    setDataSource,
    agreementsDataSource,
    productDataSource,
    modalTitle,
    searchArtists,
    searchContributors,
    searchWorks,
    searchSubjects,
    searchProducts,
    artistsSearchResults,
    contributorsSearchResults,
    worksSearchResults,
    productSearchResults,
    workSubjectSearchResults,
    addArtistToWork,
    addArtistToProduct,
    addWorkToProduct,
    updateWorkContributorIP,
    updateProductContributorIP,
    updateAgreementIP,
    updateAgreementWork,
    updateAgreementCarveOutProduct,
    getProductDetails,
    updateMatchingWork,
    updateMatchingProduct,
    yesNoPromptViewData,
    searchSuccessful,
    updateRelatedWork,
    updateWorkSourceIP,
    updateProductSourceIP,
    editableFields,
    updateEditableFields,
    expandedWorkResults,
    expandedProductResults,
    expandAll,
    expandWorkResult,
    expandProductResult,
    expandAllResults,
    modalMessage,
    updateWorkflowViewData,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    currentPage,
    resetPagination,
    updatePagination,
    usageSearchViewData,
    searchUsages,
    usageGroupsSearchResult,
    usageSearchResultsTableData,
    searchMatchWorks,
    searchMatchProducts,
    matchWorkSearchResult,
    addMatchworkToUsage,
    addMatchProductToUsage,
    expandMatchWorkResult,
    usageExpandAllResults,
    expandedMatchWorkResults,
    usageExpandAll,
    getUsageDetails,
    matchProductsSearchResult,
    lookupValues,
    searchDataChanged,
    ipMaintenanceGeneralDataViewData,
    addNewAccount,
    addNewWork,
    lookupSources,
    updateSelectionCriteria,
    updateRepresentationSelector,
    distributions,
    isReadOnlyModal,
    addFileToUpload,
    isDropZone,
    setProgressUploadFileProccess,
    containerDetailsWindowComponentData,
    droppedFiles,
    setInDropZone,
    importDistributionPools,
    tabs,
    activeTab,
    updateForeignSocietyInfo,
    getMatchingWorkInfo,
    distributionTypes,
    ipTransferViewData,
    showModal,
    searchIPs,
    ipTransfer,
    addIpToAdjustmentDetail,
    addWorkOrProductToUsageSearchCriteria,
    usageMatchingDefaultsWorks,
    usageMatchingDefaultsProducts,
    isRepertoireSearch,
    searchLicenseUser,
    licenseUserSearchResults,
    updateLicenceUserValues,
    addWorkToLicenseRequest,
    addIPToUsageDetails,
    addWorkToClaimRequest,
    commentCategoryFlag,
    addNewProduct,
    updateSubjectCode,
    incrementTempID,
    updateScheduledJobField,
    customer,
    updateUsageField
}) => {
    React.useEffect(() => {
        const close = (e) => {
            if (e.key === 'Escape') {
                hideModal();
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const handlePreventClickPropagation = (event) => {
        if (event.type === 'click') {
            event.stopPropagation();
        }
    };






    const [modalPropsUsers, setModalPropsUsers] = React.useState(modalProps);

    const renderModalContent = () => {
        switch (modalContent) {
            case SELECTION_CRITERIA_VIEW:
                return <>
                        <SelectionCriteriaView
                            hideModal={hideModal}
                            distributionPool={modalProps}
                            updateSelectionCriteria={updateSelectionCriteria}
                            dataGridTableData={dataGridTableData}
                            lookupValues={lookupValues}
                            distributions={distributions}
                            isReadOnly={isReadOnlyModal}
                            tabs={tabs}
                            activeTab={activeTab}
                            incrementTempID={incrementTempID}
                            customer={customer}
                        />
                </>;
            case COMMENTS_VIEW: {
                const updateComment = (commentId: number, value: any, fieldName: string) => {
                    let newModalProps = Object.assign({}, modalProps);
                    newModalProps[fieldName].value = value;
                    updateModalComment(newModalProps);
                }

                return <CommentsView
                    commentItem={modalProps}
                    updateComment={updateComment}
                    lookupValues={lookupValues}
                    commentCategoryFlag={commentCategoryFlag}
                />;
            }

            case USAGE_MAINTENANCE_COMMENTS_MODAL: {
                return (
                    <UsageMaintenanceGridCommentsModalView
                        hideModal={hideModal}
                        modalProps ={modalProps}
                        updateUsageField={updateUsageField}
                        index={modalIndex}
                        title={modalTitle}
                    />
                )
            }
            case SEARCH_VIEW: {
                const updateContributorIP = (workContributor: IContributorItem, ip: IP) => {
                    updateWorkContributorIP(dataSource as DataSource, workContributor, ip)
                };
                const newDataSource = (modalProps && modalProps["dataSource"]) ? DataSource.Repertoire : dataSource;
                return (
                    <SearchView
                        searchViewData={searchViewData}
                        componentInstance={modalComponentInstance}
                        modalProps={modalProps}
                        modalIndex={modalIndex}
                        modalKey={modalKey}
                        dataSource={newDataSource}
                        agreementsDataSource={agreementsDataSource}
                        searchResultsTableData={searchResultsTableData}
                        searchArtists={searchArtists}
                        searchContributors={searchContributors}
                        searchWorks={searchWorks}
                        searchSubjects={searchSubjects}
                        updateSubjects={updateSubjectCode}
                        searchProducts={searchProducts}
                        artistsSearchResults={artistsSearchResults}
                        contributorsSearchResults={contributorsSearchResults}
                        worksSearchResults={worksSearchResults}
                        productSearchResults={productSearchResults}
                        subjectSearchResults={workSubjectSearchResults}
                        addArtistToWork={addArtistToWork}
                        addArtistToProduct={addArtistToProduct}
                        addWorkToProduct={addWorkToProduct}
                        updateWorkContributorIP={updateContributorIP}
                        updateProductContributorIP={updateProductContributorIP}
                        updateAgreementIP={updateAgreementIP}
                        updateAgreementWork={updateAgreementWork}
                        updateAgreementCarveOutProduct={updateAgreementCarveOutProduct}
                        getProductDetails={getProductDetails}
                        updateMatchingWork={updateMatchingWork}
                        updateMatchingProduct={updateMatchingProduct}
                        hideModal={hideModal}
                        searchSuccessful={searchSuccessful}
                        updateRelatedWork={updateRelatedWork}
                        updateWorkSourceIP={updateWorkSourceIP}
                        updateProductSourceIP={updateProductSourceIP}
                        expandedWorkResults={expandedWorkResults}
                        expandedProductResults={expandedProductResults}
                        expandAll={expandAll}
                        expandWorkResult={expandWorkResult}
                        expandProductResult={expandProductResult}
                        expandAllResults={expandAllResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        resetPagination={resetPagination}
                        updatePagination={updatePagination}
                        searchIPs={searchIPs}
                        addIpToAdjustmentDetail={addIpToAdjustmentDetail}
                        searchLicenseUser={searchLicenseUser}
                        licenseUserSearchResults={licenseUserSearchResults}
                        updateLicenceUserValues={updateLicenceUserValues}
                        addWorkToLicenseRequest={addWorkToLicenseRequest}
                        addIPToUsageDetails={addIPToUsageDetails}
                        addWorkToClaimRequest={addWorkToClaimRequest}
                        lookupValues={lookupValues}
                    />
                );
            }
            case WORK_NOT_FOUND_VIEW: {
                return <WorkNotFoundView fields={workNotFoundViewData.fields} workId={modalProps} />
            }
            case LOADING_VIEW: {
                return <LoadingView fields={loadingViewData.fields} />
            }
            case MESSAGE_VIEW: {
                return <PopUpInfo textToScreen={modalProps.textToScreen} />
            }
            case YES_NO_PROMPT_VIEW: {
                return <YesNoPromptView fields={yesNoPromptViewData.fields} props={modalProps} />
            }
            case SCHEDULED_JOB_MODAL_VIEW: {
                return <ScheduledJobModalView fields={modalProps.fields} noCallback={modalProps.noCallback} yesCallback={modalProps.yesCallback} upsertScheduledJob={modalProps.upsertScheduledJob} componentName={modalComponentInstance} tab={tabs[activeTab]} changeData={updateScheduledJobField} />
            }
            case DISTRIBUTION_VERSION_HISTORY_VIEW:
            case VERSION_HISTORY_VIEW: {
                let filteredFields = searchResultsTableData;
                if (modalComponentInstance === SEARCH_VIEW_PRODUCTS) {
                    filteredFields.fields = filteredFields.fields.filter(x => x.name !== 'intrayWorkID');
                } else if (modalComponentInstance === SEARCH_VIEW_IPS) {
                    filteredFields.fields = filteredFields.fields.filter(x => x.name !== 'intrayWorkID' && x.name !== 'intrayProductID');
                } else {
                    filteredFields.fields = filteredFields.fields.filter(x => x.name !== 'intrayProductID');
                }

                return <VersionHistoryView
                    searchResultsTableData={filteredFields}
                    hideModal={hideModal}
                    props={modalProps}
                    updatePagination={updatePagination}
                    componentInstance={modalContent}
                />
            }
            case WORK_SUBMISSION_VIEW: {
                return (
                    <SubmissionPromptView
                        props={modalProps} />
                )
            }
            case FIELD_SETTING_VIEW: {

                var key = "default";
                if (modalProps["key"]) {
                    key = modalProps["key"]
                }

                return <FieldSettingView
                    componentInstance={modalProps["componentInstance"]}
                    componentViewData={modalProps["componentViewData"]}
                    dataGridViewData={modalProps["dataGridViewData"]}
                    onClickSave={modalProps["onClickSave"]}
                    tableContents={editableFields[key]}
                    updateEditableFields={updateEditableFields}
                    onClickCancel={hideModal}
                    editableFieldsKey={key}

                />
            }
            case ASSING_WORKFLOW_VIEW: {
                const updateUsers = (index: number, value: any, fieldName: string) => {
                    setModalPropsUsers(prevProps => {
                        const newItems = [...prevProps.items];
                        if (index >= 0 && index < newItems.length) {
                            const updatedItem = { ...newItems[index] };
                            if (fieldName === 'assigned' || fieldName === 'numberOfWorkflows') {
                                updatedItem[fieldName] = { value };
                            }
                            newItems[index] = updatedItem;
                        }
                        return { ...prevProps, items: newItems };
                    });
                };
                return <AssingWorkflowTo
                    hideModal={hideModal}
                    assingTo={modalProps["assingTo"]}
                    assingWorkflowViewData={modalProps["assingWorkflowViewData"]}
                    items={modalProps["items"]}
                    searchResultsTableData={modalProps["searchResultsTableData"]}
                    updatePagination={updatePagination}
                    totalWorkflows={modalProps["totalWorkflows"]}
                    updateUsers={updateUsers}
                />
            }
            case UPDATE_WORKFLOW_VIEW: {
                return <UpdateWorkflowView
                    hideModal={hideModal}
                    updateWorkflowViewData={updateWorkflowViewData}
                    updateWorkflows={modalProps["updateWorkflows"]} />
            }
            case AGREEMENTS_APPLICABLE_WORKS_SEARCH: {
                return <AgreementApplicableWorkSearchView
                        searchViewData={searchViewData}
                        searchSuccessful={searchSuccessful}
                        searchResultsTableData={searchResultsTableData}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        resetPagination={resetPagination}
                        updatePagination={updatePagination}
                        hideModal={hideModal}
                        modalTitle={modalTitle}
                        handleAddNewWork={handleAddNewWork}
                        searchWorks={searchWorks}
                        updateAgreementWork={updateAgreementWork}
                        worksSearchResults={worksSearchResults}
                        modalProps={modalProps}
                        expandedWorkResults={expandedWorkResults}
                        expandAll={expandAll}
                        expandWorkResult={expandWorkResult}
                        expandAllResults={expandAllResults}
                        />}
            case USAGESEARCH_VIEW: {
                return (
                    <UsageSearchView
                        searchViewData={usageSearchViewData}
                        searchResultsTableData={usageSearchResultsTableData}
                        componentInstance={modalComponentInstance}
                        modalProps={modalProps}
                        searchUsages={searchUsages}
                        usageGroupsSearchResult={usageGroupsSearchResult}
                        searchSuccessful={searchSuccessful}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        resetPagination={resetPagination}
                        updatePagination={updatePagination}
                        hideModal={hideModal}
                        searchMatchWorks={searchMatchWorks}
                        searchMatchProducts={searchMatchProducts}
                        matchWorkSearchResult={matchWorkSearchResult}
                        getUsageDetails={getUsageDetails}
                        expandMatchWorkResult={expandMatchWorkResult}
                        usageExpandAll={usageExpandAll}
                        usageExpandAllResults={usageExpandAllResults}
                        expandedMatchWorkResults={expandedMatchWorkResults}
                        addMatchworkToUsage={addMatchworkToUsage}
                        addMatchProductToUsage={addMatchProductToUsage}
                        searchDataChanged={searchDataChanged}
                        matchProductsSearchResult={matchProductsSearchResult}
                        lookupValues={lookupValues}
                        lookupSources={lookupSources}
                        distributionTypes={distributionTypes}
                        usageGroup={tabs[activeTab]?.usageMaintenanceState?.usageGroup}
                        addWorkOrProductToUsageSearchCriteria={addWorkOrProductToUsageSearchCriteria}
                        usageMatchingDefaultsWorks={usageMatchingDefaultsWorks}
                        usageMatchingDefaultsProducts={usageMatchingDefaultsProducts}
                    />
                );
            }
            case FOREIGN_SOCIETY_INFO_VIEW:
                const updateForeignSocietyInfoField = (distributionPoolID: number, value: any, fieldName: string) => {
                    updateForeignSocietyInfo(distributionPoolID, value, fieldName);
                    modalProps[fieldName].value = value;
                    updateModalComment(modalProps);
                }
                return (
                    <ForeignSocietyInfoView
                        hideModal={hideModal}
                        distributionPool={modalProps}
                        lookupValues={lookupValues}
                        dataGridTableData={dataGridTableData}
                        updateForeignSocietyInfo={updateForeignSocietyInfoField}
                        isReadOnly={isReadOnlyModal}
                    />
                );
            case MATCHING_WORK_MORE_INFO_VIEW:
                if (!tabs[activeTab].matchingWork || tabs[activeTab].matchingWork.workID !== modalProps) {
                    getMatchingWorkInfo(modalProps);
                }

                return (
                    <UsageSearchView
                        searchViewData={usageSearchViewData}
                        searchResultsTableData={usageSearchResultsTableData}
                        componentInstance={SEARCH_VIEW_USAGES}
                        modalProps={modalProps}
                        searchUsages={searchUsages}
                        usageGroupsSearchResult={usageGroupsSearchResult}
                        searchSuccessful={searchSuccessful}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        resetPagination={resetPagination}
                        updatePagination={updatePagination}
                        hideModal={hideModal}
                        searchMatchWorks={searchMatchWorks}
                        searchMatchProducts={searchMatchProducts}
                        matchWorkSearchResult={matchWorkSearchResult}
                        getUsageDetails={getUsageDetails}
                        expandMatchWorkResult={expandMatchWorkResult}
                        usageExpandAll={usageExpandAll}
                        usageExpandAllResults={usageExpandAllResults}
                        expandedMatchWorkResults={expandedMatchWorkResults}
                        addMatchworkToUsage={addMatchworkToUsage}
                        addMatchProductToUsage={addMatchProductToUsage}
                        searchDataChanged={searchDataChanged}
                        matchProductsSearchResult={matchProductsSearchResult}
                        lookupValues={lookupValues}
                        lookupSources={lookupSources}
                        distributionTypes={distributionTypes}
                        onlySearchResults={true}
                        isDistributed={tabs[activeTab].matchingWork ? tabs[activeTab].matchingWork.isDistributed : undefined}
                        dateOfRegistration={tabs[activeTab].matchingWork ? tabs[activeTab].matchingWork.dateOfRegistration : undefined}
                        tabs={tabs}
                        activeTab={activeTab}
                    />
                );
            case IP_TRANSFER_VIEW:

                return (
                    <IpTransferView
                        hideModal={hideModal}
                        showModal={showModal}
                        props={modalProps}
                        ipTransferViewData={ipTransferViewData}
                        searchIPs={searchIPs}
                        sourceIp={tabs[activeTab].interestedPartyMaintenanceState.interestedParty ? tabs[activeTab].interestedPartyMaintenanceState.interestedParty.ipBaseNumber : undefined}
                        ipTransfer={ipTransfer}
                        tabs={tabs}
                        activeTab={activeTab}
                    />
                );
            default:
                return null;
        }
    };

    const renderDataSelector = () => {
        if (modalComponentInstance === PRODUCT_WORKS_KEY && productDataSource === INTRAY || modalComponentInstance === COMPONENTS_KEY) {
            return (
                <SelectorView
                    selection={dataSource}
                    setSelection={setDataSource}
                    option1={INTRAY}
                    option2={REPERTOIRE}
                    selectorTitle={DATA_SOURCE}
                />
            );
        }
    }

    const renderMessage = () => {
        if (modalMessage != undefined) {
            return (<div>{modalMessage}</div>);
        }
    }

    const handleAddNewWork = () => {
        hideModal();
        addNewWork(lookupValues, getDataAction(DataSource.Intray, WORK_MAINTENANCE_TOOLBAR), true)
    }

    const handleAddNewProduct = () => {
        hideModal();
        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                addNewProduct(lookupValues, true, null, formats)
            })
    }

    const handleAssignTo = () => {
        const assignTo = modalProps["assingTo"];
        const assignedUsers = modalPropsUsers.items.filter(user => user.assigned && user.assigned.value);
        
        const transformedUsers = assignedUsers.map(user => ({
            userName: user.userName.value,
            numberOfWorkflows: parseInt(user.numberOfWorkflows.value) || -1,
            assigned: user.assigned.value
        }));

        if (transformedUsers.length > 0) {
            assignTo(transformedUsers);
        } else {
            hideModal();
        }
    };


    const renderModal = () => {
        const styleModalContent = (modalContent === ASSING_WORKFLOW_VIEW || modalContent === UPDATE_WORKFLOW_VIEW || modalContent === SCHEDULED_JOB_MODAL_VIEW) ? "modalBody noFlex" : "modalBody";
        if (modalContent === WORK_SUBMISSION_VIEW) {
            return (
                <div className="repertoireModal">
                    {renderModalContent()}
                </div>
            );
        }
        if (modalContent === LOADING_VIEW) {
            return (
                <div className="loaderWrapper">
                    <div className="spinner-dualball">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            );
        }
        if (modalContent === SHORTCUT_KEY_MODAL) {
            return <div className="repertoireModal">
                <ShortcutKeyModalView hideModal={hideModal} />
            </div>
        }
        if (modalContent === JOBS_IN_PROGRESS_MODAL) {
            return <div className="repertoireModal">
                <JobsInProgressModal hideModal={hideModal} jobsToDisplay={REPERTOIRE_PAGE_JOBS} />
            </div>
        }
        if (modalContent === TERRITORY_SELECTION_VIEW) {
            return <div className="repertoireModal">
                <TerritorySelectionView
                    hideModal={hideModal}
                    interestedPartyRepresentation={modalProps}
                    updateRepresentationSelector={updateRepresentationSelector}
                    dataGridTableData={dataGridTableData}
                    lookupValues={lookupValues}
                    isReadOnly={isReadOnlyModal}
                    incrementTempID={incrementTempID}
                    title={modalTitle}
                    modalIndex={modalIndex}
                />
            </div>
        }
        if (modalContent === POOL_SELECTION_VIEW) {
            return <div className="repertoireModal">
                <PoolSelectionView
                    hideModal={hideModal}
                    interestedPartyRepresentation={modalProps}
                    updateRepresentationSelector={updateRepresentationSelector}
                    dataGridTableData={dataGridTableData}
                    lookupValues={lookupValues}
                    isReadOnly={isReadOnlyModal}
                    incrementTempID={incrementTempID}
                    title={modalTitle}
                    modalIndex={modalIndex}
                    pools={tabs[activeTab].usagePoolSearchResult}
                />
            </div>
        }
        if (modalContent === REJECT_UP_WORKFLOW) {
            return <RejectWorkflowView props={modalProps} hideModal={hideModal} lookupValues={lookupValues} />
        }
        if (modalContent === WORK_SEARCH_VIEW) {
            return <ProductWorkSearchModalViewContainer
                searchWorks={searchWorks}
                isDataSourceIntray={modalProps["isDataSourceIntray"]}
                searchViewData={searchViewData}
                worksSearchResults={worksSearchResults}
                searchSuccessful={searchSuccessful}
                searchResultsTableData={searchResultsTableData}
                lookupValues={lookupValues}
                hideModal={hideModal}
                modalTitle={modalTitle}
                handleAddNewWork={handleAddNewWork}
                addWorkToProduct={addWorkToProduct}
            />
        }
        if (modalContent === USAGESEARCH_VIEW && modalComponentInstance === 'usagesUsageToWork') {
            return <UsageWorkSearchModalContainer
                searchWorks={searchWorks}
                searchViewData={searchViewData}
                worksSearchResults={worksSearchResults}
                searchSuccessful={searchSuccessful}
                searchResultsTableData={searchResultsTableData}
                lookupValues={lookupValues}
                hideModal={hideModal}
                modalTitle={modalTitle}
                handleAddNewWork={handleAddNewWork}
                setTitle={modalProps['setTitle']}
            />
        }

        if (modalContent === USAGESEARCH_VIEW && modalComponentInstance === 'usagesUsageToProduct') {
            return <UsageProductSearchModalViewContainer
                searchMatchProducts={searchMatchProducts}
                searchViewData={searchViewData}
                searchSuccessful={searchSuccessful}
                searchResultsTableData={searchResultsTableData}
                lookupValues={lookupValues}
                hideModal={hideModal}
                modalTitle={modalTitle}
                handleAddNewWork={handleAddNewProduct}
                setTitle={modalProps['setTitle']}
            />
        }
       
        if (modalContent === IMPORT_DATA_VIEW || modalContent === IMPORT_POOL_DATA_VIEW_DISTRIBUTIONS) {
            return (
                <div className="repertoireModal">
                    <div className="modal-dialog modalLarge settingsModal">
                        <div className="modal-content">
                            <div className='modal-header' style={{
                                background: "#38429C",
                                color: "white",
                            }}>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => hideModal()}>
                                    <i className="icon ms-Icon ms-Icon--ChromeClose" style={{ color: "white" }} title="Close"></i>
                                </button>
                                <h1 className="modal-title">{modalTitle}</h1>
                            </div>
                            <div className="modal-body paddingVerticalVeryLarge" >

                                {IMPORT_POOL_DATA_VIEW_DISTRIBUTIONS && <div>
                                    {"Import pools directly to the Distributions via a CSV format file containing four columns for the below data values:"}
                                    <div className="poolsImportTextBox flexColumn">
                                        <div>
                                            <label>{"• Pool code:"}</label>{" Pool Code for the pool to be imported to the Distribution (mandatory)"}
                                        </div>
                                        <div>
                                            <label>{"• Segment:"}</label>{" A sub-label for a pool, differentiating between potential differing data and/or amounts being allocated to the same Pool Code (Conditional on duplicate Pool Code entry)."}
                                        </div>
                                        <div>
                                            <label>{"• Amount to Allocate:"}</label>{" The total amount assigned for Allocation for this Pool"}
                                        </div>
                                        <div>
                                            <label>{"• Value per point:"}</label>{" The amount per point that must be allocated."}
                                        </div>
                                    </div>
                                    {"This file can be used in conjunction with manual pool additions. Pool and segment combinations will be added where they didn't exist before. If duplicates but with different 'amount to allocate' and 'nominal point value' amounts, the existing amounts will be updated."}
                                </div>}
                                <ImportView
                                    addFileToUpload={addFileToUpload}
                                    containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                                    currentDirectory={"distributions"}
                                    editingChanged={undefined}
                                    fileMetadataChanged={fileMetadataChanged}
                                    openDirectory={undefined}
                                    pageContentIsActive={true}
                                    distributions={distributions}
                                    droppedFiles={droppedFiles}
                                    isDropZone={isDropZone}
                                    setInDropZone={setInDropZone}
                                    setProgressUploadFileProccess={setProgressUploadFileProccess}
                                    sortColumn={sortColumn}
                                    saveState={undefined}
                                    isRepertoireModule={false}
                                    toggleSidebarCollapsed={undefined}
                                    validationMessageChanged={undefined}
                                    component={DISTRIBUTIONS_PAGE_VIEW}
                                    lookupValues={lookupValues}
                                    importDistributionPools={importDistributionPools}
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    hideModal={hideModal}
                                />
                            </div>
                            <div className=" flexRow flexJustifyContentRight marginVerticalVeryLarge borderTopGreyLine">
                                <div className="form-group col-sm-12 margin-topSmall marginHorizontalMedium alignRight" style={{ width: '300px' }}>
                                    <button className="button buttonAutowidth btn-defaultSecondary margin-rightMedium" onClick={() => hideModal()}>{"Close"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>);
        }

        return (
            modalContent === USAGE_MAINTENANCE_COMMENTS_MODAL ? (
                <div className="repertoireModal" onClick={handlePreventClickPropagation}>
                        <UsageMaintenanceGridCommentsModalView
                            hideModal={hideModal}
                            modalProps ={modalProps}
                            updateUsageField={updateUsageField}
                            index={modalIndex}
                            title={modalTitle}
                        />
                        </div>) : (
            <div className="repertoireModal" onClick={handlePreventClickPropagation}>
                <div className={"modal " + (modalContent === IMPORT_DATA_VIEW ? "importData" : "")}>
                    <div>
                        {displayModalCloseButton && (
                            <div className="closeButton" onClick={() => hideModal()}>
                                <img src={"assets/close.svg"} className="closeIcon" />
                            </div>
                        )}
                    </div>
                    <div className="fixedPart" />
                    <div className="dynamicPart">
                        <div className="title">{modalTitle}</div>
                        {modalComponentInstance === CONTRIBUTORS_SEARCH_VIEW ?
                            <div className="newWorkButton" title="Create New Account">
                                <div className="IconTextButton" onClick={() => addNewAccount(lookupValues, true, ipMaintenanceGeneralDataViewData)}>
                                    <i className="icon ms-Icon ms-Icon--CircleAddition"
                                        aria-hidden="true">
                                    </i>
                                    New Account
                                </div>
                            </div> : <></>}
                        {modalComponentInstance === PRODUCT_WORKS_KEY ?
                            <div className="newWorkButton" title="Create New Work" style={{ paddingRight: "24px" }}>
                                <div className="IconTextButton" onClick={handleAddNewWork}>
                                    <i className="icon ms-Icon ms-Icon--CircleAddition"
                                        aria-hidden="true">
                                    </i>
                                    New Work
                                </div>
                            </div> : <></>}
                        <div className="modalBody">{renderDataSelector()}</div>
                        <div className="modalBody">{renderMessage()}</div>
                        <div className={styleModalContent}>{renderModalContent()}</div>
                    </div> 
                    {(modalComponentInstance === APPLICABLE_WORKS_KEY && searchSuccessful) ? (
                        <div key='action' className="doneButtonRow"> 
                            <div className="doneButton">
                                <ActionButton buttonText="Done" buttonAction={() => hideModal()} />
                            </div>
                        </div>) : (<div className="fixedPart" />) }
                    {(modalContent === ASSING_WORKFLOW_VIEW) ? (
                        <div key='action' className="doneButtonRow">
                            <div className="doneButton">
                                <ActionButton buttonText="Done" buttonAction={handleAssignTo} />
                            </div>
                        </div>) : (<div className="fixedPart" />)}
                </div>
            </div>)
        );
    }

    return (
        renderModal()
    );
};


